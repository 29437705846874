import React, { useEffect, useState } from "react";
import { Wrapper } from "./style";
import { Redirect, Link, useHistory, useLocation } from "react-router-dom";
import { Avatar, Dropdown, Menu, Tooltip, message } from "antd";
import { convertSidebarLinks, nameObject } from "./lib/sidebar-menu";
import { SendOutlined } from "@ant-design/icons";

export default function Sidebar(props) {
  const { sidebarStatus, screenStatus, userType, user } = props;
  const history = useHistory();
  const location = useLocation();
  const [locationPath, setLocationPath] = useState("");

  const [selectedKeys, setSelectedKeys] = useState(["dashboard"]);
  const [collapsed, setCollapsed] = useState(false);
  const [isBackToAdmin, setIsBackToAdmin] = useState(2);

  useEffect(() => {
    const newLoca = location.pathname.split("/");
    setLocationPath(newLoca[1]);
  }, [location]);

  useEffect(() => {
    setTimeout(() => {
      setIsBackToAdmin(2);
    }, 300);
  }, [userType]);

  useEffect(() => {
    if (location?.pathname) {
      console.log(location?.pathname === "/", "location?.pathname");
      if (location?.pathname === "/") {
        return setSelectedKeys(["dashboard"]);
      }
      setCollapsed(false);
      const currantMenu = location?.pathname?.split("/");
      console.log(location?.pathname, currantMenu, "currantMenu-------------");
      console.log(
        nameObject?.[location?.pathname]?.isSubMenu,
        "nameObject?.[location?.pathname]?.isSubMenu----------------"
      );
      if (nameObject?.[location?.pathname]?.isSubMenu === 1) {
        setSelectedKeys([currantMenu?.[currantMenu?.length - 1]]);
      } else {
        setSelectedKeys([currantMenu?.[1]]);
      }
    }
    return () => {};
  }, [location?.pathname]);

  const defaultSubMenuSelected = () => {
    if (collapsed) {
      return [];
    }
    let subMenuPath = window.location.pathname.split("/");
    if (subMenuPath.length == 3) {
      return [subMenuPath?.[1]];
    }
    return [];
  };

  const backToAdmin = () => {
    let adminDataLocal = localStorage.getItem("adminData");
    if (adminDataLocal) {
      adminDataLocal = JSON.parse(adminDataLocal);
      localStorage.setItem("user", adminDataLocal.data);
      localStorage.setItem(
        "userType",
        JSON.parse(adminDataLocal?.data)?.user_type
      );
      localStorage.setItem("token", adminDataLocal.token);
      localStorage.removeItem("adminData");
    }
  };
  console.log(userType, isBackToAdmin, "--------------");

  return (
    <Wrapper>
      <div
        id="kt_app_sidebar"
        className="app-sidebar flex-column"
        data-kt-drawer="true"
        data-kt-drawer-name="app-sidebar"
        data-kt-drawer-activate="{default: true, lg: false}"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="250px"
        data-kt-drawer-direction="start"
        data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle"
        style={{
          display: !screenStatus ? "block" : sidebarStatus ? "block" : "none",
          zIndex: 999,
          position: "absolute",
          inset: 0,
          backgroundColor: "rgb(33 217 89 / 5%)",
          boxShadow: "0px 0px 13px -5px #073d73",
        }}
      >
        <>
          <div
            style={{
              backgroundImage:
                "linear-gradient(359deg, #073d7338, transparent)",
              padding: "20px 0px",
              margin: "0px 16px",
              borderRadius: "0px 0px 20px 20px",
              height: "310px",
              transition: "transform .8s ease",
            }}
            className={`mainProfileDiv ${
              isBackToAdmin == 1 && "fadeOutBottom"
            }  ${userType == 1 && "fadeInTop"}
            ${userType != 1 && "fadeInTopUser"}`}
          >
            <div
              // className="app-sidebar-header d-none d-lg-flex px-6 pb-4"
              className="app-sidebar-header px-6 pb-4"
              id="kt_app_sidebar_header"
            >
              <button
                type="button"
                data-kt-element="selected"
                className="btn btn-outline btn-custom btn-flex w-100"
                data-kt-menu-trigger="click"
                data-kt-menu-placement="bottom-start"
                data-kt-menu-offset="0px, -1px"
              >
                <img
                  alt="Logo"
                  src="/media/zonflip-logo.png"
                  className="h-60px shimmer"
                />
              </button>
            </div>
            <div className="flex-column">
              <div className="d-flex justify-content-center mb-5">
                <div
                  className="userProfileNotFound"
                  style={{
                    height: collapsed ? "45px" : "60px",
                    width: collapsed ? "45px" : "60px",
                    fontSize: collapsed ? "24px" : "30px",
                  }}
                >
                  <span style={{ textTransform: "uppercase" }}>
                    {user?.name?.charAt(0)}
                  </span>
                </div>
              </div>

              <div className="text-center">
                <span
                  // href="#"
                  className="text-gray-900 fs-3 fw-bold"
                >
                  {user?.name || user?.seller_name}
                </span>
                <span className="text-gray-600 fw-bold d-block fs-7 mb-1">
                  {user?.email}
                </span>
              </div>
              <div className="d-flex justify-content-center backToAdminLink">
                {localStorage.getItem("adminData") && (
                  <>
                    <span
                      className="d-flex align-items-center"
                      id="btnBackToAdmin"
                      style={{
                        color: "#073d73",
                        cursor: "pointer",
                        fontWeight: "bold",
                        fontSize: "15px",
                        marginTop: "21px",
                      }}
                      onClick={() => {
                        setIsBackToAdmin(1);
                        backToAdmin();
                        message.success("Success");
                        history.replace("/manage-user");
                      }}
                    >
                      <i
                        class="bi bi-person-fill-up me-2"
                        style={{ color: "#073d73", fontSize: "25px" }}
                      />
                      Back to admin
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
        <div
          className="app-sidebar-navs flex-column-fluid my-6"
          id="kt_app_sidebar_navs"
          style={{
            height: "calc(100vh - (121px + 190px + 121px))",
            overflowY: "scroll",
          }}
        >
          {/* <div
            id="kt_app_sidebar_navs_wrappers"
            className="hover-scroll-y my-2"
            data-kt-scroll="true"
            data-kt-scroll-activate="true"
            data-kt-scroll-height="auto"
            data-kt-scroll-dependencies="#kt_app_sidebar_header, #kt_app_sidebar_footer"
            data-kt-scroll-wrappers="#kt_app_sidebar_navs"
            data-kt-scroll-offset="5px"
            // style={{ height: "701px" }}
          >
            <div
              id="#kt_app_sidebar_menu"
              data-kt-menu="true"
              data-kt-menu-expand="false"
              className="menu menu-column menu-rounded menu-sub-indention menu-active-bg"
            >
              <div className="menu-item">
                <span
                  className={`menu-link ${
                    locationPath === "dashboard" && "active"
                  }`}
                  onClick={() => {
                    history.replace("/dashboard");
                  }}
                >
                  <span className="menu-icon">
                    <i className="ki-outline ki-notification-status fs-1" />
                  </span>

                  <span className="menu-title">Dashboard</span>
                </span>
              </div>

              <div className="menu-item">
                <span
                  className={`menu-link ${
                    locationPath === "graph-testing" && "active"
                  }`}
                  onClick={() => {
                    history.replace("/graph-testing");
                  }}
                >
                  <span className="menu-icon">
                    <i className="ki-outline ki-notification-status fs-1" />
                  </span>

                  <span className="menu-title">Graph Testing</span>
                </span>
              </div>

              <div className="menu-item">
                <span
                  className={`menu-link ${
                    locationPath === "campaign-analytics" && "active"
                  }`}
                  onClick={() => {
                    history.replace("/campaign-analytics");
                  }}
                >
                  <span className="menu-icon">
                    <i className="ki-outline ki-graph-3 fs-1" />
                  </span>

                  <span className="menu-title">Campaigns Analytics</span>
                </span>
              </div>

              <div className="menu-item">
                <span
                  className={`menu-link ${
                    locationPath === "campaign-analytics" && "active"
                  }`}
                  onClick={() => {
                    history.replace("/campaign-analytics");
                  }}
                >
                  <span className="menu-icon">
                    <i className="ki-outline ki-data fs-1" />
                  </span>

                  <span className="menu-title">Campaign Management</span>
                </span>
              </div>

              <div className="menu-item">
                <span
                  className={`menu-link ${
                    locationPath === "brand-management" && "active"
                  }`}
                  onClick={() => {
                    history.replace("/brand-management");
                  }}
                >
                  <span className="menu-icon">
                    <i className="ki-outline ki-abstract-26 fs-1" />
                  </span>

                  <span className="menu-title">Brand Management</span>
                </span>
              </div>

              <div
                data-kt-menu-trigger="click"
                className="menu-item menu-accordion"
              >
                <span className="menu-link">
                  <span className="menu-icon">
                    <i className="ki-outline ki-chart-simple-2 fs-2" />
                  </span>
                  <span className="menu-title">Application Logs</span>
                  <span className="menu-arrow" />
                </span>

                <div className="menu-sub menu-sub-accordion">
                  <div className="menu-item">
                    <a className="menu-link" href="Central-Log.html">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot" />
                      </span>
                      <span className="menu-title">Central Log</span>
                    </a>
                  </div>

                  <div className="menu-item">
                    <a className="menu-link" href="System-Log.html">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot" />
                      </span>
                      <span className="menu-title">System Error Log</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <Menu
            selectedKeys={selectedKeys}
            mode="inline"
            inlineCollapsed={collapsed}
            items={convertSidebarLinks(userType == 1 ? "admin" : "user")}
            onClick={(e) => {
              setSelectedKeys(e?.key);
            }}
            defaultOpenKeys={defaultSubMenuSelected()}
          />
        </div>

        <div
          className="app-sidebar-footer d-flex flex-stack px-11 pb-10 justify-content-center"
          id="kt_app_sidebar_footer"
        >
          {/* <div className id="kt_header_user_menu_toggle">
            <Dropdown
              overlay={menu}
              trigger={["click"]}
              placement="topLeft"
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
              <Tooltip placement="right" title="User profile">
                <div className="cursor-pointer symbol symbol-circle symbol-50px">
                  <img src="/media/300-2.jpg" alt="image" />
                </div>
              </Tooltip>
            </Dropdown>
          </div> */}

          <span
            className="btn btn-sm btn-outline btn-flex btn-custom px-3"
            onClick={() => {
              localStorage.clear();
              return history.replace("/login");
            }}
          >
            <i className="ki-outline ki-entrance-left fs-2 me-2" />
            Logout
          </span>
        </div>
      </div>
    </Wrapper>
  );
}
