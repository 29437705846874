import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SystemErrorLog from "../../../modules/pages/application-log/system-error-log";
import {
  GetSystemErrorLogList,
  fakeActionSystemErrorLog,
} from "../../../redux/modules/application-log/system-error-log/system-error-log.action";
const mapStateToProps = (state) => ({
  GetSystemErrorLogListResponse:
    state.SystemErrorLog.GetSystemErrorLogListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetSystemErrorLogList,
      fakeActionSystemErrorLog,
    },
    dispatch
  );

const SystemErrorLogZonFlip = connect(
  mapStateToProps,
  mapDispatchToProps
)(SystemErrorLog);

export default SystemErrorLogZonFlip;
