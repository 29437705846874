import { Input, Tag, message } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

export default function (props) {
  const {
    show,
    onHide,
    selectedAsin,
    onSuccess,
    AddKeywordAction,
    fakeActionCampaignManagement,
  } = props;

  const AddKeywordActionRes = useSelector(
    (state) => state.CampaignManagement.AddKeywordActionResponse || {}
  );

  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    if (AddKeywordActionRes.status === true) {
      console.log(AddKeywordActionRes, "AddKeywordActionRes");
      message.destroy();
      message.success(AddKeywordActionRes?.message);
      setKeyword("");
      onSuccess();
      fakeActionCampaignManagement("AddKeywordActionResponse");
    } else if (AddKeywordActionRes.status === false) {
      message.destroy();
      message.error(AddKeywordActionRes?.message);
      fakeActionCampaignManagement("AddKeywordActionResponse");
    }
  }, [AddKeywordActionRes]);

  return (
    <Modal centered show={show} onHide={onHide} size="md">
      <Modal.Header>
        <Modal.Title>Add Keyword</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div>
            <Tag color="blue">ASIN : {selectedAsin}</Tag>
          </div>
          <div className="mt-5">
            <label className="fw-bold fs-6">Keyword</label>
            <Input
              size="large"
              placeholder="Enter Keyword"
              value={keyword}
              onChange={(e) => {
                setKeyword(e.target.value);
              }}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            className="btn btn-light-danger me-5"
            onClick={() => {
              message.destroy();
              onHide();
            }}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            disabled={!keyword}
            onClick={() => {
              if (!keyword) {
                message.destroy();
                return message.warning("Please Enter Keyword");
              }
              message.destroy();
              message.loading("Loading...", 0);
              const Obj = {
                asin: selectedAsin,
                keyword: keyword,
              };
              AddKeywordAction(Obj);
            }}
          >
            Save
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
