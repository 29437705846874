import axiosCall from "../../configurations/network-services/axiosCall";

const prefix = "user";

export const GetUserList = (data) => {
  const path = `${prefix}?page=${data?.page || 1}&pageSize=${
    data?.perPage || 10
  }`;
  const responseType = "GET_USER_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const SwitchUserAction = (id) => {
  const path = `${prefix}/switch-user/${id}`;
  const responseType = "SWITCH_USER_ACTION";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const AddUserAction = (userId, data) => {
  const method = userId ? "PUT" : "post";
  const path = userId ? `${prefix}/${userId}` : `${prefix}`;
  const responseType = "ADD_USER";
  return axiosCall(method, path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const deleteUserAction = (id) => {
  const path = `${prefix}/${id}`;
  const responseType = "DELETE_USER";
  return axiosCall("DELETE", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionManageUser = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_MANAGE_USER", state: data });
};
