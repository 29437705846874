import axiosCall from "../../configurations/network-services/axiosCall";

export const getMarketplaceAmazonAction = () => {
  const path = `marketplaces`;
  const responseType = "GET_MARKETPLACE_LIST_AMAZON";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const getUserCredentialsListAction = () => {
  const path = `user-credentials`;
  const responseType = "GET_USER_CREDENTIALS_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GenerateRefreshAdTokenAction = (data) => {
  const path = `user-credential/generate-refresh-ad-token`;
  const responseType = "USER_CREDENTIAL_GENERATE_AD_TOKEN_REFRESH";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GenerateRefreshTokenAction = (data) => {
  const path = `user-credential/generate-refresh-token`;
  const responseType = "USER_CREDENTIAL_GENERATE_REFRESH";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const UserCredentialSaveAdProfileAction = (data) => {
  const path = `user-credential/save-ad-profile`;
  const responseType = "USER_CREDENTIAL_SAVE_AD_PROFILE";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const getUserCredentialsGenerateRefreshTokenAction = (data) => {
  const path = `user-credential/generate-refresh-token`;
  const responseType = "GET_USER_CREDENTIALS_GENERATE_REFRESH_TOKEN";
  return axiosCall("get", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionSettingUserCredentials = () => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_SETTING_USER_CREDENTIALS" });
};
