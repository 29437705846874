import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CampaignAnalytics from "../../modules/pages/campaign-analytics";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const CampaignAnalyticsZonFlip = connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignAnalytics);

export default CampaignAnalyticsZonFlip;
