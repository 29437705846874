import axiosCall from "../../configurations/network-services/axiosCall";

export const loginAction = (payload) => {
  const path = `login`;
  const responseType = "LOGIN";
  const body = payload;
  return axiosCall("post", path, responseType, body);
};
export const SignUpAction = (payload) => {
  const path = `signup`;
  const responseType = "SIGNUP";
  const body = payload;
  return axiosCall("post", path, responseType, body);
};
export const ForgotPasswordAction = (payload) => {
  const path = `change-new-password`;
  const responseType = "FORGOT_PASSWORD";
  const body = payload;
  return axiosCall("put", path, responseType, body);
};

export const fakeActionAuth = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_AUTH", state: data });
};
