export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_CAMPAIGN_MANAGEMENT_LIST_SUCCESS":
    case "GET_CAMPAIGN_MANAGEMENT_LIST_ERROR":
      return {
        ...state,
        GetCampaignManagementListResponse: action.updatePayload,
      };

    case "GET_KEYWORD_DETAILS_LIST_SUCCESS":
    case "GET_KEYWORD_DETAILS_LIST_ERROR":
      return {
        ...state,
        GetCampaignDetailsListResponse: action.updatePayload,
      };

    case "GET_KEYWORD_ASIN_LIST_SUCCESS":
    case "GET_KEYWORD_ASIN_LIST_ERROR":
      return {
        ...state,
        GetKeywordASINListResponse: action.updatePayload,
      };
    case "GET_MARKETPLACE_BY_CRED_SUCCESS":
    case "GET_MARKETPLACE_BY_CRED_ERROR":
      return {
        ...state,
        GetMarketplaceByCredResponse: action.updatePayload,
      };

    case "ADD_KEYWORD_ACTION_SUCCESS":
    case "ADD_KEYWORD_ACTION_ERROR":
      return {
        ...state,
        AddKeywordActionResponse: action.updatePayload,
      };

    case "FAKE_ACTION_KEYWORD_MANAGEMENT":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
