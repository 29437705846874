import React, { useEffect, useState } from "react";
import { Wrapper } from "./style";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ZoneFlipTable from "../../../../component/zonFlipTable";
import { DefaultPerPage, timeSince } from "../../../../config";
import Pagination from "../../../../component/pagination";
import Loading from "../../../../component/loading";
import moment from "moment";
import { Input, Select, Tag } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { adminCentralLogEventStatus } from "../../../../config/static-select-option";
import NoData from "../../../../component/no-data";
export default function (props) {
  const { GetCentralLogList, fakeActionCentralLog } = props;
  const GetCentralLogListRes = useSelector(
    (state) => state.CentralLog.GetCentralLogListResponse || {}
  );

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);
  const [filter, setFilter] = useState({
    event_status: "",
    event_name: "",
    event_type: "",
    priority: "",
  });

  useEffect(() => {
    const apiObj = {
      page: 1,
      perPage: pageSize,
    };
    GetCentralLogList(apiObj);
    return () => {};
  }, []);

  useEffect(() => {
    if (GetCentralLogListRes.status === true) {
      setList(GetCentralLogListRes?.data?.records);

      console.log(GetCentralLogListRes, "GetCentralLogListResponse");
      setLoading(false);
      setTotalPage(GetCentralLogListRes?.data?.pagination?.totalCount);
      fakeActionCentralLog("GetCentralLogListResponse");
    } else if (GetCentralLogListRes.status === false) {
      setLoading(false);
      fakeActionCentralLog("GetCentralLogListResponse");
    }
  }, [GetCentralLogListRes]);

  const columns = [
    {
      title: "#",
      ellipsis: true,
      align: "left",
      render: (text) => {
        return <span>{(page - 1) * pageSize + 1 + text.key}</span>;
      },
    },

    {
      title: "Log Created at",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>
              {moment(new Date(item?.created_at * 1000)).format(
                "MMM DD, YYYY hh:mm A"
              )}
            </span>
          </div>
        );
      },
    },
    {
      title: "Event Date",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>
              {moment(new Date(item?.event_date)).format("MMM DD, YYYY")}
            </span>
          </div>
        );
      },
    },
    {
      title: "Event Name",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>
              <label className="fw-bold">{item?.event_name || "-"}</label>
              &nbsp;(
              {JSON.parse(item?.event_details)?.record_type})
            </span>
          </div>
        );
      },
    },
    {
      title: "Event Type",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>{item?.event_type || "-"}</span>
          </div>
        );
      },
    },
    {
      title: "Event Status",
      align: "left",
      render: (item) => {
        return (
          <div>
            <Tag
              color={
                item.event_status === "PENDING"
                  ? "gold"
                  : item.event_status === "IN_PROGRESS"
                  ? "blue"
                  : item.event_status === "DONE"
                  ? "green"
                  : item.event_status === "FAILED"
                  ? "red"
                  : item.event_status === "FATAL"
                  ? "magenta"
                  : item.event_status === "CANCELLED"
                  ? "volcano"
                  : item.event_status === "NOT_FOUND"
                  ? "gold"
                  : "error"
              }
            >
              {item?.event_status}
            </Tag>
          </div>
        );
      },
    },
    {
      title: "Priority",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>
              {item?.priority == 1
                ? "High"
                : item?.priority == 2
                ? "Medium"
                : "Low"}
            </span>
          </div>
        );
      },
    },
    {
      title: "Marketplace",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>{item?.marketplace || "-"}</span>
          </div>
        );
      },
    },
    {
      title: "Updated At",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>
              {moment(new Date(item?.updated_at * 1000)).format(
                "MMM DD, YYYY hh:mm A"
              )}
              <br />({timeSince(item?.updated_at)})
            </span>
          </div>
        );
      },
    },
    {
      title: "Report Requested",
      align: "left",
      render: (item) => {
        return (
          <div>
            <Tag color={item.status == 1 ? "green" : "gold"}>
              <span>{item?.status == 1 ? "DONE" : "PENDING"}</span>
            </Tag>
          </div>
        );
      },
    },

    {
      title: "Reason",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>{item?.note || "-"}</span>
          </div>
        );
      },
    },
  ];

  const onPageNo = (e) => {
    setLoading(true);
    const apiObj = {
      page: e,
      perPage: pageSize,
      ...filter,
    };
    GetCentralLogList(apiObj);
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    const apiObj = {
      page: 1,
      perPage: e,
      ...filter,
    };
    GetCentralLogList(apiObj);
  };

  return (
    <Wrapper className="fadeInRight">
      <>
        {/*begin::Row*/}
        <div className="row gy-5 g-xl-5">
          {/*begin::Col*/}
          <div className="col-xxl-12">
            {/*begin::Tables Widget 9*/}
            <div className="card card-xxl-stretch mb-5 mb-xl-8">
              {/*begin::Header*/}
              <div className="card-header border-bottom-dashed">
                {/*end::Title*/}
                {/*begin::Title*/}
                <h3 className="card-title ">
                  <span className="card-label fw-bold text-gray-900">
                    Central Log
                  </span>
                </h3>
                {/*end::Title*/}
                <div className="card-toolbar">
                  <div className="position-relative me-3">
                    <Select
                      className="w-150px"
                      size="large"
                      placeholder="Event Type"
                      allowClear
                      value={filter?.event_type || null}
                      onChange={(e) => {
                        const filter_ = { ...filter };
                        filter_.event_type = e || "";
                        setFilter(filter_);
                        setLoading(true);
                        setPage(1);
                        const apiObj = {
                          page: 1,
                          perPage: pageSize,
                          ...filter_,
                        };
                        GetCentralLogList(apiObj);
                      }}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      options={[
                        {
                          value: "Advertising Report",
                          label: "Advertising Report",
                        },
                      ]}
                    />
                  </div>
                  <div className="position-relative me-3">
                    {" "}
                    <Select
                      className="w-150px"
                      size="large"
                      placeholder="Event Name"
                      allowClear
                      value={filter?.event_name || null}
                      onChange={(e) => {
                        const filter_ = { ...filter };
                        filter_.event_name = e || "";
                        setFilter(filter_);
                        setLoading(true);
                        setPage(1);
                        const apiObj = {
                          page: 1,
                          perPage: pageSize,
                          ...filter_,
                        };
                        GetCentralLogList(apiObj);
                      }}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      options={[
                        {
                          value: "SPONSORED_PRODUCTS",
                          label: "SPONSORED_PRODUCTS",
                        },
                      ]}
                    />
                  </div>
                  <div className="position-relative me-3">
                    <Select
                      className="w-150px"
                      size="large"
                      placeholder="Event Status"
                      allowClear
                      value={filter?.event_status || null}
                      onChange={(e) => {
                        const filter_ = { ...filter };
                        filter_.event_status = e || "";
                        setFilter(filter_);
                        setLoading(true);
                        setPage(1);
                        const apiObj = {
                          page: 1,
                          perPage: pageSize,
                          ...filter_,
                        };
                        GetCentralLogList(apiObj);
                      }}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      options={adminCentralLogEventStatus.map((d) => {
                        return {
                          label: d.label,
                          value: d.value,
                        };
                      })}
                    />
                  </div>
                  <div className="position-relative ms-3">
                    {" "}
                    <Select
                      className="w-150px"
                      size="large"
                      placeholder="Priority"
                      allowClear
                      value={filter?.priority || null}
                      onChange={(e) => {
                        const filter_ = { ...filter };
                        filter_.priority = e || "";
                        setFilter(filter_);
                        setLoading(true);
                        setPage(1);
                        const apiObj = {
                          page: 1,
                          perPage: pageSize,
                          ...filter_,
                        };
                        GetCentralLogList(apiObj);
                      }}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      options={[
                        {
                          value: "1",
                          label: "High",
                        },
                        {
                          value: "2",
                          label: "Medium",
                        },
                        {
                          value: "3",
                          label: "Low",
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              <div className="card-body pb-5 pt-0">
                <div className="table-responsive mt-5">
                  {/*begin::Table container*/}
                  {loading ? (
                    <Loading />
                  ) : list?.length != 0 ? (
                    <>
                      <ZoneFlipTable
                        columns={columns}
                        fixed="top"
                        dataSource={list}
                        rowKey="key"
                        loading={loading}
                        pagination={false}
                        scroll={{ x: "max-content" }}
                      />
                    </>
                  ) : (
                    <NoData />
                  )}
                </div>
                <Pagination
                  loading={loading || list?.length === 0}
                  pageSize={pageSize}
                  page={page}
                  totalPage={totalPage}
                  onPerPage={onPerPage}
                  onPageNo={onPageNo}
                />
              </div>
              {/*begin::Body*/}
            </div>
            {/*end::Tables Widget 9*/}
          </div>
          {/*end::Col*/}
        </div>
        {/*end::Row*/}
      </>
    </Wrapper>
  );
}
