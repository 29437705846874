import styled from "styled-components";
export const Wrapper = styled.div`
  .btn {
    border: none;
    font-family: "Lato";
    font-size: inherit;
    color: inherit;
    background: none;
    cursor: pointer;
    padding: 25px 80px;
    display: inline-block;
    margin: 15px 30px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    outline: none;
    position: relative;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
  }
  .btn:after {
    content: "";
    position: absolute;
    z-index: -1;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
  }

  /* Pseudo elements for icons */
  .btn:before {
    font-family: "FontAwesome";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    position: relative;
    -webkit-font-smoothing: antialiased;
  }
  /* Icon separator */
  .btn-sep {
    padding: 25px 60px 25px 120px;
  }

  .btn-sep:before {
    background: rgba(0, 0, 0, 0.15);
  }
  /* Button 2 */
  .btn-2 {
    background: #2ecc71;
    color: #fff;
  }

  .btn-2:hover {
    background: #27ae60;
  }

  .btn-2:active {
    background: #27ae60;
    top: 2px;
  }

  .btn-2:before {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    line-height: 3;
    font-size: 140%;
    width: 60px;
  }

  .icon-cart:before {
    content: "\f07a";
  }
`;
