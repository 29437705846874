import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SearchTermReport from "../../../modules/pages/sp-advertisement-report/search-term-report";
import {
  GetSearchTermReportList,
  fakeActionSearchTerm,
} from "../../../redux/modules/sp-advertisement-report/search-term-report/search-term-report.action";

const mapStateToProps = (state) => ({
  GetSearchTermReportListResponse:
    state.SearchTermReport.GetSearchTermReportListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { GetSearchTermReportList, fakeActionSearchTerm },
    dispatch
  );

const SearchTermReportZonFlip = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchTermReport);

export default SearchTermReportZonFlip;
