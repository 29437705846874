import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AdvertisedProductReport from "../../../modules/pages/sp-advertisement-report/advertised-product-report";
import {
  GetAdvertisedProductReportList,
  fakeActionAdvertisedProductReport,
} from "../../../redux/modules/sp-advertisement-report/advertised-product-report/advertised-product-report.action";

const mapStateToProps = (state) => ({
  GetAdvertisedProductReportListResponse:
    state.AdvertisedProductReport.GetAdvertisedProductReportListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { GetAdvertisedProductReportList, fakeActionAdvertisedProductReport },
    dispatch
  );

const AdvertisedProductReportZonFlip = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvertisedProductReport);

export default AdvertisedProductReportZonFlip;
