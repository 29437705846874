import { message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Loading from "../../../../component/loading";
import CustomTooltip from "../../../../component/tooltip";
import { timeSince, urlDecode } from "../../../../config";
import AdList from "./lib/adList";
import AddNewAddModalView from "./lib/new-add";
import NewAddModal from "./new-add";
import Wrapper from "./style";

export default function (props) {
  const {
    GenerateRefreshTokenAction,
    getUserCredentialsListAction,
    GenerateRefreshAdTokenAction,
    fakeActionSettingUserCredentials,
  } = props;
  const history = useHistory();
  const location = useLocation();
  const [tab, setTab] = useState(2);
  const [addNewStatus, setAddNewStatus] = useState(false);
  const userData = JSON.parse(localStorage.getItem("user"));

  const [addNewAddModal, setAddNewAddModal] = useState(false);

  const GetUserCredentialsListRes = useSelector(
    (state) => state.UserCredentials.GetUserCredentialsListResponse || {}
  );
  const UserCredentialGenerateRefreshRes = useSelector(
    (state) => state.UserCredentials.UserCredentialGenerateRefreshResponse || {}
  );
  const UserCredentialGenerateAdTokenRefreshRes = useSelector(
    (state) =>
      state.UserCredentials.UserCredentialGenerateAdTokenRefreshResponse || {}
  );

  const [list, setList] = useState([]);
  const [listLoading, setListLoading] = useState(true);

  const [adListModal, setAdListModal] = useState(false);
  const [adList, setAdList] = useState([]);

  const [selectedMarketplace, setSelectedMarketplace] = useState({
    sellername: userData?.seller_name || "",
    email: userData?.email,
    region_name: "",
    marketplace_id: "",
    seller_type: "seller",
  });

  useEffect(() => {
    if (UserCredentialGenerateAdTokenRefreshRes?.status === true) {
      message.destroy();
      localStorage.removeItem("adCode");
      message.success(UserCredentialGenerateAdTokenRefreshRes?.message);
      setAdListModal(true);
      setAdList(JSON.parse(UserCredentialGenerateAdTokenRefreshRes?.data));

      fakeActionSettingUserCredentials();
    } else if (UserCredentialGenerateAdTokenRefreshRes?.status === false) {
      message.destroy();
      message.error(UserCredentialGenerateAdTokenRefreshRes?.message);
      fakeActionSettingUserCredentials();
    }
  }, [UserCredentialGenerateAdTokenRefreshRes]);
  useEffect(() => {
    if (
      UserCredentialGenerateRefreshRes?.status === true ||
      UserCredentialGenerateRefreshRes?.status === false
    ) {
      message.destroy();
      if (UserCredentialGenerateRefreshRes?.status) {
        message.success(UserCredentialGenerateRefreshRes?.message);
      } else {
        message.error(UserCredentialGenerateRefreshRes?.message);
      }
      setTimeout(() => {
        history.replace("/callback");
      }, 1000);
      fakeActionSettingUserCredentials();
    }
  }, [UserCredentialGenerateRefreshRes]);

  useEffect(() => {
    const callBackAd = JSON.parse(
      localStorage.getItem("credentials") || {}
    )?.advertising_return_url?.split("/");

    if (location.pathname.includes("/spapi-callback")) {
      if (location?.search) {
        const { selling_partner_id, spapi_oauth_code, state } =
          urlDecode(location);
        const dataView = {
          spapi_oauth_code: spapi_oauth_code,
          selling_partner_id: selling_partner_id,
          app_id: JSON.parse(localStorage.getItem("credentials"))
            ?.lwa_application_id,
          seller_name: state?.split("!!")?.[1] || "",
          marketplace_id: state?.split("!!")?.[3] || "",
          credential_type:
            state?.split("!!")?.[4] === "seller" ? "SP-API" : "VENDOR-API",
        };

        message.destroy();
        message.loading("Loading...");
        GenerateRefreshTokenAction(dataView);
      } else {
        setListLoading(true);
        getUserCredentialsListAction();
      }
    } else if (
      `/${callBackAd[callBackAd?.length - 1]}` === location?.pathname
    ) {
      setTab(2);
      if (location.search) {
        const { code, marketplace_id, seller_name } = urlDecode(location);

        if (!code) {
          setListLoading(true);
          getUserCredentialsListAction();
          return;
        }
        localStorage.setItem("adCode", code);
        history.replace(location?.pathname);
        message.destroy();
        message.loading("Loading...", 0);

        GenerateRefreshAdTokenAction({
          ad_code: localStorage.getItem("adCode"),
          marketplace_id,
          seller_name,
        });
      } else {
        setListLoading(true);
        getUserCredentialsListAction();
      }
    }
  }, [location]);

  useEffect(() => {
    if (GetUserCredentialsListRes?.status === true) {
      setList(GetUserCredentialsListRes?.data);
      setListLoading(false);
      if (!adListModal) {
        fakeActionSettingUserCredentials();
      }
    } else if (GetUserCredentialsListRes?.status === false) {
      setListLoading(false);
      setList([]);
      fakeActionSettingUserCredentials();
    }
  }, [GetUserCredentialsListRes]);
  useEffect(() => {
    window.onAmazonLoginReady = function () {
      window.amazon.Login.setClientId(
        JSON.parse(localStorage.getItem("credentials"))?.advertising_client_id
      );
    };
    let a = document.createElement("script");
    a.type = "text/javascript";
    a.async = true;
    a.id = "amazon-login-sdk";
    a.src = "https://assets.loginwithamazon.com/sdk/na/login1.js";
    const getRoot = document.getElementById("amazon-root");
    getRoot.appendChild(a);
    setListLoading(true);
    getUserCredentialsListAction();
    return () => {};
  }, []);

  return (
    <Wrapper>
      <div className="post d-flex flex-column-fluid" id="kt_post">
        {/*begin::Container*/}
        <div id="kt_content_container" className="container-fluid">
          <div className="row">
            <div className="col-md-12 cus-tab">
              <ul className="nav nav-pills mb-5 fs-6" role="tablist">
                {/* <li className="nav-item" role="presentation">
                  <a
                    className={`nav-link fs-7 fw-bold py-3 px-7 me-2 ${
                      tab == 1 ? "active" : ""
                    }`}
                    data-bs-toggle="tab"
                    onClick={() => {
                      setTab(1);
                      history.replace("/spapi-callback");
                    }}
                    aria-selected="false"
                    role="tab"
                    tabIndex={-1}
                  >
                    Amazon SP API Credentials
                  </a>
                </li> */}
                <li className="nav-item" role="presentation">
                  <a
                    className={`nav-link fs-7 fw-bold py-3 px-7 me-2 ${
                      tab == 2 ? "active" : ""
                    }`}
                    data-bs-toggle="tab"
                    onClick={() => {
                      setTab(2);
                      history.replace("/callback");
                    }}
                    aria-selected="true"
                    role="tab"
                  >
                    Amazon Advertising Credentials
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/*begin::Row*/}
          <div className="tab-content" id="myTabContent">
            <div
              className={`tab-pane fade ${tab === 1 ? "active show" : ""}`}
              id="kt_tab_pane_7"
              role="tabpanel"
            >
              <div className="row gy-5 g-xl-5">
                {/*begin::Col*/}
                <div className="col-xxl-12">
                  {/*begin::Tables Widget 9*/}
                  <div className="card card-xxl-stretch mb-5 mb-xl-8">
                    {/*begin::Header*/}
                    <div className="card-header border-bottom-dashed">
                      <h3 className="card-title fw-bold text-dark">
                        Amazon SP API Credentials List
                      </h3>
                      <div className="card-toolbar">
                        <div className="card-toolbar">
                          <button
                            type="button"
                            className="btn btn-primary px-7"
                            onClick={() => {
                              setAddNewStatus(true);
                            }}
                          >
                            Add New{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="card-body py-2">
                      {listLoading ? (
                        <Loading />
                      ) : list?.length !== 0 ? (
                        <div className="table-responsive">
                          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                            <thead>
                              <tr className="fw-bolder text-dark">
                                <th className="w-75px">#</th>
                                <th className="min-w-175px">
                                  Seller Account Name
                                </th>
                                <th className="min-w-75px">ARN</th>
                                <th className="min-w-100px">Region</th>
                                <th className="min-w-150px">Credential Type</th>
                                <th className="min-w-75px">Marketplace</th>
                                <th className="min-w-75px">AWS Access Key</th>
                                <th className="min-w-150px">AWS Secret Key</th>
                                <th className="min-w-175px">LWA ClientID</th>
                                <th className="min-w-175px">LWA Secret</th>
                                <th className="min-w-225px">Refresh Token</th>
                              </tr>
                            </thead>
                            <tbody>
                              {list
                                ?.filter(
                                  (d) =>
                                    d?.credential_type !== "Advertising-API"
                                )
                                ?.map((d, i) => {
                                  const credential = JSON.parse(
                                    d?.credential_details || {}
                                  );
                                  return (
                                    <tr key={i}>
                                      <td>{i + 1}</td>
                                      <td>{credential?.seller_name}</td>
                                      <td>{credential?.role_arn}</td>
                                      <td>{credential?.region}</td>
                                      <td>{d?.credential_type}</td>
                                      <td>{d?.marketplace}</td>
                                      <td>{credential?.access_key}</td>
                                      <td>
                                        <CustomTooltip maxTextWidth={150}>
                                          {credential?.secret_key}
                                        </CustomTooltip>
                                      </td>
                                      <td>
                                        <CustomTooltip maxTextWidth={175}>
                                          {credential?.client_id}
                                        </CustomTooltip>
                                      </td>
                                      <td>
                                        <CustomTooltip maxTextWidth={175}>
                                          {credential?.client_secret}
                                        </CustomTooltip>
                                      </td>
                                      <td>
                                        <CustomTooltip maxTextWidth={225}>
                                          {credential?.refresh_token}
                                        </CustomTooltip>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <div className="table-responsive">
                          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                            <thead>
                              <tr className="fw-bolder text-dark">
                                <th className="w-75px">#</th>
                                <th className="min-w-75px">
                                  Seller Account Name
                                </th>
                                <th className="min-w-75px">ARN</th>
                                <th className="min-w-75px">Region</th>
                                <th className="min-w-75px">Marketplace</th>
                                <th className="min-w-75px">AWS Access Key</th>
                                <th className="min-w-75px">AWS Secret Key</th>
                                <th className="min-w-75px">LWA ClientID</th>
                                <th className="min-w-75px">LWA Secret</th>
                                <th className="min-w-75px">Refresh Token</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td colSpan={12}>
                                  <div className="py-7 text-center">
                                    <div className="ant-empty-image ">
                                      <svg
                                        width={64}
                                        height={41}
                                        viewBox="0 0 64 41"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <g
                                          transform="translate(0 1)"
                                          fill="none"
                                          fillRule="evenodd"
                                        >
                                          <ellipse
                                            fill="#f5f5f5"
                                            cx={32}
                                            cy={33}
                                            rx={32}
                                            ry={7}
                                          />
                                          <g
                                            fillRule="nonzero"
                                            stroke="#d9d9d9"
                                          >
                                            <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z" />
                                            <path
                                              d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                                              fill="#fafafa"
                                            />
                                          </g>
                                        </g>
                                      </svg>
                                    </div>
                                    <div className>No data found...</div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`tab-pane fade ${tab === 2 ? "active show" : ""}`}
              id="kt_tab_pane_8"
              role="tabpanel"
            >
              <div className="row gy-5 g-xl-5">
                {/*begin::Col*/}
                <div className="col-xxl-12">
                  {/*begin::Tables Widget 9*/}
                  <div className="card card-xxl-stretch mb-5 mb-xl-8">
                    {/*begin::Header*/}
                    <div className="card-header border-bottom-dashed">
                      <h3 className="card-title fw-bold text-dark">
                        Amazon Advertising Credentials List
                      </h3>
                      <div className="card-toolbar">
                        {console.log(
                          `${
                            JSON.parse(localStorage.getItem("credentials"))
                              ?.advertising_return_url
                          }?marketplace_id=ATVPDKIKX0DER&seller_name=${
                            JSON.parse(localStorage.getItem("user"))
                              ?.seller_name || "test"
                          }`,
                          "asd"
                        )}
                        {/* <Button onClick={() => setAddNewAddModal(true)}>
                          Add New
                        </Button> */}
                        <div id="amazon-root">
                          <a
                            id="LoginWithAmazon"
                            style={{
                              marginLeft: "10px",
                            }}
                            onClick={() => {
                              let options = {};
                              options.scope =
                                "advertising::campaign_management";
                              // options.scope = "cpc_advertising:campaign_management";
                              options.response_type = "code";
                              window.amazon.Login.authorize(
                                options,
                                `${
                                  JSON.parse(
                                    localStorage.getItem("credentials")
                                  )?.advertising_return_url
                                }?marketplace_id=ATVPDKIKX0DER&seller_name=${
                                  JSON.parse(localStorage.getItem("user"))
                                    ?.seller_name || "test"
                                }`
                              );
                            }}
                          >
                            <img
                              border="0"
                              alt="Login with Amazon"
                              src="https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_gold_156x32.png"
                              width="156"
                              height="32"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="card-body py-2">
                      {listLoading ? (
                        <Loading />
                      ) : list?.length !== 0 ? (
                        <div className="table-responsive">
                          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                            <thead>
                              <tr className="fw-bolder text-dark">
                                <th>#</th>
                                <th className="min-w-175px">
                                  Seller Account Name
                                </th>
                                <th className="min-w-100px">Profile ID</th>
                                <th className="min-w-150px">Credential Type</th>
                                <th className="min-w-100px">Client ID</th>
                                <th className="min-w-125px">Country Code</th>
                                <th className="min-w-125px">Currency Code</th>
                                <th className="min-w-100px">Time Zone</th>
                                <th className="min-w-175px">Created At</th>
                                <th className="min-w-175px">Updated At</th>
                              </tr>
                            </thead>
                            <tbody>
                              {list
                                ?.filter(
                                  (d) =>
                                    d?.credential_type === "Advertising-API"
                                )
                                ?.map((d, i) => {
                                  const credential_details =
                                    JSON.parse(d?.credential_details || {}) ||
                                    {};
                                  return (
                                    <tr key={i}>
                                      <td>{i + 1}</td>
                                      <td>
                                        {credential_details?.seller_name || "-"}
                                      </td>
                                      <td>
                                        {credential_details?.profile_id || "-"}
                                      </td>
                                      <td>{d?.credential_type || "-"}</td>
                                      <td>
                                        {credential_details?.client_id || "-"}
                                      </td>
                                      <td>
                                        {credential_details?.country_code ||
                                          "-"}
                                      </td>
                                      <td>
                                        {credential_details?.currency_code ||
                                          "-"}
                                      </td>
                                      <td>
                                        {credential_details?.time_zone || "-"}
                                      </td>
                                      <td>
                                        <div>
                                          <span>
                                            {moment(
                                              new Date(d.created_at * 1000)
                                            ).format("MMM DD, YYYY hh:mm A")}
                                          </span>
                                          <br />
                                          <span className="timeStampColor">
                                            ({timeSince(d.created_at)})
                                          </span>
                                        </div>
                                      </td>
                                      <td>
                                        <div>
                                          <span>
                                            {moment(
                                              new Date(d.updated_at * 1000)
                                            ).format("MMM DD, YYYY hh:mm A")}
                                          </span>
                                          <br />
                                          <span className="timeStampColor">
                                            ({timeSince(d.updated_at)})
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                            {/*end::Table body*/}
                          </table>
                        </div>
                      ) : (
                        <div className="table-responsive">
                          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                            <thead>
                              <tr className="fw-bolder text-dark">
                                <th className="w-75px">#</th>
                                <th className="min-w-75px">
                                  Seller Account Name
                                </th>
                                <th className="min-w-75px">ARN</th>
                                <th className="min-w-75px">Region</th>
                                <th className="min-w-75px">Marketplace</th>
                                <th className="min-w-75px">AWS Access Key</th>
                                <th className="min-w-75px">AWS Secret Key</th>
                                <th className="min-w-75px">LWA ClientID</th>
                                <th className="min-w-75px">LWA Secret</th>
                                <th className="min-w-75px">Refresh Token</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td colSpan={12}>
                                  <div className="py-7 text-center">
                                    <div className="ant-empty-image ">
                                      <svg
                                        width={64}
                                        height={41}
                                        viewBox="0 0 64 41"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <g
                                          transform="translate(0 1)"
                                          fill="none"
                                          fillRule="evenodd"
                                        >
                                          <ellipse
                                            fill="#f5f5f5"
                                            cx={32}
                                            cy={33}
                                            rx={32}
                                            ry={7}
                                          />
                                          <g
                                            fillRule="nonzero"
                                            stroke="#d9d9d9"
                                          >
                                            <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z" />
                                            <path
                                              d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                                              fill="#fafafa"
                                            />
                                          </g>
                                        </g>
                                      </svg>
                                    </div>
                                    <div className>No data found...</div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                      {/*end::Table*/}
                    </div>
                    {/*begin::Body*/}
                  </div>
                  {/*end::Tables Widget 9*/}
                </div>
                {/*end::Col*/}
              </div>
              {/*end::Row*/}
            </div>
          </div>
        </div>
        {/*end::Container*/}
      </div>
      <div id="amazon-root"></div>
      {adListModal && (
        <AdList
          show={adListModal}
          data={adList?.map((d, i) => {
            return { ...d, key: i };
          })}
          getList={() => {
            setListLoading(true);
            getUserCredentialsListAction();
          }}
          onHide={() => {
            setAdList([]);
            setAdListModal(false);
          }}
          {...props}
        />
      )}
      {addNewAddModal && (
        <AddNewAddModalView
          show={addNewAddModal}
          close={() => setAddNewAddModal(false)}
          {...props}
        />
      )}
      {addNewStatus && (
        <NewAddModal
          show={addNewStatus}
          onHide={() => {
            setAddNewStatus(false);
            setSelectedMarketplace({
              sellername: userData?.seller_name || "",
              email: userData?.email,
              region_name: "",
              marketplace_id: "",
            });
          }}
          setSelectedMarketplace={setSelectedMarketplace}
          selectedMarketplace={selectedMarketplace}
          {...props}
        />
      )}
    </Wrapper>
  );
}
