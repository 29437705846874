import React, { Suspense } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Pages from "./containers";
import Login from "./containers/auth/index";
import SignUp from "./containers/auth/signup";
import ForgotPassword from "./containers/auth/forgot-password";

function AppRoute(props) {
  return (
    <BrowserRouter history={props.history}>
      <Suspense>
        <Switch>
          <Route path="/login" component={Login} exact />
          <Route path="/sign-up" component={SignUp} exact />
          <Route path="/forgot-password" component={ForgotPassword} exact />
          <Route path="/" component={Pages} />
          <Redirect to="/" />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default AppRoute;
