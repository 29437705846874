import axiosCall from "../../../configurations/network-services/axiosCall";

// const prefix = "dashboard";

export const GetSystemErrorLogList = (data) => {
  const path = `user/system-event-logs?page=${data?.page || 1}&pageSize=${
    data?.perPage || 10
  }`;
  const responseType = "GET_SYSTEM_ERROR_LOG_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionSystemErrorLog = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_SYSTEM_ERROR_LOG", state: data });
};
