export const ENDPOINT = "https://api.zonflip.com";
export const API_URL = `${ENDPOINT}/api/v1/`;
export const pageDropdown = [10, 25, 50, 100];
export const DefaultPerPage = 10;
export const DateFormat = "MMM DD, YYYY";

export const timeSince = (date) => {
  const units = [
    { label: "year", seconds: 31536000 },
    { label: "month", seconds: 2592000 },
    { label: "day", seconds: 86400 },
    { label: "hour", seconds: 3600 },
    { label: "minute", seconds: 60 },
    { label: "second", seconds: 1 },
  ];

  const seconds = Math.floor((new Date() - date * 1000) / 1000);

  for (let i = 0; i < units.length; i++) {
    const interval = seconds / units[i].seconds;
    if (interval > 1) {
      return (
        Math.floor(interval) +
        " " +
        units[i].label +
        (interval > 1 ? "s" : "") +
        " ago"
      );
    }
  }

  return "just now";
};

export const urlDecode = (url) => {
  return (url?.search?.split("?")[1]?.split("&") || []).reduce((newObj, e) => {
    const obj = e.split("=");
    return { ...newObj, [obj[0]]: obj[1] };
  }, {});
};

export const MarketplaceFlag = (marketplaceList) =>
  marketplaceList.map((d, i) => {
    return {
      value: d?.marketplace_id,
      label: (
        <>
          <img
            src={`/media/domainImage/${d?.marketplace_id}.png`}
            style={{ height: "20px" }}
            loading="lazy"
            className="me-3"
            onError={(e) => {
              e.target.src = "/media/domainImage/red-flag.png";
            }}
          />
          {d?.marketplace_name}({d?.sales_channel})
        </>
      ),
      ...d,
    };
  });
