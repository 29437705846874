import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ZoneFlipTable from "../../../../component/zonFlipTable";
import Pagination from "../../../../component/pagination";
import { DefaultPerPage } from "../../../../config";
import Loading from "../../../../component/loading";
import { SearchOutlined } from "@ant-design/icons";
import { Input, Select, Tag } from "antd";
import NoData from "../../../../component/no-data";
import moment from "moment";

export default function (props) {
  const location = useLocation();
  const { GetAdvertisedProductReportList, fakeActionAdvertisedProductReport } =
    props;
  const GetAdvertisedProductReportListRes = useSelector(
    (state) =>
      state.AdvertisedProductReport.GetAdvertisedProductReportListResponse || {}
  );

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);

  useEffect(() => {
    const apiObj = {
      page: 1,
      perPage: pageSize,
    };
    GetAdvertisedProductReportList(apiObj);
  }, []);

  useEffect(() => {
    if (GetAdvertisedProductReportListRes.status === true) {
      setList(GetAdvertisedProductReportListRes?.data?.records);
      console.log(
        GetAdvertisedProductReportListRes,
        "GetAdvertisedProductReportListRes"
      );
      setTotalPage(
        GetAdvertisedProductReportListRes?.data?.pagination?.totalCount
      );
      setLoading(false);
      fakeActionAdvertisedProductReport(
        "GetAdvertisedProductReportListResponse"
      );
    } else if (GetAdvertisedProductReportListRes.status === false) {
      setLoading(false);
      fakeActionAdvertisedProductReport(
        "GetAdvertisedProductReportListResponse"
      );
    }
  }, [GetAdvertisedProductReportListRes]);

  const onPageNo = (e) => {
    setLoading(true);
    const apiObj = {
      page: e,
      perPage: pageSize,
    };
    GetAdvertisedProductReportList(apiObj);
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    const apiObj = {
      page: 1,
      perPage: e,
    };
    GetAdvertisedProductReportList(apiObj);
  };
  const columns = [
    {
      title: "#",
      align: "left",
      ellipsis: true,

      render: (_, __, i) => {
        return <span>{(page - 1) * pageSize + 1 + i}</span>;
      },
    },
    {
      title: "Report Date",
      align: "left",
      render: (item) => {
        return (
          <span>
            {moment(new Date(item?.report_date)).format("MMM DD, YYYY")}
          </span>
        );
      },
    },
    {
      title: "Campaign Name",
      align: "left",
      width: 200,

      render: (item) => {
        return <div>{item?.campaign_name || "-"}</div>;
      },
    },
    {
      title: "Campaign Id",
      align: "left",
      render: (item) => {
        return <div>{item?.campaign_id || "-"}</div>;
      },
    },
    {
      title: "Campaign Status",
      align: "left",
      render: (item) => {
        return <div>{item?.campaign_status || "-"}</div>;
      },
    },
    {
      title: "Campaign Budget Amount",
      align: "left",
      render: (item) => {
        return (
          <span className="d-flex align-item-center">
            ${item?.campaign_budget_amount || 0}
          </span>
        );
      },
    },
    {
      title: "Campaign Budget Type",
      align: "left",
      render: (item) => {
        return <div>{item?.campaign_budget_type || "-"}</div>;
      },
    },
    {
      title: "Campaign Rule Based Budget Amount",
      align: "left",
      render: (item) => {
        return (
          <span className="d-flex align-item-center">
            ${item?.campaign_rule_based_budget_amount || 0}
          </span>
        );
      },
    },
    // {
    //   title: "Campaign Appthcable Budget Rule Id",
    //   align: "left",
    //   render: (item) => {
    //     return <div>{item?.campaign_applicable_budget_rule_id || "-"}</div>;
    //   },
    // },
    // {
    //   title: "Campaign Appthcable Budget Rule Name",
    //   align: "left",
    //   render: (item) => {
    //     return <div>{item?.campaign_applicable_budget_rule_name || "-"}</div>;
    //   },
    // },
    {
      title: "Campaign Budget Currency Code",
      align: "left",
      render: (item) => {
        return <div>{item?.campaign_budget_currency_code || "-"}</div>;
      },
    },
    {
      title: "Impressions",
      align: "left",
      render: (item) => {
        return <div>{item?.impressions || "-"}</div>;
      },
    },
    {
      title: "Clicks",
      align: "left",
      render: (item) => {
        return <div>{item?.clicks || "-"}</div>;
      },
    },
    {
      title: "Cost",
      align: "left",
      render: (item) => {
        return (
          <span className="d-flex align-item-center">{item?.cost || 0}</span>
        );
      },
    },
    {
      title: "Purchases1d",
      align: "left",
      render: (item) => {
        return <div>{item?.purchases1d || "-"}</div>;
      },
    },
    {
      title: "Purchases7d",
      align: "left",
      render: (item) => {
        return <div>{item?.purchases7d || "-"}</div>;
      },
    },
    {
      title: "Purchases14d",
      align: "left",
      render: (item) => {
        return <div>{item?.purchases14d || "-"}</div>;
      },
    },
    {
      title: "Purchases30d",
      align: "left",
      render: (item) => {
        return <div>{item?.purchases30d || "-"}</div>;
      },
    },
    {
      title: "Purchases Same Sku1d",
      align: "left",
      render: (item) => {
        return <div>{item?.purchases_same_sku1d || "-"}</div>;
      },
    },
    {
      title: "Purchases Same Sku7d",
      align: "left",
      render: (item) => {
        return <div>{item?.purchases_same_sku7d || "-"}</div>;
      },
    },
    {
      title: "Purchases Same Sku14d",
      align: "left",
      render: (item) => {
        return <div>{item?.purchases_same_sku14d || "-"}</div>;
      },
    },
    {
      title: "Purchases Same Sku30d",
      align: "left",
      render: (item) => {
        return <div>{item?.purchases_same_sku30d || "-"}</div>;
      },
    },
    {
      title: "Units Sold Cthcks1d",
      align: "left",
      render: (item) => {
        return <div>{item?.units_sold_clicks1d || "-"}</div>;
      },
    },
    {
      title: "Units Sold Cthcks7d",
      align: "left",
      render: (item) => {
        return <div>{item?.units_sold_clicks7d || "-"}</div>;
      },
    },
    {
      title: "Units Sold Cthcks14d",
      align: "left",
      render: (item) => {
        return <div>{item?.units_sold_clicks14d || "-"}</div>;
      },
    },
    {
      title: "Units Sold Cthcks30d",
      align: "left",
      render: (item) => {
        return <div>{item?.units_sold_clicks30d || "-"}</div>;
      },
    },
    {
      title: "Sales1d",
      align: "left",
      render: (item) => {
        return <div>${item?.sales1d || "0"}</div>;
      },
    },
    {
      title: "Sales7d",
      align: "left",
      render: (item) => {
        return <div>${item?.sales7d || "0"}</div>;
      },
    },
    {
      title: "Sales14d",
      align: "left",
      render: (item) => {
        return <div>${item?.sales14d || "0"}</div>;
      },
    },
    {
      title: "Sales30d",
      align: "left",
      render: (item) => {
        return <div>${item?.sales30d || "0"}</div>;
      },
    },
    {
      title: "Attributed Sales Same Sku1d",
      align: "left",
      render: (item) => {
        return <div>${item?.attributed_sales_same_sku1d || "0"}</div>;
      },
    },
    {
      title: "Attributed Sales Same Sku7d",
      align: "left",
      render: (item) => {
        return <div>${item?.attributed_sales_same_sku7d || "0"}</div>;
      },
    },
    {
      title: "Attributed Sales Same Sku14d",
      align: "left",
      render: (item) => {
        return <div>${item?.attributed_sales_same_sku14d || "0"}</div>;
      },
    },
    {
      title: "Attributed Sales Same Sku30d",
      align: "left",
      render: (item) => {
        return <div>${item?.attributed_sales_same_sku30d || 0}</div>;
      },
    },
    {
      title: "Units Sold Same Sku1d",
      align: "left",
      render: (item) => {
        return <div>{item?.units_sold_same_sku1d || "-"}</div>;
      },
    },
    {
      title: "Units Sold Same Sku7d",
      align: "left",
      render: (item) => {
        return <div>{item?.units_sold_same_sku7d || "-"}</div>;
      },
    },
    {
      title: "Units Sold Same Sku14d",
      align: "left",
      render: (item) => {
        return <div>{item?.units_sold_same_sku14d || "-"}</div>;
      },
    },
    {
      title: "Units Sold Same Sku30d",
      align: "left",
      render: (item) => {
        return <div>{item?.units_sold_same_sku30d || "-"}</div>;
      },
    },
    {
      title: "Kindle Edition Normalized Pages Read14d",
      align: "left",
      render: (item) => {
        return (
          <div>{item?.kindle_edition_normalized_pages_read14d || "0"}</div>
        );
      },
    },
    {
      title: "Kindle Edition Normalized Pages Royalties 14d",
      align: "left",
      render: (item) => {
        return (
          <div>
            {item?.kindle_edition_normalized_pages_royalties_14d || "0"}
          </div>
        );
      },
    },
    // {
    //   title: "Campaign Bidding Strategy",
    //   align: "left",    //
    //   render: (item) => {
    //     return <div>{item?.campaign_bidding_strategy || "-"}</div>;
    //   },
    // },
    {
      title: "Cost Per Click",
      align: "left",
      render: (item) => {
        return (
          <span className="d-flex align-item-center">
            ${item?.cost_per_click || 0}
          </span>
        );
      },
    },
    {
      title: "Click Through Rate",
      align: "left",
      render: (item) => {
        return (
          <span className="d-flex align-item-center">
            ${item?.click_through_rate || 0}
          </span>
        );
      },
    },
    {
      title: "Spend",
      align: "left",
      render: (item) => {
        return (
          <span className="d-flex align-item-center">${item?.spend || 0}</span>
        );
      },
    },
    {
      title: "Marketplace Id",
      align: "left",
      render: (item) => {
        return <div>{item?.marketplace_id || "-"}</div>;
      },
    },
    {
      title: "Marketplace",
      align: "left",
      render: (item) => {
        return <div>{item?.marketplace || "-"}</div>;
      },
    },
    {
      title: "Profile Id",
      align: "left",
      render: (item) => {
        return <div>{item?.profile_id || "-"}</div>;
      },
    },
  ];
  return (
    <>
      <div className="fadeInRight">
        <div className="d-flex flex-wrap gap-3 mb-3">
          <div className="position-relative mr-4"></div>
        </div>
        {/*begin::Row*/}
        <div className="row gy-5 g-xl-5">
          {/*begin::Col*/}
          <div className="col-xxl-12">
            {/*begin::Tables Widget 9*/}
            <div className="card card-xxl-stretch mb-5 mb-xl-8">
              {/*begin::Header*/}
              <div className="card-header border-bottom-dashed">
                {/*end::Title*/}
                {/*begin::Title*/}
                <h3 className="card-title ">
                  <span className="card-label fw-bold text-gray-900">
                    Advertised Product Report
                  </span>
                </h3>
                {/*end::Title*/}
                <div className="card-toolbar">
                  <div className="position-relative mr-4"></div>
                </div>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              <div className="card-body pb-5 pt-0">
                {/*begin::Table container*/}
                <div className="table-responsive mt-5">
                  {loading ? (
                    <Loading />
                  ) : list?.length !== 0 ? (
                    <ZoneFlipTable
                      columns={columns}
                      fixed="top"
                      dataSource={list}
                      rowKey="key"
                      loading={loading}
                      pagination={false}
                      scroll={{ x: "max-content" }}
                    />
                  ) : (
                    <NoData />
                  )}
                </div>
                <Pagination
                  loading={loading || list?.length === 0}
                  pageSize={pageSize}
                  page={page}
                  totalPage={totalPage}
                  onPerPage={onPerPage}
                  onPageNo={onPageNo}
                />
                {/* {loading ? (
                  <Loading />
                ) : (
                  <div className="table-responsive">
                    <ZoneFlipTable
                      columns={columns}
                      fixed="top"
                      dataSource={list}
                      rowKey="key"
                      loading={loading}
                      pagination={false}
                    />
                    <Pagination
                      loading={loading || list?.length === 0}
                      pageSize={pageSize}
                      page={page}
                      totalPage={totalPage}
                      onPerPage={onPerPage}
                      onPageNo={onPageNo}
                    />
                  </div>
                )} */}
              </div>
              {/*begin::Body*/}
            </div>
            {/*end::Tables Widget 9*/}
          </div>
          {/*end::Col*/}
        </div>
        {/*end::Row*/}
      </div>
    </>
  );
}
