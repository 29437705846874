import axiosCall from "../../configurations/network-services/axiosCall";

// const prefix = "dashboard";

export const GetASINList = (data) => {
  const path = `asin-list?page=${data?.page || 1}&pageSize=${
    data?.perPage || 10
  }&searchAsin=${data?.search_value || ""}&marketplace_id=${
    data?.marketplace_id
  }`;
  const responseType = "GET_ASIN_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetCampaignList = (data) => {
  const path = `get-campaigns-list?asin=${data?.asin || ""}&page=${
    data?.page || 1
  }&pageSize=${data?.perPage || 10}&searchCampaignName=${
    data?.searchCampaignName || ""
  }&searchCampaignStatus=${data?.searchCampaignStatus || ""}`;
  const responseType = "GET_CAMPAIGN_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetKeywordList = (data) => {
  const path = `get-keyword-list?campaignId=${data?.campaign_id || ""}&page=${
    data?.page || 1
  }&pageSize=${data?.perPage || 10}&searchKeywordname=${
    data?.searchKeywordName || ""
  }&searchCampaignStatus=${
    data?.searchCampaignStatus || ""
  }&searchKeywordType=${data?.searchKeywordType || ""}`;
  const responseType = "GET_KEYWORD_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetKeywordLogList = (data) => {
  const path = `get-keyword-bid-list?keyword_id=${
    data?.keywordId || ""
  }&start_date=${data?.start_date || ""}&end_date=${data?.end_date || ""}`;
  const responseType = "GET_KEYWORD_LOG_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetNotesLogList = (id) => {
  const path = `keyword-log-list?keyword_id=${id}`;
  const responseType = "GET_NOTES_LOG_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetGraphKeyword = (id) => {
  const path = `graph-keyword-log?keyword_id=${id}`;
  const responseType = "GET_GRAPH_KEYWORD";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetRankGraph = (id) => {
  const path = `get-keyword-graph-rank?keyword_id=${id}`;
  const responseType = "GET_RANK_GRAPH";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const CombinedKeywordGraph = (data) => {
  const path = `combined-keyword-graph-data?keyword_id=${
    data?.keywordId || ""
  }&start_date=${data?.start_date || ""}&end_date=${data?.end_date || ""}`;
  const responseType = "GET_COMBINED_KEYWORD_GRAPH";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const StoreKeyword = (data) => {
  const path = `keyword-store`;
  const responseType = "STORE_KEYWORD";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const UpdateKeyword = (data, id) => {
  const path = `keyword-update/${id}`;
  const responseType = "UPDATE_KEYWORD";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const DeleteKeyword = (data, id) => {
  const path = `keyword-delete/${id}`;
  const responseType = "DELETE_KEYWORD";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionBrandManagement = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_BRAND_MANAGEMENT", state: data });
};
