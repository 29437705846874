export const adminCentralLogEventStatus = [
  {
    label: "PENDING",
    value: "PENDING",
  },
  {
    label: "IN_PROGRESS",
    value: "IN_PROGRESS",
  },
  {
    label: "DONE",
    value: "DONE",
  },
  {
    label: "FAILED",
    value: "FAILED",
  },
  {
    label: "FATAL",
    value: "FATAL",
  },
  {
    label: "CANCELLED",
    value: "CANCELLED",
  },
  {
    label: "NOT_FOUND",
    value: "NOT_FOUND",
  },
];
