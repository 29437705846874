import React, { useEffect, useState } from "react";
import { Wrapper } from "../style";
import {
  Badge,
  Input,
  Radio,
  Select,
  Table,
  Tag,
  Tooltip,
  message,
} from "antd";
import RangePickerZonFlip from "../../../../component/range-picker";
import {
  DateFormat,
  DefaultPerPage,
  MarketplaceFlag,
} from "../../../../config";
import dayjs from "dayjs";
import ZoneFlipTable from "../../../../component/zonFlipTable";
import moment from "moment";
import { useSelector } from "react-redux";
import Loading from "../../../../component/loading";
import NoData from "../../../../component/no-data";
import Pagination from "../../../../component/pagination";
import { useLocation, Link } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import AddKeyword from "./add-keyword";
export default function (props) {
  const {
    GetCampaignDetailsList,
    GetKeywordASINList,
    GetMarketplaceByCred,
    AddKeywordAction,
    fakeActionCampaignManagement,
  } = props;

  const location = useLocation();

  const GetCampaignDetailsListResponse = useSelector(
    (state) => state.CampaignManagement.GetCampaignDetailsListResponse || {}
  );

  const GetKeywordASINListRes = useSelector(
    (state) => state.CampaignManagement.GetKeywordASINListResponse || {}
  );

  const GetMarketplaceByCredRes = useSelector(
    (state) => state.CampaignManagement.GetMarketplaceByCredResponse || {}
  );

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ASINLoading, setASINLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);
  const [selectedAsin, setSelectedAsin] = useState(null);
  const [campaignId, setCampaignId] = useState("");
  const [campaignName, setCampaignName] = useState("");
  const [selectedRank, setSelectedRank] = useState("o");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [asinList, setAsinList] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [marketplaceList, setMarketplaceList] = useState([]);
  const [marketplaceLoading, setMarketplaceLoading] = useState(true);
  const [selectedMarketplace, setSelectedMarketplace] = useState();

  const [value, setValue] = useState(1);
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  const [filters, setFilters] = useState({
    start_date: dayjs().add(-30, "d"),
    end_date: dayjs(),
  });

  useEffect(() => {
    GetMarketplaceByCred();
  }, []);

  useEffect(() => {
    if (GetMarketplaceByCredRes.status === true) {
      console.log(GetMarketplaceByCredRes, "GetMarketplaceByCredRes");
      setMarketplaceList(GetMarketplaceByCredRes?.data?.marketplace_list);
      setMarketplaceLoading(false);
      setSelectedMarketplace(
        GetMarketplaceByCredRes?.data?.marketplace_list?.[0]?.marketplace_id
      );
      GetKeywordASINList(
        GetMarketplaceByCredRes?.data?.marketplace_list?.[0]?.marketplace_id
      );
      fakeActionCampaignManagement("GetMarketplaceByCredResponse");
    } else if (GetMarketplaceByCredRes.status === false) {
      setMarketplaceList([]);
      setMarketplaceLoading(false);
      setSelectedMarketplace("");
      fakeActionCampaignManagement("GetMarketplaceByCredResponse");
    }
  }, [GetMarketplaceByCredRes]);

  useEffect(() => {
    if (GetKeywordASINListRes.status === true) {
      setASINLoading(false);
      console.log(GetKeywordASINListRes?.data, "ASINList");
      setAsinList(GetKeywordASINListRes?.data);
      setSelectedAsin(GetKeywordASINListRes?.data?.[0]);
      const obj = {
        page: 1,
        perPage: pageSize,
        start_date: filters?.start_date?.format("YYYY-MM-DD"),
        end_date: filters?.end_date?.format("YYYY-MM-DD"),
        asin: GetKeywordASINListRes?.data?.[0],
        keyword: searchKeyword,
        marketplace_id: selectedMarketplace,
      };
      GetCampaignDetailsList(obj);
      fakeActionCampaignManagement("GetKeywordASINListResponse");
    } else if (GetKeywordASINListRes.status === false) {
      setASINLoading(false);
      fakeActionCampaignManagement("GetKeywordASINListResponse");
    }
  }, [GetKeywordASINListRes]);

  useEffect(() => {
    if (GetCampaignDetailsListResponse.status === true) {
      setLoading(false);

      const newList = GetCampaignDetailsListResponse?.data?.records.filter(
        (d) => {
          console.log(d?.keyword, "keyword");
          if (
            d?.keyword !== "Placeholder keyword" &&
            d?.keyword !== "substitutes" &&
            d?.keyword !== "loose-match" &&
            d?.keyword !== "close-match" &&
            d?.keyword !== "complements"
          ) {
            return { ...d };
          }
        }
      );
      setList(newList);
      console.log(newList, "newList");
      setLoading(false);
      setTotalPage(
        GetCampaignDetailsListResponse?.data?.pagination?.totalCount
      );
      fakeActionCampaignManagement("GetCampaignDetailsListResponse");
    } else if (GetCampaignDetailsListResponse.status === false) {
      setLoading(false);
      fakeActionCampaignManagement("GetCampaignDetailsListResponse");
    }
  }, [GetCampaignDetailsListResponse]);

  // const columns_ = [
  //   {
  //     title: "Keyword",
  //     dataIndex: "keyword",
  //     key: "keyword",
  //     width: 100,
  //     fixed: "left",
  //   },
  //   {
  //     title: "ASIN",
  //     dataIndex: "advertised_asin",
  //     key: "advertised_asin",
  //     width: 100,
  //     fixed: "left",
  //   },
  //   {
  //     title: (d, _) => {
  //       console.log(d, _, "d");
  //       return <div>{"date"}</div>;
  //     },
  //     children: [
  //       {
  //         title: "O",
  //         dataIndex: "organic_rank",
  //         key: "organic_rank",
  //         width: 150,
  //       },
  //       {
  //         title: "S",
  //         dataIndex: "sponsored_rank",
  //         key: "sponsored_rank",
  //         width: 150,
  //       },
  //     ],
  //   },
  //   {
  //     title: "29 Jun",
  //     children: [
  //       {
  //         title: "O",
  //         dataIndex: "o",
  //         key: "o",
  //         width: 150,
  //       },
  //       {
  //         title: "S",
  //         dataIndex: "s",
  //         key: "s",
  //         width: 150,
  //       },
  //     ],
  //   },
  // ];
  // const data_ = [
  //   {
  //     id: 4,
  //     advertised_asin: "B00SF5CFOQ",
  //     keyword_id: "183670615025698",
  //     keyword: "Keyword 1",
  //     childData: [
  //       {
  //         date: "2023-08-01",
  //         organic_rank: "1",
  //         sponsored_rank: "2",
  //       },
  //       {
  //         date: "2023-08-02",
  //         organic_rank: "1",
  //         sponsored_rank: "2",
  //       },
  //       {
  //         date: "2023-08-03",
  //         organic_rank: "1",
  //         sponsored_rank: "2",
  //       },
  //     ],
  //     created_at: 1692249421,
  //     updated_at: 1692249421,
  //   },
  //   {
  //     id: 4,
  //     advertised_asin: "B00SF5CFOQ",
  //     keyword_id: "183670615025698",
  //     keyword: "Keyword 2",
  //     childData: [
  //       {
  //         date: "2023-08-03",
  //         organic_rank: "1",
  //         sponsored_rank: "2",
  //       },
  //       {
  //         date: "2023-08-04",
  //         organic_rank: "1",
  //         sponsored_rank: "2",
  //       },
  //     ],
  //     created_at: 1692249421,
  //     updated_at: 1692249421,
  //   },
  //   {
  //     id: 4,
  //     advertised_asin: "B00SF5CFOQ",
  //     keyword_id: "183670615025698",
  //     keyword: "Keyword ",
  //     childData: [
  //       {
  //         date: "2023-08-06",
  //         organic_rank: "1",
  //         sponsored_rank: "2",
  //       },
  //       {
  //         date: "2023-08-07",
  //         organic_rank: "1",
  //         sponsored_rank: "2",
  //       },
  //     ],
  //     created_at: 1692249421,
  //     updated_at: 1692249421,
  //   },
  // ];

  const [toggleColumns, setToggleColumns] = useState("os");

  const DateWiseRankTable = TableView(toggleColumns, page, pageSize, list);

  const onPageNo = (e) => {
    setLoading(true);
    const apiObj = {
      page: e,
      perPage: pageSize,
      start_date: filters?.start_date?.format("YYYY-MM-DD"),
      end_date: filters?.end_date?.format("YYYY-MM-DD"),
      asin: selectedAsin,
      keyword: searchKeyword,
      marketplace_id: selectedMarketplace,
    };
    GetCampaignDetailsList(apiObj);
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    const apiObj = {
      page: 1,
      perPage: e,
      start_date: filters?.start_date?.format("YYYY-MM-DD"),
      end_date: filters?.end_date?.format("YYYY-MM-DD"),
      asin: selectedAsin,
      keyword: searchKeyword,
      marketplace_id: selectedMarketplace,
    };
    GetCampaignDetailsList(apiObj);
  };

  return (
    <Wrapper className="fadeInRight">
      <>
        <div className>
          <div
            className="row mx-0 mb-3 mt-0"
            style={{
              background: "#fff",
              padding: "13px 6px",
              borderRadius: "7px",
            }}
          >
            <div className="d-flex flex-wrap gap-3 align-items-center">
              <div className="position-relative">
                <Link
                  to={{
                    pathname: "/campaign-management",
                  }}
                  // target={"blank"}
                >
                  <button
                    class="btn btn-primary btn-icon"
                    style={{ height: "30px", width: "30px" }}
                  >
                    <i class="bi bi-arrow-left-short fs-1"></i>
                  </button>
                </Link>
              </div>
              <div className="position-relative ">
                <RangePickerZonFlip
                  className="ant_common_input"
                  size="large"
                  id="campaign_range_id"
                  allowClear={false}
                  style={{ width: "290px" }}
                  format={DateFormat}
                  value={[filters?.start_date, filters?.end_date]}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  // disabled={chartLoading}
                  onChange={(e) => {
                    const filters_ = filters;
                    filters_.start_date = e?.[0];
                    filters_.end_date = e?.[1];
                    setFilters({ ...filters_ });
                    setPage(1);
                    setPageSize(DefaultPerPage);
                    setLoading(true);
                    const obj = {
                      page: 1,
                      perPage: pageSize,
                      start_date: e?.[0]?.format("YYYY-MM-DD"),
                      end_date: e?.[1]?.format("YYYY-MM-DD"),
                      asin: selectedAsin,
                      keyword: searchKeyword,
                      marketplace_id: selectedMarketplace,
                    };
                    GetCampaignDetailsList(obj);
                  }}
                />
              </div>
              <div className="position-relative ">
                <Select
                  // value={1}
                  placeholder="Select ASIN"
                  size="large"
                  style={{ width: "200px" }}
                  className="fs-7"
                  allowClear={false}
                  showSearch
                  loading={ASINLoading}
                  value={selectedAsin}
                  onChange={(e) => {
                    setSelectedAsin(e);
                    setPage(1);
                    setPageSize(DefaultPerPage);
                    setLoading(true);
                    const obj = {
                      page: 1,
                      perPage: pageSize,
                      start_date: filters?.start_date?.format("YYYY-MM-DD"),
                      end_date: filters?.end_date?.format("YYYY-MM-DD"),
                      asin: e,
                      keyword: searchKeyword,
                      marketplace_id: selectedMarketplace,
                    };
                    GetCampaignDetailsList(obj);
                  }}
                  options={Object.keys(asinList).map((d, i) => {
                    return {
                      value: asinList?.[d],
                      label: asinList?.[d],
                    };
                  })}
                />
              </div>
              <div className="position-relative mr-4">
                <Input
                  type="text"
                  size="large"
                  placeholder="Search Keyword"
                  value={searchKeyword}
                  className="ant_common_input fs-6"
                  prefix={
                    <SearchOutlined
                      style={{ color: "#a1a5b7", fontSize: "18px" }}
                    />
                  }
                  onChange={(e) => {
                    setSearchKeyword(e.target.value);
                  }}
                  onPressEnter={() => {
                    setLoading(true);
                    setPage(1);
                    setPageSize(DefaultPerPage);
                    setLoading(true);
                    const obj = {
                      page: 1,
                      perPage: pageSize,
                      start_date: filters?.start_date?.format("YYYY-MM-DD"),
                      end_date: filters?.end_date?.format("YYYY-MM-DD"),
                      asin: selectedAsin,
                      keyword: searchKeyword,
                      marketplace_id: selectedMarketplace,
                    };
                    GetCampaignDetailsList(obj);
                  }}
                />
              </div>
              <div className="position-relative ms-auto">
                <button
                  className="btn btn-primary fs-7 fw-bold"
                  onClick={() => {
                    if (!selectedAsin) {
                      message.destroy();
                      return message.info("Please Select ASIN");
                    }
                    setIsOpenModal(true);
                  }}
                >
                  <i className="ki-outline ki-plus-circle fs-3" />
                  Add Keyword
                </button>
              </div>
            </div>
          </div>

          <div className="row gy-5 g-xl-5">
            <div className="col-xxl-12">
              <div className="card card-xxl-stretch mb-5 mb-xl-8">
                <div className="card-header border-bottom-dashed">
                  <div className="d-flex flex-center">
                    <h5 className="align-items-start"></h5>
                  </div>

                  <div className="card-toolbar">
                    <div className="d-flex align-items-center">
                      <Radio.Group
                        onChange={(e) => {
                          setToggleColumns(e.target.value);
                        }}
                        value={toggleColumns}
                      >
                        <Radio value={"o"}>
                          <span className="badge badge-circle badge-outline badge-secondary fw-bolder text-success fs-6 me-3">
                            O
                          </span>
                          <span className="fw-semibold fs-6 text-gray-600">
                            Organic Rank
                          </span>
                        </Radio>
                        <Radio value={"s"}>
                          <span className="badge badge-circle badge-outline badge-secondary fw-bolder text-dark fs-6 me-3">
                            S
                          </span>
                          <span className="fw-semibold fs-6 text-gray-600">
                            Sponsored Rank
                          </span>
                        </Radio>
                        <Radio value={"os"}>
                          <span>All</span>
                        </Radio>
                      </Radio.Group>
                    </div>
                    <div className="position-relative">
                      <Select
                        className="w-200px"
                        size="large"
                        placeholder="Select Marketplace"
                        loading={marketplaceLoading}
                        value={selectedMarketplace || null}
                        onChange={(e) => {
                          setSelectedMarketplace(e);
                          setAsinList([]);
                          GetKeywordASINList(e);
                          // setLoading(true);
                          // setPage(1);
                          // setPageSize(DefaultPerPage);
                          // const obj = {
                          //   page: 1,
                          //   perPage: DefaultPerPage,
                          //   start_date:
                          //     filters?.start_date?.format("YYYY-MM-DD"),
                          //   end_date: filters?.end_date?.format("YYYY-MM-DD"),
                          //   asin: selectedAsin,
                          //   keyword: searchKeyword,
                          //   marketplace_id: e,
                          // };
                          // GetCampaignDetailsList(obj);
                        }}
                        options={MarketplaceFlag(marketplaceList)}
                      />
                    </div>
                    {/* <div className="d-flex align-items-center px-5">
                      <div
                        onClick={() => {
                          console.log("O");
                          setToggleColumns("o");
                        }}
                        style={{ cursor: "pointer" }}
                        className="d-flex align-items-center me-6"
                      >
                        <span className="badge badge-circle badge-outline badge-secondary fw-bolder text-success fs-6 me-3">
                          O
                        </span>
                        <span className="fw-semibold fs-6 text-gray-600">
                          Organic Rank
                        </span>
                      </div>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          console.log("S");
                          setToggleColumns("s");
                        }}
                        className="d-flex align-items-center"
                      >
                        <span className="badge badge-circle badge-outline badge-secondary fw-bolder text-dark fs-6 me-3">
                          S
                        </span>
                        <span className="fw-semibold fs-6 text-gray-600">
                          Sponsored Rank
                        </span>
                      </div>
                      <div className="d-flex align-items-center ms-2">
                        <button
                          onClick={() => {
                            setToggleColumns(null);
                          }}
                          class="btn btn-primary btn-icon"
                          style={{ height: "30px", width: "30px" }}
                        >
                          <i class="bi bi-arrow-clockwise fs-4"></i>
                        </button>
                      </div>
                    </div> */}
                  </div>
                </div>

                <div className="card-body py-2 pt-0">
                  <div className="d-flex justify-content-end mt-5">
                    <div className="position-relative d-flex align-items-center">
                      <div
                        style={{
                          height: "7px",
                          width: "7px",
                          borderRadius: "4px",
                          backgroundColor: "orange",
                        }}
                        className="me-2"
                      ></div>
                      <Tooltip
                        title={`keyword having the symbol , added using "Add Keyword" Functionality`}
                      >
                        <label className="fw-bold">Keyword Added By User</label>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="table-responsive my-5">
                    {loading ? (
                      <Loading />
                    ) : list?.length !== 0 ? (
                      <>
                        {/* <div className="row px-0 mx-0">
                          <div className="px-0 mx-0 col-12 col-mg-12 col-lg-9 col-xl-8"></div>
                        </div> */}
                        <DateWiseRankTable data={list} />
                      </>
                    ) : (
                      <NoData message={"No Keyword Found"} />
                    )}
                  </div>
                  <Pagination
                    loading={loading || list?.length === 0}
                    pageSize={pageSize}
                    page={page}
                    totalPage={totalPage}
                    onPerPage={onPerPage}
                    onPageNo={onPageNo}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {isOpenModal && (
          <AddKeyword
            show={isOpenModal}
            onHide={() => {
              setIsOpenModal(false);
            }}
            onSuccess={() => {
              setLoading(true);
              const obj = {
                page: 1,
                perPage: pageSize,
                start_date: filters?.start_date?.format("YYYY-MM-DD"),
                end_date: filters?.end_date?.format("YYYY-MM-DD"),
                asin: selectedAsin,
                keyword: searchKeyword,
                marketplace_id: selectedMarketplace,
              };
              GetCampaignDetailsList(obj);
              setIsOpenModal(false);
            }}
            selectedAsin={selectedAsin}
            {...props}
          />
        )}
      </>
    </Wrapper>
  );
}
function TableView(toggleColumns, page, pageSize, list) {
  useEffect(() => {
    console.log("mount");
    return () => {
      console.log("unmount");
    };
  }, []);
  return ({ data }) => {
    // Extract all unique dates
    const allDates = [
      ...new Set(
        data?.flatMap((item) => item?.childData?.map((entry) => entry?.date))
      ),
    ];

    const findColumns = (date) => {
      const ColumnsO = {
        title: (
          <span className="badge badge-circle badge-outline badge-secondary fw-bolder text-success fs-6">
            O
          </span>
        ),
        align: "center",
        className: "add-cell-padding",
        dataIndex: "childData",
        key: `organic_${date}`,
        render: (childData) => {
          const rank = childData?.find(
            (entry) => entry?.date === date
          )?.organic_rank;
          return (
            <div
              style={{
                color: `${
                  rank == ">106" ? "orange" : rank != "-" ? "#fff" : "#000"
                }`,
                backgroundColor: `${
                  rank != ">106" && rank != "-" ? "#68b958" : "none"
                }`,
                borderRadius: "2px",
              }}
            >
              <span
                style={{
                  fontWeight: `${rank != ">106" && rank != "-" ? "bold" : ""}`,
                }}
              >
                {rank || "-"}
              </span>
            </div>
          );
        },
      };
      const ColumnsS = {
        title: (
          <span className="badge badge-circle badge-outline badge-secondary fw-bolder text-dark fs-6">
            S
          </span>
        ),
        dataIndex: "childData",
        className: "add-cell-padding",
        align: "center",
        key: `sponsored_${date}`,
        render: (childData) => {
          const rank = childData?.find(
            (entry) => entry?.date === date
          )?.sponsored_rank;
          return (
            <>
              <div
                style={{
                  color: `${
                    rank == ">106" ? "#000" : rank != "-" ? "#fff" : "#000"
                  }`,
                  backgroundColor: `${
                    rank != ">106" && rank != "-" ? "#68b958" : "none"
                  }`,
                  borderRadius: "2px",
                }}
              >
                <span
                  style={{
                    fontWeight: `${
                      rank != ">106" && rank != "-" ? "bold" : ""
                    }`,
                  }}
                >
                  {rank || "-"}
                </span>
              </div>
            </>
          );
        },
      };
      if (toggleColumns === "o") {
        return [ColumnsO];
      } else if (toggleColumns === "s") {
        return [ColumnsS];
      } else {
        return [ColumnsO, ColumnsS];
      }
    };

    // Generate columns for each date
    let dateColumns = allDates?.map((date) => ({
      title: moment(date).format("MMM DD"),
      className: "add-cell-padding-3",
      children: findColumns(date),
    }));
    // setAddViewColumns(dateColumns);
    // Calculate rowSpan for each group
    // data.forEach((item) => {
    //   item.rowSpan = item.childData.length;
    // });
    return (
      <Table
        columns={[
          {
            title: "#",
            // ellipsis: true,
            fixed: "left",
            width: 5,
            align: "left",
            render: (_, __, i) => {
              return <span>{(page - 1) * pageSize + 1 + i}</span>;
            },
          },
          {
            title: "Keyword",

            fixed: "left",
            width: 150,
            className: "add-cell-padding",
            render: (text) => {
              return (
                <div className="d-flex align-items-center">
                  <span>{text?.keyword}</span>
                  {text?.add_asin === "YES" && (
                    <div
                      className="ms-2"
                      style={{
                        height: "7px",
                        width: "7px",
                        borderRadius: "4px",
                        backgroundColor: "orange",
                      }}
                    ></div>
                  )}
                </div>
              );

              // props: {
              //   rowSpan: record.rowSpan,
              // },
            },
          },
          {
            title: "ASIN",
            dataIndex: "advertised_asin",
            key: "advertised_asin",
            className: "add-cell-padding",
            // fixed: "left",
            width: 50,
          },
          {
            title: "Sales",
            dataIndex: "sales_14d",
            key: "sales_14d",
            // className: "add-cell-padding",
            // fixed: "left",
            width: 25,
          },
          ...dateColumns,
        ]}
        dataSource={list}
        pagination={false}
        bordered
        scroll={{ x: "max-content" }}
      />
    );
  };
}
