import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CampaignDetails from "../../../modules/pages/keyword-tracker/lib/campaign-details";
import {
  GetCampaignDetailsList,
  GetKeywordASINList,
  GetMarketplaceByCred,
  AddKeywordAction,
  fakeActionCampaignManagement,
} from "../../../redux/modules/keyword-tracker/keyword-tracker.action";

const mapStateToProps = (state) => ({
  GetCampaignDetailsListResponse:
    state.CampaignManagement.GetCampaignDetailsListResponse,
  GetKeywordASINListResponse:
    state.CampaignManagement.GetKeywordASINListResponse,
  GetMarketplaceByCredResponse:
    state.CampaignManagement.GetMarketplaceByCredResponse,
  AddKeywordActionResponse: state.CampaignManagement.AddKeywordActionResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetCampaignDetailsList,
      GetKeywordASINList,
      GetMarketplaceByCred,
      AddKeywordAction,
      fakeActionCampaignManagement,
    },
    dispatch
  );

const CampaignDetailsZonFlip = connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignDetails);

export default CampaignDetailsZonFlip;
