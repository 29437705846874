export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_ASIN_LIST_SUCCESS":
    case "GET_ASIN_LIST_ERROR":
      return {
        ...state,
        GetAsinListResponse: action.updatePayload,
      };

    case "GET_CAMPAIGN_LIST_SUCCESS":
    case "GET_CAMPAIGN_LIST_ERROR":
      return {
        ...state,
        GetCampaignListResponse: action.updatePayload,
      };

    case "GET_KEYWORD_LIST_SUCCESS":
    case "GET_KEYWORD_LIST_ERROR":
      return {
        ...state,
        GetKeywordListResponse: action.updatePayload,
      };

    case "GET_KEYWORD_LOG_LIST_SUCCESS":
    case "GET_KEYWORD_LOG_LIST_ERROR":
      return {
        ...state,
        GetKeywordLogListResponse: action.updatePayload,
      };

    case "GET_NOTES_LOG_LIST_SUCCESS":
    case "GET_NOTES_LOG_LIST_ERROR":
      return {
        ...state,
        GetNotesLogListResponse: action.updatePayload,
      };

    case "GET_GRAPH_KEYWORD_SUCCESS":
    case "GET_GRAPH_KEYWORD_ERROR":
      return {
        ...state,
        GetGraphKeywordResponse: action.updatePayload,
      };

    case "GET_RANK_GRAPH_SUCCESS":
    case "GET_RANK_GRAPH_ERROR":
      return {
        ...state,
        GetRankGraphResponse: action.updatePayload,
      };

    case "GET_COMBINED_KEYWORD_GRAPH_SUCCESS":
    case "GET_COMBINED_KEYWORD_GRAPH_ERROR":
      return {
        ...state,
        CombinedKeywordGraphResponse: action.updatePayload,
      };

    case "STORE_KEYWORD_SUCCESS":
    case "STORE_KEYWORD_ERROR":
      return {
        ...state,
        GetStoreKeywordResponse: action.updatePayload,
      };

    case "UPDATE_KEYWORD_SUCCESS":
    case "UPDATE_KEYWORD_ERROR":
      return {
        ...state,
        GetUpdateKeywordResponse: action.updatePayload,
      };

    case "DELETE_KEYWORD_SUCCESS":
    case "DELETE_KEYWORD_ERROR":
      return {
        ...state,
        GetDeleteKeywordResponse: action.updatePayload,
      };

    case "FAKE_ACTION_BRAND_MANAGEMENT":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
