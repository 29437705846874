import axiosCall from "../../../configurations/network-services/axiosCall";

// const prefix = "dashboard";

export const GetCentralLogList = (data) => {
  const path = `user/system-event-process?page=${data?.page || 1}&pageSize=${
    data?.perPage || 10
  }&event_status=${data?.event_status || ""}&event_name=${
    data?.event_name || ""
  }&event_type=${data?.event_type || ""}&priority=${data?.priority || ""}`;
  const responseType = "GET_CENTRAL_LOG_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionCentralLog = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_CENTRAL_LOG", state: data });
};
