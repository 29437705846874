import styled from "styled-components";
export const Wrapper = styled.div`
  .ant-menu {
    opacity: 0;
    animation: fadeIn 0.2s ease-in both;
    padding: 0 0.5rem;
    background: transparent;
    .ant-menu-item,
    .ant-menu-submenu-title {
      // border: 1px dashed #21d959;
      border-radius: 0px;
      border-left: 4px solid transparent;
      .ant-menu-title-content,
      .ant-menu-item-icon > i {
        transition: 0.1s !important;
      }
      &:hover {
        // background-image: linear-gradient(45deg, #174b71, #8dbb9c);
        background-image: linear-gradient(
          45deg,
          rgb(7 61 115 / 53%),
          rgb(54 218 105 / 26%)
        );
        border-left: 4px solid rgb(7 61 115);
        border-radius: 0px;
        .ant-menu-item-icon {
          i {
            color: #ffffff !important;
          }
        }
        .ant-menu-title-content {
          color: #ffffff !important;
        }
      }
    }

    .ant-menu-item-selected {
      // background-image: linear-gradient(45deg, #174b71, #8dbb9c);
      background-image: linear-gradient(
        45deg,
        rgb(7 61 115 / 53%),
        rgb(54 218 105 / 26%)
      );
      border-left: 4px solid rgb(7 61 115);
      color: #fff;
      font-weight: 600;
      border-radius: 0px;
      .ant-menu-item-icon {
        i {
          color: #ffffff;
        }
      }
      animation-duration: 0.7s;
      animation-fill-mode: both;
      animation-name: fadeInLeftMenu;
    }

    // .ant-menu-item-selected {
    //   background-color: #21d959;
    //   color: #ffffff;
    //   .ant-menu-item-icon {
    //     i {
    //       color: #ffffff;
    //     }
    //   }
    // }
  }

  .ant-menu-light.ant-menu-root.ant-menu-inline {
    border-inline-end: none !important;
  }
  .ant-dropdown .menu {
    border-radius: 0.475rem;
    background-color: #ffffff !important;
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15) !important;
  }

  .userProfileNotFound {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #073d73;
    border-radius: 10px;
    color: #ffffff;
    font-weight: 400;
    // box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset;
    box-shadow: 0 15px 25px -4px rgb(198, 198, 198),
      inset 0 -3px 4px -1px rgba(0, 0, 0, 0.2),
      0 -10px 15px -1px rgba(255, 255, 255, 0.6),
      inset 0 3px 4px -1px rgba(255, 255, 255, 0.2),
      inset 0 0 5px 1px rgba(255, 255, 255, 0.8),
      inset 0 20px 30px 0 rgba(255, 255, 255, 0.2);
  }

  @keyframes fadeInLeftMenu {
    from {
      opacity: 0;
      transform: translateX(-3%);
    }
    to {
      opacity: 1;
    }
  }

  // .mainProfileDiv:hover {
  //   transform: rotateX(-180deg);
  // }
`;
