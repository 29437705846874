import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import KeywordsReport from "../../../modules/pages/sp-advertisement-report/keywords-report";
import {
  GetKeywordReportList,
  fakeActionKeywordReportList,
} from "../../../redux/modules/sp-advertisement-report/keywords-report/keywords-report.action";

const mapStateToProps = (state) => ({
  GetKeywordReportListResponse:
    state.KeywordReport.GetKeywordReportListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { GetKeywordReportList, fakeActionKeywordReportList },
    dispatch
  );

const KeywordsReportZonFlip = connect(
  mapStateToProps,
  mapDispatchToProps
)(KeywordsReport);

export default KeywordsReportZonFlip;
