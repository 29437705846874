import styled from "styled-components";
export const Wrapper = styled.div`
  height: 100%;
`;

export const NewLogin = styled.div`
  .carousel-container {
  }

  .slider-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #f0f0f0;
    font-size: 24px;
    font-weight: bold;
  }
`;
