export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_DASHBOARD_PRODUCT_DETAILS_SUCCESS":
    case "GET_DASHBOARD_PRODUCT_DETAILS_ERROR":
      return {
        ...state,
        GetProductDetailsResponse: action.updatePayload,
      };

    case "FAKE_ACTION_DASHBOARD":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
