import React, { useEffect, useState } from "react";
import { Wrapper } from "../style";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ZoneFlipTable from "../../../../component/zonFlipTable";
import { DefaultPerPage, MarketplaceFlag } from "../../../../config";
import Pagination from "../../../../component/pagination";
import Loading from "../../../../component/loading";
import { Input, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import NoData from "../../../../component/no-data";
import moment from "moment";

export default function (props) {
  const {
    GetASINList,
    GetMarketplaceByCred,
    fakeActionCampaignManagement,
    fakeActionBrandManagement,
    setTabStatus,
    selectedBread,
    setSelectedBread,
  } = props;
  const GetAsinListRes = useSelector(
    (state) => state.AsinManagement.GetAsinListResponse || {}
  );
  const GetMarketplaceByCredRes = useSelector(
    (state) => state.CampaignManagement.GetMarketplaceByCredResponse || {}
  );

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);
  const [searchAsin, setSearchAsin] = useState("");
  const [marketplaceList, setMarketplaceList] = useState([]);
  const [marketplaceLoading, setMarketplaceLoading] = useState(true);
  const [selectedMarketplace, setSelectedMarketplace] = useState();

  useEffect(() => {
    GetMarketplaceByCred();
  }, []);

  useEffect(() => {
    if (GetMarketplaceByCredRes.status === true) {
      console.log(GetMarketplaceByCredRes, "GetMarketplaceByCredRes");
      setMarketplaceList(GetMarketplaceByCredRes?.data?.marketplace_list);
      setMarketplaceLoading(false);
      setSelectedMarketplace(
        GetMarketplaceByCredRes?.data?.marketplace_list?.[0]?.marketplace_id
      );
      const name =
        GetMarketplaceByCredRes?.data?.marketplace_list?.[0]?.marketplace_name;
      const salesChannel =
        GetMarketplaceByCredRes?.data?.marketplace_list?.[0]?.sales_channel;
      const selected_ = { ...selectedBread };
      selected_.marketplace_name = `${name}(${salesChannel})`;
      setSelectedBread(selected_);
      const apiObj = {
        page: 1,
        perPage: pageSize,
        search_value: searchAsin,
        marketplace_id:
          GetMarketplaceByCredRes?.data?.marketplace_list?.[0]?.marketplace_id,
      };
      GetASINList(apiObj);
      fakeActionCampaignManagement("GetMarketplaceByCredResponse");
    } else if (GetMarketplaceByCredRes.status === false) {
      setMarketplaceList([]);
      setMarketplaceLoading(false);
      setSelectedMarketplace("");
      fakeActionCampaignManagement("GetMarketplaceByCredResponse");
    }
  }, [GetMarketplaceByCredRes]);

  useEffect(() => {
    if (GetAsinListRes.status === true) {
      setList(GetAsinListRes?.data?.records);
      console.log(GetAsinListRes, "GetAsinListRes");
      setLoading(false);
      setTotalPage(GetAsinListRes?.data?.pagination?.totalCount);
      fakeActionBrandManagement("GetAsinListResponse");
    } else if (GetAsinListRes.status === false) {
      setLoading(false);
      fakeActionBrandManagement("GetAsinListResponse");
    }
  }, [GetAsinListRes]);

  const columns = [
    {
      title: "#",
      width: 100,
      ellipsis: true,
      align: "left",
      render: (text) => {
        return <span>{(page - 1) * pageSize + 1 + text.key}</span>;
      },
    },

    {
      title: "ASINs",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span style={{ fontWeight: "600" }}>{item?.asin}</span>
          </div>
        );
      },
    },

    {
      title: "Rank",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>{item?.rank || "-"}</span>
          </div>
        );
      },
    },
    {
      title: "Created At",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>
              {moment(new Date(item?.created_at * 1000)).format(
                "MMM DD, YYYY "
              )}
            </span>
          </div>
        );
      },
    },
    {
      title: "Action",
      align: "left",
      render: (item) => {
        return (
          <>
            <span
              onClick={() => {
                const selected_ = { ...selectedBread };
                selected_.asin = item?.asin;
                setSelectedBread(selected_);
                setTabStatus("View Campaign");
              }}
              className="btn btn-sm btn-primary"
            >
              View Campaign
            </span>
            {/* <Link
              to={{
                pathname: "/brand-management/view-campaign",
                state: {
                  advertised_asin: item?.advertised_asin,
                },
              }}
              className="btn btn-sm btn-primary"
            >
              View Campaign
            </Link> */}
          </>
        );
      },
    },
  ];

  const onPageNo = (e) => {
    setLoading(true);
    const apiObj = {
      page: e,
      perPage: pageSize,
      search_value: searchAsin,
      marketplace_id: selectedMarketplace,
    };
    GetASINList(apiObj);
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    const apiObj = {
      page: 1,
      perPage: e,
      search_value: searchAsin,
      marketplace_id: selectedMarketplace,
    };
    GetASINList(apiObj);
  };

  return (
    <Wrapper className="fadeInRight">
      <>
        {/*begin::Row*/}
        <div className="row gy-5 g-xl-5">
          {/*begin::Col*/}

          <div className="col-xxl-12">
            <div
              className="d-flex flex-wrap gap-3 mb-3"
              style={{
                background: "#21d9591f",
                padding: "10px 0px",
                borderRadius: "7px",
              }}
            >
              <div className="position-relative mx-5 d-flex">
                <span className="me-1 d-flex align-items-center">
                  <Link
                    to={{
                      pathname: "/dashboard",
                    }}
                  >
                    <i className="ki-outline ki-home fs-6 d-flex" />
                  </Link>
                </span>
                <span className="me-1 d-flex align-items-center">
                  <i className="ki-outline ki-right fs-7" />
                </span>
                <span className="fw-bold">ASIN Management </span>
              </div>
            </div>
            {/*begin::Tables Widget 9*/}
            <div className="card card-xxl-stretch mb-5 mb-xl-8">
              {/*begin::Header*/}
              <div className="card-header border-bottom-dashed">
                {/*end::Title*/}
                {/*begin::Title*/}
                <h3 className="card-title ">
                  <span className="card-label fw-bold text-gray-900">
                    ASIN List
                  </span>
                </h3>
                {/*end::Title*/}
                <div className="card-toolbar">
                  <div className="position-relative mr-4">
                    <Input
                      type="text"
                      size="large"
                      placeholder="Search for ASIN"
                      className="ant_common_input fs-6 fw-bold"
                      prefix={
                        <SearchOutlined
                          style={{ color: "#a1a5b7", fontSize: "18px" }}
                        />
                      }
                      onChange={(e) => {
                        setSearchAsin(e.target.value);
                      }}
                      onPressEnter={() => {
                        setLoading(true);
                        const apiObj = {
                          page: 1,
                          perPage: DefaultPerPage,
                          search_value: searchAsin,
                          marketplace_id: selectedMarketplace,
                        };
                        GetASINList(apiObj);
                      }}
                    />
                  </div>
                  <div className="position-relative ms-2">
                    <Select
                      className="w-200px"
                      size="large"
                      placeholder="Select Marketplace"
                      loading={marketplaceLoading}
                      value={selectedMarketplace || null}
                      onChange={(e, _) => {
                        setSelectedMarketplace(e);
                        const selected_ = { ...selectedBread };
                        selected_.marketplace_name = _?.marketplace_name;
                        setSelectedBread(selected_);
                        setLoading(true);
                        setPage(1);
                        setPageSize(DefaultPerPage);
                        const apiObj = {
                          page: 1,
                          perPage: DefaultPerPage,
                          search_value: searchAsin,
                          marketplace_id: e,
                        };
                        GetASINList(apiObj);
                      }}
                      options={MarketplaceFlag(marketplaceList)}
                    />
                  </div>
                </div>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              <div className="card-body pb-5 pt-0">
                {/*begin::Table container*/}
                <div className="table-responsive mt-5">
                  {loading ? (
                    <Loading />
                  ) : list?.length !== 0 ? (
                    <ZoneFlipTable
                      columns={columns}
                      fixed="top"
                      dataSource={list}
                      rowKey="key"
                      loading={loading}
                      pagination={false}
                      scroll={{ x: "max-content" }}
                    />
                  ) : (
                    <NoData />
                  )}
                </div>
                <Pagination
                  loading={loading || list?.length === 0}
                  pageSize={pageSize}
                  page={page}
                  totalPage={totalPage}
                  onPerPage={onPerPage}
                  onPageNo={onPageNo}
                />

                {/*end::Table container*/}
                {/*end::Table container*/}
                {/* <div className="d-flex flex-stack flex-wrap pt-0 mt-5">
                  <div className="fs-7 fw-bold text-gray-700 d-flex align-items-center">
                    <select
                      name
                      aria-controls
                      className="form-select form-select-sm form-select-solid w-75px me-3"
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                    Showing 1 to 3 of 50 entries
                  </div>
                
                  <ul className="pagination">
                    <li className="page-item previous">
                      <a href="#" className="page-link">
                        <i className="previous" />
                      </a>
                    </li>
                    <li className="page-item active">
                      <a href="#" className="page-link">
                        1
                      </a>
                    </li>
                    <li className="page-item">
                      <a href="#" className="page-link">
                        2
                      </a>
                    </li>
                    <li className="page-item">
                      <a href="#" className="page-link">
                        3
                      </a>
                    </li>
                    <li className="page-item">
                      <a href="#" className="page-link">
                        4
                      </a>
                    </li>
                    <li className="page-item">
                      <a href="#" className="page-link">
                        5
                      </a>
                    </li>
                    <li className="page-item">
                      <a href="#" className="page-link">
                        6
                      </a>
                    </li>
                    <li className="page-item next">
                      <a href="#" className="page-link">
                        <i className="next" />
                      </a>
                    </li>
                  </ul>
                 
                </div> */}
              </div>
              {/*begin::Body*/}
            </div>
            {/*end::Tables Widget 9*/}
          </div>
          {/*end::Col*/}
        </div>
        {/*end::Row*/}
      </>
    </Wrapper>
  );
}
