import React, { useEffect, useState } from "react";
// import { Wrapper } from "./style";
import Chart from "react-apexcharts";
import { DatePicker, Input, Modal, Spin, Tag, Tooltip, message } from "antd";
import dayjs from "dayjs";
import ChartModal from "./chart-modal";
import moment from "moment/moment";
import Loading from "../../../../component/loading";
import ZoneFlipTable from "../../../../component/zonFlipTable";
import NoData from "../../../../component/no-data";
import { useLocation, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import RangePickerZonFlip from "../../../../component/range-picker";
import { DateFormat } from "../../../../config";
export default function (props) {
  const {
    setTabStatus,
    selectedBread,
    GetKeywordLogList,
    GetNotesLogList,
    StoreKeyword,
    UpdateKeyword,
    DeleteKeyword,
    GetGraphKeyword,
    GetRankGraph,
    CombinedKeywordGraph,
    fakeActionBrandManagement,
  } = props;

  const GetKeywordLogListRes = useSelector(
    (state) => state.AsinManagement.GetKeywordLogListResponse || {}
  );

  const GetStoreKeywordRes = useSelector(
    (state) => state.AsinManagement.GetStoreKeywordResponse || {}
  );

  const GetUpdateKeywordRes = useSelector(
    (state) => state.AsinManagement.GetUpdateKeywordResponse || {}
  );

  const GetDeleteKeywordRes = useSelector(
    (state) => state.AsinManagement.GetDeleteKeywordResponse || {}
  );

  const GetGraphKeywordRes = useSelector(
    (state) => state.AsinManagement.GetGraphKeywordResponse || {}
  );

  const GetRankGraphRes = useSelector(
    (state) => state.AsinManagement.GetRankGraphResponse || {}
  );

  const GetNotesLogListRes = useSelector(
    (state) => state.AsinManagement.GetNotesLogListResponse || {}
  );

  const CombinedKeywordGraphRes = useSelector(
    (state) => state.AsinManagement.CombinedKeywordGraphResponse || {}
  );

  const [filters, setFilters] = useState({
    start_date: dayjs().add(-30, "d"),
    end_date: dayjs(),
  });

  const location = useLocation();
  const chartRef = React.createRef();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dateValue, setDateValue] = useState(dayjs());
  const [inputKeyword, setInputKeyword] = useState("");
  const [clickIndex, setClickIndex] = useState();
  const [keywordLabelEvent, setKeywordLabelEvent] = useState({});
  const [loading, setLoading] = useState(true);
  const [kdKeyword, setKDKeyword] = useState(false);
  const [kdKeywordId, setKDKeywordId] = useState(false);
  const [kdCampaignId, setKDCampaignId] = useState(false);
  const [kdCampaignName, setKDCampaignName] = useState(false);

  const [list, setList] = useState([]);
  const [listLoading, setListLoading] = useState(true);
  const [notesList, setNotesList] = useState([]);
  const [notesListLoading, setNotesListLoading] = useState(true);
  const [annotations, setAnnotations] = useState([]);
  const [annotationStatus, setAnnotationStatus] = useState("ADD");
  const [keywordUniqueId, setKeywordUniqueId] = useState("");
  const [keywordRowId, setKeywordRowId] = useState("");
  const [apiStatus, setApiStatus] = useState(false);
  const [chartSeries, setChartSeries] = useState([]);
  const [chartDate, setChartDate] = useState([]);
  const [twoMonthDate, setTwoMonthDate] = useState([]);

  console.log(selectedBread, "annotations");
  console.log(kdKeywordId, "kdKeywordId");

  const [annotationListNew, setAnnotationsListNew] = useState([]);
  const [bidChangeListNew, setBidChangeListNew] = useState([]);

  useEffect(() => {
    // console.log("location", location);
    setKDKeyword(selectedBread?.kd_keyword_name);
    setKDCampaignId(selectedBread?.kd_campaign_id);
    setKDKeywordId(selectedBread?.kd_keyword_id);
    setKDCampaignName(selectedBread?.kd_campaign_name);
  }, []);

  useEffect(() => {
    if (kdKeywordId) {
      GetNotesLogList(kdKeywordId);
      // GetGraphKeyword(kdKeywordId);
      // GetRankGraph(kdKeywordId);
      const newObj = {
        start_date: filters?.start_date?.format("YYYY-MM-DD"),
        end_date: filters?.end_date?.format("YYYY-MM-DD"),
        keywordId: kdKeywordId,
      };
      CombinedKeywordGraph(newObj);
      GetKeywordLogList(newObj);
      // CombinedKeywordGraph(kdKeywordId);
    }
  }, [kdKeywordId]);

  useEffect(() => {
    if (annotationListNew?.length != 0 || bidChangeListNew?.length != 0) {
      console.log(
        annotationListNew,
        bidChangeListNew,
        "CombinedKeywordGraphRes, GetKeywordLogListRes"
      );
      const annotationListNew_GeneratedObjects =
        annotationListNew?.map((item) => {
          return {
            x: new Date(item?.date).getTime(),
            borderColor: "#073d73",
            label: {
              text: item?.keyword_graph_name,
              uniqueId: item?.unique_id,
              keywordRowId: item?.id,
              style: {
                color: "#fff",
                background: "#073d73bf",
              },
            },
          };
        }) || [];
      console.log(
        annotationListNew_GeneratedObjects,
        "annotationListNew_GeneratedObjects"
      );

      const bidChangeListNew_GeneratedObjects =
        bidChangeListNew?.map((item) => {
          return {
            x: new Date(item?.updated_at).getTime(),
            borderColor: "#597817",
            strokeDashArray: 0,
            borderWidth: 2,
            label: {
              text: `Bid From ${item?.from} to ${item?.to}`,
              uniqueId: "BidLine",
              keywordRowId: "BidLine",
              style: {
                color: "#fff",
                background: "#597817",
              },
            },
          };
        }) || [];
      console.log(
        bidChangeListNew_GeneratedObjects,
        "annotationListNew_GeneratedObjects"
      );
      const newArray = [
        ...annotationListNew_GeneratedObjects,
        ...bidChangeListNew_GeneratedObjects,
      ];
      console.log(newArray, "newArray");
      setAnnotations(newArray);
    }
  }, [annotationListNew, bidChangeListNew]);
  useEffect(() => {
    if (CombinedKeywordGraphRes.status === true) {
      console.log(CombinedKeywordGraphRes, "CombinedKeywordGraphRes");
      const generatedObjects =
        CombinedKeywordGraphRes?.data?.records?.map((item) => {
          console.log(item, "item");
          return {
            x: new Date(item?.date).getTime(),
            borderColor: "#073d73",
            label: {
              text: item?.keyword_graph_name,
              uniqueId: item?.unique_id,
              keywordRowId: item?.id,
              style: {
                color: "#fff",
                background: "#073d73bf",
              },
            },
          };
        }) || [];
      console.log(generatedObjects, "generatedObjects");
      setChartDate(CombinedKeywordGraphRes?.data?.chartDate);
      setChartSeries(CombinedKeywordGraphRes?.data?.chartData);
      setAnnotationsListNew(CombinedKeywordGraphRes?.data?.records);
      // setAnnotations(generatedObjects);
      setLoading(false);
      fakeActionBrandManagement("CombinedKeywordGraphResponse");
    } else if (CombinedKeywordGraphRes.status === false) {
      setLoading(false);
      fakeActionBrandManagement("CombinedKeywordGraphResponse");
    }
  }, [CombinedKeywordGraphRes]);

  useEffect(() => {
    if (GetKeywordLogListRes.status === true) {
      console.log(GetKeywordLogListRes, "GetKeywordLogListRes");
      // console.log(
      //   GetKeywordLogListRes?.data?.records?.length,
      //   "GetKeywordLogListRes?.data?.records?.length "
      // );
      GetKeywordLogListRes?.data?.records?.length == 0
        ? setList([])
        : setList(GetKeywordLogListRes?.data?.records[0]?.bid_changes);
      setBidChangeListNew(
        GetKeywordLogListRes?.data?.records?.[0]?.bid_changes
      );
      setListLoading(false);
      fakeActionBrandManagement("GetKeywordLogListResponse");
    } else if (GetKeywordLogListRes.status === false) {
      setListLoading(false);
      fakeActionBrandManagement("GetKeywordLogListResponse");
    }
  }, [GetKeywordLogListRes]);

  useEffect(() => {
    if (GetNotesLogListRes.status === true) {
      console.log(GetNotesLogListRes, "GetNotesLogListRes");
      // console.log(
      //   GetKeywordLogListRes?.data?.records?.length,
      //   "GetKeywordLogListRes?.data?.records?.length "
      // );
      setNotesList(GetNotesLogListRes?.data?.records);
      setNotesListLoading(false);
      fakeActionBrandManagement("GetNotesLogListResponse");
    } else if (GetNotesLogListRes.status === false) {
      setNotesListLoading(false);
      fakeActionBrandManagement("GetNotesLogListResponse");
    }
  }, [GetNotesLogListRes]);

  useEffect(() => {
    if (GetStoreKeywordRes.status === true) {
      console.log(GetStoreKeywordRes, "GetStoreKeywordRes--------------");
      message.destroy();
      message.success(GetStoreKeywordRes?.message);
      // GetGraphKeyword(kdKeywordId);
      const newObj = {
        start_date: filters?.start_date?.format("YYYY-MM-DD"),
        end_date: filters?.end_date?.format("YYYY-MM-DD"),
        keywordId: kdKeywordId,
      };
      CombinedKeywordGraph(newObj);
      GetKeywordLogList(kdKeywordId);
      GetNotesLogList(kdKeywordId);
      resetValue();
      setApiStatus(false);
      setLoading(false);
      fakeActionBrandManagement("GetStoreKeywordResponse");
    } else if (GetStoreKeywordRes.status === false) {
      message.destroy();
      message.error(GetStoreKeywordRes?.message);
      fakeActionBrandManagement("GetStoreKeywordResponse");
    }
  }, [GetStoreKeywordRes]);

  useEffect(() => {
    if (GetUpdateKeywordRes.status === true) {
      console.log(GetUpdateKeywordRes, "GetUpdateKeywordRes--------------");
      message.destroy();
      message.success(GetUpdateKeywordRes?.message);
      // GetGraphKeyword(kdKeywordId);
      const newObj = {
        start_date: filters?.start_date?.format("YYYY-MM-DD"),
        end_date: filters?.end_date?.format("YYYY-MM-DD"),
        keywordId: kdKeywordId,
      };
      CombinedKeywordGraph(newObj);
      GetKeywordLogList(kdKeywordId);
      GetNotesLogList(kdKeywordId);
      resetValue();
      setApiStatus(false);
      setLoading(false);
      fakeActionBrandManagement("GetUpdateKeywordResponse");
    } else if (GetUpdateKeywordRes.status === false) {
      message.destroy();
      message.error(GetUpdateKeywordRes?.message);
      fakeActionBrandManagement("GetUpdateKeywordResponse");
    }
  }, [GetUpdateKeywordRes]);

  useEffect(() => {
    if (GetDeleteKeywordRes.status === true) {
      console.log(GetDeleteKeywordRes, "GetDeleteKeywordRes--------------");
      message.destroy();
      message.success(GetDeleteKeywordRes?.message);
      // GetGraphKeyword(kdKeywordId);
      const newObj = {
        start_date: filters?.start_date?.format("YYYY-MM-DD"),
        end_date: filters?.end_date?.format("YYYY-MM-DD"),
        keywordId: kdKeywordId,
      };
      CombinedKeywordGraph(newObj);
      GetKeywordLogList(kdKeywordId);
      setIsModalOpen(false);
      resetValue();
      setLoading(false);
      fakeActionBrandManagement("GetDeleteKeywordResponse");
    } else if (GetDeleteKeywordRes.status === false) {
      message.destroy();
      message.error(GetDeleteKeywordRes?.message);
      fakeActionBrandManagement("GetDeleteKeywordResponse");
    }
  }, [GetDeleteKeywordRes]);

  // useEffect(() => {
  //   if (GetGraphKeywordRes.status === true) {
  //     console.log(GetGraphKeywordRes, "GetGraphKeywordRes--------------");
  //     const generatedObjects =
  //       GetGraphKeywordRes?.data?.records?.map((item) => {
  //         return {
  //           x: new Date(item?.date).getTime(),
  //           borderColor: "#073d73",
  //           label: {
  //             text: item?.keyword_graph_name,
  //             uniqueId: item?.unique_id,
  //             keywordRowId: item?.id,
  //             style: {
  //               color: "#fff",
  //               background: "#073d73bf",
  //             },
  //           },
  //         };
  //       }) || [];
  //     console.log(generatedObjects, "generatedObjects");
  //     setAnnotations(generatedObjects);
  //     setLoading(false);
  //     fakeActionBrandManagement("GetGraphKeywordResponse");
  //   } else if (GetGraphKeywordRes.status === false) {
  //     fakeActionBrandManagement("GetGraphKeywordResponse");
  //   }
  // }, [GetGraphKeywordRes]);

  // useEffect(() => {
  //   if (GetRankGraphRes.status === true) {
  //     console.log(GetRankGraphRes, "GetRankGraphRes--------------");
  //     console.log(GetRankGraphRes?.data?.chartData, "chartData");
  //     // setAnnotations(generatedObjects);
  //     setLoading(false);
  //     setChartSeries(GetRankGraphRes?.data?.chartData);
  //     setChartDate(GetRankGraphRes?.data?.chartDate);
  //     fakeActionBrandManagement("GetRankGraphResponse");
  //   } else if (GetRankGraphRes.status === false) {
  //     setLoading(false);
  //     fakeActionBrandManagement("GetRankGraphResponse");
  //   }
  // }, [GetRankGraphRes]);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.chart.updateOptions({
        annotations: {
          xaxis: annotations?.map((annotation, index) => ({
            ...annotation,
            label: {
              ...annotation?.label,
              click: (event, chartContext) => {
                console.log("annotationNew", annotation);
                if (annotation?.label?.uniqueId !== "BidLine") {
                  console.log(event, "Event----------");
                  console.log(chartContext, "charContext----------");
                  console.log(index, "index-----------");
                  setIsModalOpen(true);
                  setClickIndex(index);
                  setKeywordLabelEvent(event);
                } else {
                  message.destroy();
                  message.info("You can not update Bid...");
                }
              },
              // handleAnnotationClick(event, chartContext, index),
            },
          })),
        },
      });
    }
  }, [annotations]);

  // Get the current date

  // const addNewAnnotation = () => {
  //   console.log(dateValue, "dateValue");
  //   const updatedAnnotations = [...annotations];

  //   if (clickIndex === 0 || clickIndex) {
  //     updatedAnnotations[clickIndex].label.text = inputKeyword;
  //     updatedAnnotations[clickIndex].x = new Date(dateValue).getTime();
  //     setAnnotations(updatedAnnotations);
  //     message.destroy();
  //     message.success("Keyword Updated");
  //   } else {
  //     const newObj = {
  //       x: new Date(dateValue).getTime(),
  //       borderColor: "#073d73bf",
  //       label: {
  //         text: inputKeyword,
  //         style: {
  //           color: "#fff",
  //           background: "#073d73bf",
  //         },
  //       },
  //     };
  //     setAnnotations((prevAnnotations) => [...prevAnnotations, newObj]);
  //     message.success("Keyword Added");
  //   }
  //   resetValue();
  // };

  const chartOptions = {
    chart: {
      id: "line-chart",
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: "smooth",
      show: !0,
      width: 3,
      //   colors: [o, s, d],
    },
    xaxis: {
      type: "datetime",
      // min: new Date("2022-01-10").getTime(),
      // max: new Date("2022-01-30").getTime(),

      categories:
        chartDate?.map((d) => {
          return moment(new Date(d)).format("DD MMM YYYY");
        }) || [],
    },
    yaxis: {
      labels: {
        formatter: (value) => value,
      },
      tickAmount: 5,
    },
    tooltip: {
      marker: {
        show: true,
      },
      x: {
        show: false,
      },
      y: {
        formatter: function (
          value,
          { series, seriesIndex, dataPointIndex, w }
        ) {
          const withTitle_ = w?.config?.series?.[seriesIndex];
          console.log(withTitle_, "withTitle_");
          return value == 106 ? ">106" : value;
        },
      },
    },
    annotations: {
      xaxis: annotations?.map((annotation, index) => ({
        ...annotation,
        label: {
          ...annotation.label,
          click: (event, chartContext) => {
            console.log("annotationNew", annotation);
            if (annotation?.label?.uniqueId !== "BidLine") {
              console.log("annotationNew", annotation);
              console.log(event, "Event----------");
              console.log(chartContext, "charContext----------");
              console.log(index, "index-----------");
              setIsModalOpen(true);
              setClickIndex(index);
              setKeywordLabelEvent(event);
            } else {
              message.destroy();
              message.info("You can not update Bid...");
            }
          },
          // handleAnnotationClick(event, chartContext, index),
        },
      })),
    },
  };

  const onChangeDate = (date, dateString) => {
    setDateValue(dateString);
    console.log(date, dateString);
  };
  const editAnnotation = () => {
    console.log("Edit");

    setAnnotationStatus("UPDATE");
    const myDate = moment(keywordLabelEvent?.x).format("YYYY-MM-DD");
    // console.log(myDate, "keywordLabelEvent?.label?.text");
    setInputKeyword(keywordLabelEvent?.label?.text);
    setDateValue(myDate);
    setKeywordUniqueId(keywordLabelEvent?.label?.uniqueId);
    setKeywordRowId(keywordLabelEvent?.label?.keywordRowId);
    setIsModalOpen(false);
  };
  const deleteAnnotation = () => {
    console.log("Delete");
    const obj = {
      is_deleted: 1,
      action: "DELETE",
    };
    setLoading(true);
    message.destroy();
    message.loading("Loading...", 0);
    DeleteKeyword(obj, keywordLabelEvent?.label?.keywordRowId);
  };
  const resetValue = () => {
    setInputKeyword("");
    setAnnotationStatus("ADD");
    setClickIndex("");
    setKeywordLabelEvent({});
  };
  console.log(list, "-------------------new List");

  const columns = [
    {
      title: "Change History",
      // width: 220,
      align: "left",
      render: (item) => {
        return (
          <div>
            <span style={{ fontWeight: "600" }}>
              Bid From {item?.from} To {item?.to}
            </span>
          </div>
        );
      },
    },
    {
      title: "Date & Time",
      // width: 220,
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>
              {moment(new Date(item?.updated_at)).format("MMM DD, YYYY ")}
            </span>
          </div>
        );
      },
    },
  ];

  const columns_old = [
    {
      title: "#",
      width: 100,
      ellipsis: true,
      align: "left",
      render: (item) => {
        return <span>{item?.id}</span>;
      },
    },

    {
      title: "keyword Name",
      // width: 220,
      align: "left",
      render: (item) => {
        return (
          <div>
            <span style={{ fontWeight: "600" }}>
              {item?.keyword_graph_name}
            </span>
          </div>
        );
      },
    },
    {
      title: "Keyword Action",
      // width: 220,
      align: "left",
      render: (item) => {
        return (
          <div>
            <Tag
              color={
                item?.action === "ADD"
                  ? "green"
                  : item?.action === "UPDATE"
                  ? "blue"
                  : "red"
              }
            >
              {item?.action}
            </Tag>
          </div>
        );
      },
    },
    {
      title: "Updated at",
      // width: 220,
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>
              {moment(new Date(item?.updated_at * 1000)).format(
                "MMM DD, YYYY "
              )}
            </span>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <div className="row gy-5 g-xl-5 fadeInRight">
        {/*begin::Col*/}

        <div className="col-xxl-12">
          <div
            className="d-flex justify-content-between align-items-center gap-3 mb-3"
            style={{
              background: "#21d9591f",
              padding: "10px 0px",
              borderRadius: "7px",
            }}
          >
            <div className="position-relative mx-5 d-flex align-items-center">
              <button
                onClick={() => {
                  setTabStatus("View Keyword");
                }}
                class="btn btn-primary btn-icon me-3"
                style={{ height: "30px", width: "30px" }}
              >
                <i class="bi bi-arrow-left-short fs-1"></i>
              </button>
              <span className="me-1 d-flex align-items-center">
                <Link
                  to={{
                    pathname: "/dashboard",
                  }}
                >
                  <i className="ki-outline ki-home fs-6 d-flex" />
                </Link>
              </span>
              <span className="me-1 d-flex align-items-center">
                <i className="ki-outline ki-right fs-7" />
              </span>
              <span
                onClick={() => {
                  setTabStatus("Asin Management");
                }}
                style={{ cursor: "pointer" }}
              >
                ASIN Management
              </span>
              <span className="me-1 d-flex align-items-center">
                <i className="ki-outline ki-right fs-7" />
              </span>
              <span
                onClick={() => {
                  setTabStatus("View Campaign");
                }}
                style={{ cursor: "pointer" }}
              >
                View Campaign
              </span>
              <span className="me-1 d-flex align-items-center">
                <i className="ki-outline ki-right fs-7" />
              </span>
              <span
                onClick={() => {
                  setTabStatus("View Keyword");
                }}
                style={{ cursor: "pointer" }}
              >
                View Keyword
              </span>
              <span className="me-1 d-flex align-items-center">
                <i className="ki-outline ki-right fs-7" />
              </span>
              <span className="fw-bold">Keyword Details</span>
            </div>
            <div className="me-2">
              <label className="fw-bold fs-6 d-flex">
                <img
                  src={`/media/domainImage/${selectedBread?.marketplace_name}.png`}
                  style={{ height: "20px" }}
                  className="me-2"
                />
                {selectedBread?.marketplace_name}
              </label>
            </div>
          </div>
          {/*begin::Tables Widget 9*/}
          <div className="card  mb-5 mb-xl-8">
            {/*begin::Header*/}
            <div className="card-header border-bottom-dashed">
              {/*end::Title*/}
              {/*begin::Title*/}
              <h3 className="card-title ">
                <span className="card-label fw-bold text-gray-900">
                  Keyword Graph - {kdKeyword}
                </span>
              </h3>
              {/*end::Title*/}
              <div className="card-toolbar">
                <RangePickerZonFlip
                  className="ant_common_input"
                  size="large"
                  id="campaign_range_id"
                  allowClear={false}
                  style={{ width: "290px" }}
                  format={DateFormat}
                  value={[filters?.start_date, filters?.end_date]}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  // disabled={chartLoading}

                  onOpenChange={(e) => {
                    if (e) {
                      console.log(e, "-----------");
                      const filter_ = {
                        start_date: filters?.start_date,
                        end_date: null,
                      };
                      setFilters(filter_);
                    }
                  }}
                  onChange={(e) => {
                    const filters_ = filters;
                    filters_.start_date = e?.[0];
                    filters_.end_date = e?.[1];
                    setFilters({ ...filters_ });
                    setLoading(true);
                    setChartDate([]);
                    setChartSeries([]);
                    setAnnotations([]);
                    const newObj = {
                      start_date: e?.[0]?.format("YYYY-MM-DD"),
                      end_date: e?.[1]?.format("YYYY-MM-DD"),
                      keywordId: kdKeywordId,
                    };
                    CombinedKeywordGraph(newObj);
                    GetKeywordLogList(newObj);
                  }}
                />
              </div>
            </div>

            <div className="card-body py-2 pt-0">
              <div className="mt-4 d-flex justify-content-end align-items-center">
                <div
                  className="position-relative mr-4"
                  style={{ marginRight: "5px" }}
                >
                  <Input
                    size="large"
                    placeholder="Add Note"
                    className="ant_common_input fs-6"
                    onChange={(e) => {
                      setInputKeyword(e.target.value);
                    }}
                    value={inputKeyword}
                  />
                </div>
                <div
                  className="position-relative mr-4"
                  style={{ marginRight: "5px" }}
                >
                  <DatePicker
                    className="ant_common_input"
                    size="large"
                    value={dayjs(dateValue, "YYYY-MM-DD")}
                    format={"YYYY-MM-DD"}
                    onChange={onChangeDate}
                    allowClear={false}
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="position-relative">
                  <button
                    disabled={apiStatus}
                    style={{ padding: "9px 12px" }}
                    onClick={() => {
                      if (!inputKeyword) {
                        message.destroy();
                        return message.info("Please enter keyword");
                      }
                      if (annotationStatus === "ADD") {
                        setApiStatus(true);
                        const obj = {
                          keyword_graph_name: inputKeyword,
                          action: annotationStatus,
                          date: moment(dateValue).format("YYYY-MM-DD"),
                          keyword_id: kdKeywordId,
                          keyword_name: kdKeyword,
                          campaign_id: kdCampaignId,
                          campaign_name: kdCampaignName,
                        };
                        setLoading(true);
                        message.destroy();
                        message.loading("Loading...", 0);
                        StoreKeyword(obj);
                      } else {
                        setApiStatus(true);
                        const obj = {
                          keyword_graph_name: inputKeyword,
                          action: annotationStatus,
                          date: moment(dateValue).format("YYYY-MM-DD"),
                          keyword_id: kdKeywordId,
                          keyword_name: kdKeyword,
                          campaign_id: kdCampaignId,
                          campaign_name: kdCampaignName,
                          unique_id: keywordUniqueId,
                        };
                        setLoading(true);
                        message.destroy();
                        message.loading("Loading...", 0);
                        UpdateKeyword(obj, keywordRowId);
                      }

                      // addNewAnnotation();
                    }}
                    className="btn btn-primary"
                  >
                    {clickIndex === 0 || clickIndex ? "Edit Note" : "Add Note"}
                  </button>
                </div>
                <div className="position-relative">
                  <Tooltip title="Reset">
                    <button
                      className="btn btn-primary btn-icon ms-2"
                      style={{ height: 30, width: 30 }}
                      onClick={() => {
                        resetValue();
                      }}
                    >
                      <i className="bi bi-arrow-clockwise fs-4" />
                    </button>
                  </Tooltip>
                </div>
              </div>
              {(loading || chartSeries?.length != 0) && (
                <Spin spinning={loading}>
                  <Chart
                    options={chartOptions}
                    series={chartSeries}
                    type="line"
                    width="100%"
                    height="400"
                    ref={chartRef}
                  />
                </Spin>
              )}
              {!loading && chartSeries?.length == 0 && (
                <NoData height="300px" />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row gy-5 g-xl-5 fadeInRight">
        {/*begin::Col*/}
        <div className="col-xxl-12">
          <div className="card  mb-5 mb-xl-8">
            {/*begin::Header*/}
            <div className="card-header border-bottom-dashed">
              {/*end::Title*/}
              {/*begin::Title*/}
              <h3 className="card-title ">
                <span className="card-label fw-bold text-gray-900">
                  Keyword Log
                </span>
              </h3>
              {/*end::Title*/}
              <div className="card-toolbar">
                <div
                  className="position-relative mr-4"
                  style={{ marginRight: "5px" }}
                ></div>
              </div>
            </div>
            <div className="card-body pb-5 pt-0">
              <div className="table-responsive mt-5">
                {!listLoading && list?.length == 0 ? (
                  <NoData height="300px" />
                ) : (
                  <ZoneFlipTable
                    columns={columns}
                    fixed="top"
                    dataSource={list}
                    rowKey="key"
                    loading={listLoading}
                    pagination={false}
                  />
                )}

                {/* {loading ? (
                  <Loading />
                ) : list?.length !== 0 ? ( */}
                {/* <ZoneFlipTable
                  columns={columns}
                  fixed="top"
                  dataSource={list}
                  rowKey="key"
                  loading={loading}
                  pagination={false}
                /> */}
                {/* ) : (
                  <NoData height="300px" />
                )} */}
              </div>

              {/* <Pagination
                loading={loading || list?.length === 0}
                pageSize={pageSize}
                page={page}
                totalPage={totalPage}
                onPerPage={onPerPage}
                onPageNo={onPageNo}
              /> */}
            </div>
          </div>
          <div className="card  mb-5 mb-xl-8">
            {/*begin::Header*/}
            <div className="card-header border-bottom-dashed">
              {/*end::Title*/}
              {/*begin::Title*/}
              <h3 className="card-title ">
                <span className="card-label fw-bold text-gray-900">
                  Notes Log
                </span>
              </h3>
              {/*end::Title*/}
              <div className="card-toolbar">
                <div
                  className="position-relative mr-4"
                  style={{ marginRight: "5px" }}
                ></div>
              </div>
            </div>
            <div className="card-body pb-5 pt-0">
              <div className="table-responsive mt-5">
                {!notesListLoading && notesList?.length == 0 ? (
                  <NoData height="300px" />
                ) : (
                  <ZoneFlipTable
                    columns={columns_old}
                    fixed="top"
                    dataSource={notesList}
                    rowKey="key"
                    loading={notesListLoading}
                    pagination={false}
                  />
                )}

                {/* ) : (
                
                )} */}
              </div>
              {/* <Pagination
                loading={loading || list?.length === 0}
                pageSize={pageSize}
                page={page}
                totalPage={totalPage}
                onPerPage={onPerPage}
                onPageNo={onPageNo}
              /> */}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="mx-10 pt-15">
        <h3 className="mb-10">Keyword Chart</h3>

        <div className="row d-flex justify-start-end mb-10">
          <div className="col-3">
            <Input
              placeholder="Enter Keyword"
              size="large"
              onChange={(e) => {
                setKeyword(e.target.value);
              }}
              value={keyword}
            />
          </div>
          <div className="col-3">
            <DatePicker
              size="large"
              value={dayjs(dateValue, "YYYY-MM-DD")}
              format={"YYYY-MM-DD"}
              onChange={onChangeDate}
              allowClear={false}
              style={{ width: "100%" }}
            />
          </div>
          <div className="col-3">
            <button
              onClick={() => {
                if (!keyword) {
                  message.destroy();
                  return message.info("Please enter keyword");
                }
                addNewAnnotation();
              }}
              className="btn btn-primary"
            >
              {clickIndex === 0 || clickIndex ? "Edit" : "Add"}
            </button>
          </div>
        </div>

        <Chart
          options={chartOptions}
          series={chartOptions.series}
          type="line"
          width="100%"
          height="400"
          ref={chartRef}
        />
      </div> */}

      {isModalOpen && (
        <ChartModal
          isModalOpen={isModalOpen}
          handleEdit={editAnnotation}
          handleDelete={deleteAnnotation}
          handleCancel={() => {
            setIsModalOpen(false);
          }}
          keyword={keywordLabelEvent?.label?.text}
        />
      )}
    </>
  );
}
