import Auth from "../../modules/auth/auth.reducer";
import Dashboard from "../../modules/dashboard/dashboard.reducer";
import AsinManagement from "../../modules/campaign-management/campaign-management.reducer";
import SystemErrorLog from "../../modules/application-log/system-error-log/system-error-log.reducer";
import CentralLog from "../../modules/application-log/central-log/central-log.reducer";
import DataFrequencySetting from "../../modules/application-log/data-frequency-setting/data-frequency-setting.reducer";
import UserCredentials from "../../modules/setting/index.reducer";
import ManageUser from "../../modules/manage-user/manage-user.reducer";
import AdvertisedProductReport from "../../modules/sp-advertisement-report/advertised-product-report/advertised-product-report.reducer";
import KeywordReport from "../../modules/sp-advertisement-report/keywords-report/keywords-report.reducer";
import SearchTermReport from "../../modules/sp-advertisement-report/search-term-report/search-term-report.reducer";
import CampaignManagement from "../../modules/keyword-tracker/keyword-tracker.reducer";
const reducers = {
  Auth,
  Dashboard,
  AsinManagement,
  SystemErrorLog,
  CentralLog,
  DataFrequencySetting,
  UserCredentials,
  ManageUser,
  AdvertisedProductReport,
  KeywordReport,
  SearchTermReport,
  CampaignManagement,
};

export default reducers;
