import axiosCall from "../../../configurations/network-services/axiosCall";

// const prefix = "dashboard";

export const GetDataFrequencySettingList = (data) => {
  const path = `data-scheduler?page=${data?.page || 1}&pageSize=${
    data?.perPage || 10
  }`;
  const responseType = "GET_DATA_FREQUENCY_SETTING_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionDataFrequencySetting = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_DATA_FREQUENCY_SETTING", state: data });
};
