import axiosCall from "../../../configurations/network-services/axiosCall";

// const prefix = "dashboard";

export const GetAdvertisedProductReportList = (data) => {
  const path = `advertising/sp/advertised-product-report?page=${
    data?.page || 1
  }&pageSize=${data?.perPage || 10}`;
  const responseType = "GET_ADVERTISED_PRODUCT_REPORT_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionAdvertisedProductReport = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_ADVERTISED_PRODUCT", state: data });
};
