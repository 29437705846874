import axiosCall from "../../../configurations/network-services/axiosCall";

// const prefix = "dashboard";

export const GetKeywordReportList = (data) => {
  const path = `advertising/sp/sp-keywords-report?page=${
    data?.page || 1
  }&pageSize=${data?.perPage || 10}`;
  const responseType = "GET_KEYWORD_REPORT_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionKeywordReportList = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_KEYWORD_REPORT", state: data });
};
