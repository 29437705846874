import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import Sidebar from "../modules/layouts/sidebar";
import Header from "../modules/layouts/header";
import Footer from "../modules/layouts/footer";
import PageRouter from "../pageRouter";
export default function (props) {
  const [redirectPage, setRedirectPage] = useState(false);
  const [sidebarStatus, setSidebarStatus] = useState(false);
  const [screenStatus, setScreenStatus] = useState(false);
  function updateSize() {
    setScreenStatus(window.innerWidth < 992);
    // setSidebarStatus(!(window.innerWidth < 992));
  }
  useEffect(() => {
    updateSize();
    window.addEventListener("resize", updateSize);
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  if (redirectPage || !localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  const checkLoginType = () => {
    return localStorage.getItem("userType");
  };
  const getUser = () => {
    return JSON.parse(localStorage.getItem("user"));
  };
  console.log(sidebarStatus, screenStatus, "-------");

  return (
    <>
      <div
        className="d-flex flex-column flex-root app-root"
        id="kt_app_root"
        style={{ height: "100%" }}
      >
        {/*begin::Page*/}
        <div
          className="app-page flex-column flex-column-fluid"
          id="kt_app_page"
          style={{ height: "100%", backgroundColor: "#eaeaea" }}
        >
          <Header
            setSidebarStatus={setSidebarStatus}
            sidebarStatus={sidebarStatus}
          />
          <div
            className="app-wrapper flex-column flex-row-fluid"
            id="kt_app_wrapper"
            style={{
              height: "100%",
              marginTop: "0px",
            }}
          >
            <Sidebar
              setSidebarStatus={setSidebarStatus}
              sidebarStatus={sidebarStatus}
              screenStatus={screenStatus}
              userType={checkLoginType()}
              user={getUser()}
            />
            <div
              className="app-main flex-column flex-row-fluid mt-5"
              id="kt_app_main"
              style={{ height: "calc(100vh - 95px)", overflow: "auto" }}
            >
              {/*begin::Content wrapper*/}
              <div className="d-flex flex-column flex-column-fluid">
                {sidebarStatus && screenStatus && (
                  <div
                    onClick={() => setSidebarStatus(false)}
                    style={{
                      background: "#00000033",
                      position: "absolute",
                      inset: 0,
                      zIndex: "100",
                    }}
                  />
                )}
                {/*begin::Content*/}
                <div
                  id="kt_app_content"
                  className="app-content flex-column-fluid"
                  style={{ paddingTop: "0px" }}
                >
                  {/*begin::Content container*/}
                  <div
                    id="kt_app_content_container"
                    className="app-container container-fluid"
                  >
                    <div className>
                      <PageRouter userType={checkLoginType()} {...props} />
                    </div>
                  </div>
                  {/*end::Content container*/}
                </div>
                {/*end::Content*/}
              </div>
              {/*end::Content wrapper*/}
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );

  // return (
  //   <>
  //     <div
  //       className="fade-in d-flex flex-column flex-root"
  //       style={{ height: "100%" }}
  //     >
  //       <div
  //         className="page d-flex flex-row flex-column-fluid"
  //         style={{ height: "100%" }}
  //       >
  //         <div className="fadeInLeft">
  //           <Sidebar
  //             setSidebarStatus={setSidebarStatus}
  //             sidebarStatus={sidebarStatus}
  //             screenStatus={screenStatus}
  //           />
  //         </div>
  //         <div className=" d-flex flex-column flex-row-fluid" id="kt_wrapper">
  //           <Header
  //             setSidebarStatus={setSidebarStatus}
  //             sidebarStatus={sidebarStatus}
  //           />

  //           <div className=" d-flex flex-column flex-row-fluid">
  //             {sidebarStatus && screenStatus && (
  //               <div
  //                 onClick={() => setSidebarStatus(false)}
  //                 style={{
  //                   background: "#00000026",
  //                   position: "absolute",
  //                   inset: 0,
  //                 }}
  //               />
  //             )}

  //             <div className=" flex-column flex-column-fluid">
  //               <div
  //                 style={{
  //                   height: "500px",
  //                   width: "500px",
  //                   backgroundColor: "red",
  //                 }}
  //               >
  //                 <span> Hiiii Hello</span>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );
}
