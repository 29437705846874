import React, { useEffect, useState } from "react";
import AsinManagement from "./lib/asin-management";
import ViewCampaign from "./lib/view-campaign";
import ViewKeyword from "./lib/view-keyword";
import KeywordDetails from "./lib/keyword-details";
export default function (props) {
  const [tabStatus, setTabStatus] = useState("Asin Management");
  const [selectedBread, setSelectedBread] = useState({
    asin: null,
    campaignId: null,
    campaignName: null,
    keyword: null,
    kd_keyword_id: null,
    kd_keyword_name: null,
    kd_campaign_id: null,
    kd_campaign_name: null,
    marketplace_name: null,
  });
  if (tabStatus === "Asin Management") {
    return (
      <AsinManagement
        {...props}
        setTabStatus={setTabStatus}
        selectedBread={selectedBread}
        setSelectedBread={setSelectedBread}
      />
    );
  } else if (tabStatus === "View Campaign") {
    return (
      <ViewCampaign
        {...props}
        setTabStatus={setTabStatus}
        selectedBread={selectedBread}
        setSelectedBread={setSelectedBread}
      />
    );
  } else if (tabStatus === "View Keyword") {
    return (
      <ViewKeyword
        {...props}
        setTabStatus={setTabStatus}
        selectedBread={selectedBread}
        setSelectedBread={setSelectedBread}
      />
    );
  } else if (tabStatus === "Keyword Details") {
    return (
      <KeywordDetails
        {...props}
        setTabStatus={setTabStatus}
        selectedBread={selectedBread}
        setSelectedBread={setSelectedBread}
      />
    );
  }
}
