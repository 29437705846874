import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MarketplaceCredentials from "../../modules/pages/setting/marketplace-credentials";
import {
  getMarketplaceAmazonAction,
  getUserCredentialsListAction,
  GenerateRefreshAdTokenAction,
  UserCredentialSaveAdProfileAction,
  fakeActionSettingUserCredentials,
  GenerateRefreshTokenAction,
} from "../../redux/modules/setting/index.action";

const mapStateToProps = (state) => ({
  GetMarketplaceListAmazonResponse:
    state.UserCredentials.GetMarketplaceListAmazonResponse,
  GetUserCredentialsListResponse:
    state.UserCredentials.GetUserCredentialsListResponse,
  UserCredentialGenerateAdTokenRefreshResponse:
    state.UserCredentials.UserCredentialGenerateAdTokenRefreshResponse,
  UserCredentialGenerateRefreshResponse:
    state.UserCredentials.UserCredentialGenerateRefreshResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getMarketplaceAmazonAction,
      getUserCredentialsListAction,
      fakeActionSettingUserCredentials,
      GenerateRefreshAdTokenAction,
      UserCredentialSaveAdProfileAction,
      GenerateRefreshTokenAction,
    },
    dispatch
  );

const MarketplaceCredentials_ = connect(
  mapStateToProps,
  mapDispatchToProps
)(MarketplaceCredentials);

export default MarketplaceCredentials_;
