import React, { useEffect, useState } from "react";
import { Wrapper } from "./style";
import NoData from "../../../../component/no-data";
import { DefaultPerPage, timeSince } from "../../../../config";
import Loading from "../../../../component/loading";
import ZoneFlipTable from "../../../../component/zonFlipTable";
import Pagination from "../../../../component/pagination";
import { useSelector } from "react-redux";
import moment from "moment";
import ZonFlipTooltip from "../../../../component/tooltip";
import {
  UserSwitchOutlined,
  EditTwoTone,
  DeleteTwoTone,
  PlusOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Popconfirm, Rate, Tooltip, message } from "antd";
import UserModal from "./lib/user-modal";
export default function (props) {
  const {
    GetUserList,
    SwitchUserAction,
    AddUserAction,
    deleteUserAction,
    fakeActionManageUser,
    history,
  } = props;

  const GetUserListRes = useSelector(
    (state) => state.ManageUser.GetUserListResponse || {}
  );
  const SwitchUserRes = useSelector(
    (state) => state.ManageUser.SwitchUserResponse || {}
  );
  const DeleteUserRes = useSelector(
    (state) => state.ManageUser.DeleteUserResponse || {}
  );
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);

  const [selectedUser, setSelectedUser] = useState(null);
  const [changePassword, setChangePassword] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [userModal, setUserModal] = useState(false);

  useEffect(() => {
    const apiObj = {
      page: 1,
      perPage: pageSize,
    };
    GetUserList(apiObj);
    return () => {};
  }, []);

  useEffect(() => {
    if (GetUserListRes.status === true) {
      setList(GetUserListRes?.data?.records);
      console.log(GetUserListRes, "GetUserListRes");
      setLoading(false);
      setTotalPage(GetUserListRes?.data?.pagination?.totalCount);
      fakeActionManageUser("GetUserListResponse");
    } else if (GetUserListRes.status === false) {
      setLoading(false);
      fakeActionManageUser("GetUserListResponse");
    }
  }, [GetUserListRes]);

  useEffect(() => {
    if (SwitchUserRes.status) {
      // setIsSwitch(true);
      message.destroy();
      setTimeout(() => {
        let adminToken = localStorage.getItem("token");
        let adminData = localStorage.getItem("user");
        const data = {
          token: adminToken,
          data: adminData,
        };
        localStorage.setItem("adminData", JSON.stringify(data));

        localStorage.setItem(
          "user",
          JSON.stringify(SwitchUserRes?.data?.user_data)
        );
        localStorage.setItem(
          "userType",
          SwitchUserRes?.data?.user_data?.user_type
        );
        localStorage.setItem(
          "credentials",
          JSON.stringify(SwitchUserRes?.data?.credentials)
        );
        localStorage.setItem("token", SwitchUserRes?.data?.auth_token);

        history.replace("/campaign-management");
        fakeActionManageUser("SwitchUserResponse");
      }, 500);
      // message.success(SwitchUserRes.message);
    } else if (SwitchUserRes.status === false) {
      // setIsSwitch(false);
      message.destroy();
      message.error(SwitchUserRes.message);
      fakeActionManageUser("SwitchUserResponse");
    }
  }, [SwitchUserRes]);

  useEffect(() => {
    if (DeleteUserRes.status) {
      message.destroy();
      message.success(DeleteUserRes.message);
      const apiObj = {
        page: 1,
        perPage: pageSize,
      };
      GetUserList(apiObj);
      fakeActionManageUser("DeleteUserResponse");
    }
  }, [DeleteUserRes]);

  const columns = [
    {
      title: "Sr. No.",
      ellipsis: true,
      width: 100,
      align: "left",
      render: (_, __, i) => {
        return <span>{(page - 1) * pageSize + 1 + i}</span>;
      },
    },
    {
      title: "Name",
      width: 150,
      align: "left",
      render: (text) => {
        return <span className="fw-bolder">{text?.name || "-"}</span>;
      },
    },
    {
      title: "Email",
      width: 200,
      align: "left",
      render: (text) => {
        return <span>{text?.email || "-"}</span>;
      },
    },
    {
      title: "Contact No",
      width: 150,
      align: "left",
      render: (text) => {
        return <span>{text?.contact_no || "-"}</span>;
      },
    },
    {
      title: "Seller Name",
      width: 150,
      align: "left",
      render: (text) => {
        return <span>{text?.seller_name || "-"}</span>;
      },
    },
    {
      title: "Pending Reports",
      width: 150,
      align: "left",
      render: (text) => {
        return <span>{text?.pending_reports || "0"}</span>;
      },
    },
    {
      title: "Switch User",
      width: 150,
      align: "center",
      render: (text) => {
        return (
          <span
            onClick={() => {
              message.loading("Switching...", 0);
              SwitchUserAction(text?.id);
            }}
            style={{ cursor: "pointer" }}
          >
            <ZonFlipTooltip title="Switch user">
              <UserSwitchOutlined
                style={{ fontSize: "26px", color: "#21d959" }}
              />
            </ZonFlipTooltip>
          </span>
        );
      },
    },
    {
      title: "Created At",
      width: 200,
      align: "left",
      render: (text) => {
        return (
          <div>
            <span>
              {moment(new Date(text.created_at * 1000)).format(
                "MMM DD, YYYY hh:mm A"
              )}
            </span>
            <br />
            <span className="timeStampColor">
              ({timeSince(text.created_at)})
            </span>
          </div>
        );
      },
    },

    {
      title: "Updated At",
      width: 200,
      align: "left",
      render: (text) => {
        return (
          <div>
            <span>
              {text?.updated_at !== 1
                ? moment(new Date(text.updated_at * 1000)).format(
                    "MMM DD, YYYY hh:mm A"
                  )
                : moment(new Date()).format("MMM DD, YYYY hh:mm A")}
            </span>
            <br />
            <span className="timeStampColor">
              {text?.updated_at !== 1
                ? timeSince(text?.updated_at)
                : timeSince(Date.parse(new Date()) / 1000)}
            </span>
          </div>
        );
      },
    },
    {
      title: "Action",
      width: 100,
      align: "center",
      render: (text) => {
        return (
          <div className="d-flex justify-content-center" id="userActionIcons">
            <div className="Edit-Icon-Antd d-flex">
              <Tooltip title="Edit" placement="left">
                <EditTwoTone
                  onClick={() => {
                    setSelectedRow(text);
                    setUserModal(true);
                  }}
                />
              </Tooltip>
            </div>

            <div>
              <Popconfirm
                title="Are you sure to delete this user?"
                placement="left"
                cancelText="No"
                okText="Yes"
                onConfirm={() => {
                  message.loading("Deleting...", 0);
                  console.log(text?.id);
                  deleteUserAction(text?.id);
                }}
              >
                <div className="Delete-Icon-Antd d-flex">
                  <DeleteTwoTone />
                </div>
              </Popconfirm>
            </div>
          </div>
        );
      },
    },
  ];

  const onPageNo = (e) => {
    setLoading(true);
    const apiObj = {
      page: e,
      perPage: pageSize,
    };
    GetUserList(apiObj);
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    const apiObj = {
      page: 1,
      perPage: e,
    };
    GetUserList(apiObj);
  };

  return (
    <Wrapper>
      <>
        {/*begin::Row*/}
        <div className="row gy-5 g-xl-5">
          {/*begin::Col*/}
          <div className="col-xxl-12">
            {/*begin::Tables Widget 9*/}
            <div className="card card-xxl-stretch mb-5 mb-xl-8">
              {/*begin::Header*/}
              <div className="card-header border-bottom-dashed">
                {/*end::Title*/}
                {/*begin::Title*/}
                <h3 className="card-title ">
                  <span className="card-label fw-bold text-gray-900">
                    Manage User
                  </span>
                </h3>
                {/*end::Title*/}
                <div className="card-toolbar">
                  <div className="position-relative mr-4">
                    {" "}
                    <div>
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          setSelectedRow({});
                          setUserModal(true);
                        }}
                      >
                        <PlusOutlined className="me-2" />
                        Add User
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              <div className="card-body pb-5 pt-0">
                {/*begin::Table container*/}
                <div className="table-responsive mt-5">
                  {loading ? (
                    <Loading />
                  ) : list?.length != 0 ? (
                    <>
                      <ZoneFlipTable
                        columns={columns}
                        fixed="top"
                        dataSource={list}
                        rowKey="key"
                        loading={loading}
                        pagination={false}
                      />
                    </>
                  ) : (
                    <NoData />
                  )}
                </div>
                <Pagination
                  loading={loading || list?.length === 0}
                  pageSize={pageSize}
                  page={page}
                  totalPage={totalPage}
                  onPerPage={onPerPage}
                  onPageNo={onPageNo}
                />
              </div>

              {/*begin::Body*/}
            </div>
            {/*end::Tables Widget 9*/}
          </div>
          {/*end::Col*/}
        </div>
        {/*end::Row*/}
      </>
      {userModal && (
        <UserModal
          show={userModal}
          onHide={() => setUserModal(false)}
          data={selectedRow}
          onSuccess={(text) => {
            setUserModal(false);
            message.success(text);
            setList([]);
            GetUserList({
              page: page,
              perPage: pageSize,
            });
          }}
          onError={(text) => {
            message.error(text);
          }}
          {...props}
        />
      )}
    </Wrapper>
  );
}
