import { Alert, Input, Modal, message } from "antd";
import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
export default function ({
  show,
  onHide,
  onSuccess,
  onError,
  data,
  AddUserAction,
  fakeActionManageUser,
}) {
  const [sending, setSending] = useState(false);
  const [name, setName] = useState("");
  const [sellerName, setSellerName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [contact, setContact] = useState("");
  const [address, setAddress] = useState("");
  const [userId, serUserId] = useState("");
  const [visible, setVisible] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidContact, setIsValidContact] = useState(true);
  const AddUserRes = useSelector(
    (state) => state.ManageUser.AddUserResponse || {}
  );

  useEffect(() => {
    if (data?.id) {
      serUserId(data?.id);
      setName(data?.name);
      setContact(data?.contact_no);
      setPassword("");
      setEmail(data?.email);
      setSellerName(data?.seller_name);
      setAddress(data?.address);
    } else {
      setName("");
      setContact("");
      setPassword("");
      setEmail("");
      setSellerName("");
    }
  }, [data]);

  const onSubmit = () => {
    const isValidEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
      email
    );
    setIsValidEmail(isValidEmail);
    const isValidContact = /(0|91)?[6-9][0-9]{9}/gm.test(contact);
    setIsValidContact(isValidContact);
    if (isValidEmail && isValidContact) {
      const body = {
        name: name,
        seller_name: sellerName,
        email: email,
        password: password,
        photo_base64: null,
        contact_no: contact,
        address: address,
      };
      if (data.id) {
        delete body.status;
        delete body.password;
      }
      message.loading("Please Wait...", 0);
      AddUserAction(data.id, body);
    }
  };

  useEffect(() => {
    if (AddUserRes.status) {
      message.destroy();
      console.log(AddUserRes, "----------------AddUserRes");
      onSuccess(AddUserRes?.message);
      onHide();

      fakeActionManageUser("AddUserResponse");
    } else if (AddUserRes.status === false) {
      onError(AddUserRes?.message);
      fakeActionManageUser("AddUserResponse");
    }
  }, [AddUserRes]);

  const modalOkButtonProps = {
    disabled: name && sellerName && email && contact && !sending ? false : true,
  };

  return (
    <>
      <Modal
        open={show}
        onCancel={onHide}
        centered
        title="User"
        onOk={onSubmit}
        // onCancel={handleCancel}
        okButtonProps={modalOkButtonProps}
      >
        <>
          <div className="separator separator-dashed mt-3 mb-5" />
          <div>
            <div className="col-md-12 form-group mb-5">
              <label>Name</label>
              <Input
                className="ant_common_input"
                size="large"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
            <div className="col-md-12 form-group mb-5">
              <label>Seller Name</label>
              <Input
                className="ant_common_input"
                size="large"
                value={sellerName}
                onChange={(e) => {
                  setSellerName(e.target.value);
                }}
              />
            </div>
            <div className="col-md-12 form-group mb-5">
              <label>Email</label>
              <Input
                className="ant_common_input"
                size="large"
                value={email}
                type="email"
                autocomplete="false"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              {!isValidEmail && (
                <Alert
                  className="mt-2"
                  message="Invalid Email"
                  type="error"
                  showIcon
                />
              )}
            </div>
            {!data.id && (
              <div className="col-md-12 form-group mb-5">
                <label>Password</label>
                <Input
                  className="ant_common_input"
                  size="large"
                  value={password}
                  autocomplete="new-password"
                  type="password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </div>
            )}

            <div className="col-md-12 form-group mb-5">
              <label>Phone Number</label>
              <Input
                className="ant_common_input"
                size="large"
                value={contact}
                onChange={(e) => {
                  setContact(e.target.value);
                }}
              />
              {!isValidContact && (
                <Alert
                  className="mt-2"
                  message="Invalid Phone Number"
                  type="error"
                  showIcon
                />
              )}
            </div>

            <div className="col-md-12 form-group mb-5">
              <label>Address</label>
              <Input
                className="ant_common_input"
                size="large"
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
              />
            </div>
          </div>
        </>
      </Modal>
    </>
  );
}
