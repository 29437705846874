export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_MARKETPLACE_LIST_AMAZON_SUCCESS":
    case "GET_MARKETPLACE_LIST_AMAZON_ERROR":
      return {
        ...state,
        GetMarketplaceListAmazonResponse: action.updatePayload,
      };
    case "GET_USER_CREDENTIALS_LIST_SUCCESS":
    case "GET_USER_CREDENTIALS_LIST_ERROR":
      return {
        ...state,
        GetUserCredentialsListResponse: action.updatePayload,
      };
    case "USER_CREDENTIAL_GENERATE_REFRESH_SUCCESS":
    case "USER_CREDENTIAL_GENERATE_REFRESH_ERROR":
      return {
        ...state,
        UserCredentialGenerateRefreshResponse: action.updatePayload,
      };
    case "GET_USER_CREDENTIALS_GENERATE_REFRESH_TOKEN_SUCCESS":
    case "GET_USER_CREDENTIALS_GENERATE_REFRESH_TOKEN_ERROR":
      return {
        ...state,
        GetUserCredentialsGenerateRefreshTokenResponse: action.updatePayload,
      };
    case "USER_CREDENTIAL_GENERATE_AD_TOKEN_REFRESH_SUCCESS":
    case "USER_CREDENTIAL_GENERATE_AD_TOKEN_REFRESH_ERROR":
      return {
        ...state,
        UserCredentialGenerateAdTokenRefreshResponse: action.updatePayload,
      };
    case "USER_CREDENTIAL_SAVE_AD_PROFILE_SUCCESS":
    case "USER_CREDENTIAL_SAVE_AD_PROFILE_ERROR":
      return {
        ...state,
        UserCredentialSaveAdProfileResponse: action.updatePayload,
      };
    case "FAKE_ACTION_SETTING_USER_CREDENTIALS":
      return [];
    default:
      return state;
  }
};
