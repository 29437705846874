import React from "react";
import { Wrapper } from "./style";
import NoData from "../../../../component/no-data";
export default function () {
  return (
    <Wrapper>
      <>
        {/*begin::Row*/}
        <div className="row gy-5 g-xl-5">
          {/*begin::Col*/}
          <div className="col-xxl-12">
            {/*begin::Tables Widget 9*/}
            <div className="card card-xxl-stretch mb-5 mb-xl-8">
              {/*begin::Header*/}
              <div className="card-header border-bottom-dashed">
                {/*end::Title*/}
                {/*begin::Title*/}
                <h3 className="card-title ">
                  <span className="card-label fw-bold text-gray-900">
                    Marketplace
                  </span>
                </h3>
                {/*end::Title*/}
                <div className="card-toolbar">
                  <div className="position-relative mr-4"></div>
                  <div className="position-relative ms-3"></div>
                </div>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              <div className="card-body pb-5 pt-0">
                {/*begin::Table container*/}
                <div className="table-responsive mt-5">
                  <NoData />
                </div>
              </div>
              {/*begin::Body*/}
            </div>
            {/*end::Tables Widget 9*/}
          </div>
          {/*end::Col*/}
        </div>
        {/*end::Row*/}
      </>
    </Wrapper>
  );
}
