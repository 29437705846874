import { Link } from "react-router-dom";
import Icons from "../../../../component/icons";

const GetIcons = (icon) => {
  return <Icons type={icon} />;
};
const bullet = (
  <span className="menu-bullet">
    <span className="bullet bullet-dot" />
  </span>
);

export const UserMenus = [
  {
    title: "Dashboard",
    name: "dashboard",
    path: "/dashboard",
    icon: GetIcons("dashboard"),
  },
  {
    title: "Campaign Analytics",
    name: "campaign-analytics",
    path: "/campaign-analytics",
    icon: GetIcons("campaign-analytics"),
  },
  {
    title: "Keyword Tracker",
    name: "keyword-tracker",
    path: "/keyword-tracker",
    icon: GetIcons("campaign-management"),
  },
  {
    title: "Campaign Management",
    name: "campaign-management",
    path: "/campaign-management",
    icon: GetIcons("brand-management"),
  },
  {
    title: " Advertisement Report",
    name: "advertisement-report",
    path: "/advertisement-report",
    icon: GetIcons("advertisement-report"),
    children: [
      {
        title: "Advertised Product Report",
        name: "advertised-product-report",
        path: "/advertised-product-report",
        parent: "advertisement-report",
        icon: bullet,
      },
      {
        title: "Keywords Report",
        name: "keywords-report",
        path: "/keywords-report",
        parent: "advertisement-report",
        icon: bullet,
      },
      {
        title: "Search Term Report",
        name: "search-term-report",
        path: "/search-term-report",
        parent: "advertisement-report",
        icon: bullet,
      },
    ],
  },
  {
    title: "Setting",
    name: "setting",
    path: "/setting",
    icon: GetIcons("setting"),
    children: [
      {
        title: "Marketplace",
        name: "marketplace",
        path: "/marketplace",
        parent: "setting",
        icon: bullet,
      },
    ],
  },
  {
    title: "Application Logs",
    name: "application-logs",
    path: "/application-logs",
    icon: GetIcons("application-logs"),
    children: [
      {
        title: "Central Log",
        name: "central-log",
        path: "/central-log",
        parent: "application-logs",
        icon: bullet,
      },
      {
        title: "System Error Log",
        name: "system-error-log",
        path: "/system-error-log",
        parent: "application-logs",
        icon: bullet,
      },
      {
        title: "Data Frequency Setting",
        name: "data-frequency-setting",
        path: "/data-frequency-setting",
        parent: "application-logs",
        icon: bullet,
      },
    ],
  },
];

export const AdminMenus = [
  {
    title: "Manage User",
    name: "manage-user",
    path: "/manage-user",
    icon: GetIcons("manage-user"),
  },
];

export const nameObject = {
  "/": {
    name: "Dashboard",
  },
  "/dashboard": {
    name: "Dashboard",
  },
  // "/rule-management": {
  //   name: "Rule Management",
  //   "/dashboard": "Dashboard",
  // },
  // "/keywords-list": {
  //   name: "Keywords List",
  //   "/dashboard": "Dashboard",
  //   "/dashboard": "Dashboard",
  // },
  // "/negative-keywords": {
  //   name: "Negative Keywords",
  //   "/dashboard": "Dashboard",
  //   "/dashboard": "Dashboard",
  // },
  // "/negative-asin": {
  //   name: "Negative Asin",
  //   "/dashboard": "Dashboard",
  //   "/dashboard": "Dashboard",
  // },
  "/campaign-analytics": {
    name: "Campaign Analytics",
    "/dashboard": "Dashboard",
    "/dashboard": "Dashboard",
  },
  "/keyword-tracker": {
    name: "Keyword Tracker",
  },

  "/keyword-tracker/campaign-details": {
    name: "Keyword Tracker",
  },
  // "/search-term-data": {
  //   name: "Search Term Data",
  //   "/dashboard": "Dashboard",
  //   "/dashboard": "Dashboard",
  // },
  "/setting/my-profile": {
    name: "My Profile",
    "/dashboard": "Dashboard",
    "/dashboard": "Dashboard",
  },
  "/setting/manage-user": {
    name: "Manage User",
    "/dashboard": "Dashboard",
    "/dashboard": "Dashboard",
  },
  "/setting/marketplace-credentials": {
    name: "Marketplace Credentials",
    "/dashboard": "Dashboard",
    "/dashboard": "Dashboard",
  },
  "/campaign-management": {
    name: "Campaign Management",
  },
  "/application-logs/central-log": {
    name: "Central Log",
    isSubMenu: 1,
    "/central-log": "CentralLog",
    "/central-log": "CentralLog",
  },
  "/application-logs/system-error-log": {
    name: "System Error Log",
    isSubMenu: 1,
    "/system-error-log": "SystemErrorLog",
    "/system-error-log": "SystemErrorLog",
  },
  "/application-logs/data-frequency-setting": {
    name: "Data Frequency Setting",
    isSubMenu: 1,
    "/data-frequency-setting": "DataFrequencySetting",
    "/data-frequency-setting": "DataFrequencySetting",
  },
  "/setting/marketplace": {
    name: "Marketplace",
    isSubMenu: 1,
    "/marketplace": "Marketplace",
    "/marketplace": "Marketplace",
  },
  "/advertisement-report/advertised-product-report": {
    name: "Advertised Product Report",
    isSubMenu: 1,
    "/advertised-product-report": "Advertised Product Report",
    "/advertised-product-report": "Advertised Product Report",
  },
  "/advertisement-report/keywords-report": {
    name: "Keywords Report",
    isSubMenu: 1,
    "/keywords-report": "Keywords Report",
    "/keywords-report": "Keywords Report",
  },
  "/advertisement-report/search-term-report": {
    name: "Search Term Report",
    isSubMenu: 1,
    "/search-term-report": "Search Term Report",
    "/search-term-report": "Search Term Report",
  },
};
// utils for sidebar make
const LinksMenu = (label, key, children, path) => {
  return (
    <Link
      id={Math.random()}
      to={{
        pathname: children ? `${children ? "/" + children : ""}/${key}` : path,
      }}
    >
      {label}
    </Link>
  );
};
function getItem(label, key, icon, children, type, path) {
  return {
    key,
    icon,
    children: Array.isArray(children) ? children : null,
    label:
      children && Array.isArray(children)
        ? label
        : LinksMenu(label, key, children, path),
    type,
  };
}
export const convertSidebarLinks = (type) => {
  console.log(type, "type-------------");
  const menuType = type === "admin" ? AdminMenus : UserMenus;
  return menuType?.map((d) => {
    if (d?.children && d?.children?.length !== 0) {
      return getItem(
        d?.title,
        d?.name,
        d?.icon,
        d?.children?.map((c) => {
          return getItem(c.title, c.name, c.icon, c.parent);
        })
      );
    }
    return getItem(d.title, d.name, d.icon, null, null, d.path);
  });
};
