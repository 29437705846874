import axiosCall from "../../../configurations/network-services/axiosCall";

// const prefix = "dashboard";

export const GetSearchTermReportList = (data) => {
  const path = `advertising/sp/sp-search-term-report?page=${
    data?.page || 1
  }&pageSize=${data?.perPage || 10}`;
  const responseType = "GET_SEARCH_TERM_REPORT_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionSearchTerm = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_SEARCH_TERM_REPORT", state: data });
};
