import React, { useEffect, useState } from "react";
import { DatePicker, Input, Modal } from "antd";

export default function (props) {
  const { isModalOpen, handleEdit, handleDelete, handleCancel, keyword } =
    props;

  return (
    <div>
      <Modal
        title="Confirm"
        open={isModalOpen}
        onCancel={handleCancel}
        cancelButtonProps={{
          style: {
            backgroundColor: "#d9214e",
            color: "#fff",
          },
          className: "d-none",
        }}
        okButtonProps={{ className: "d-none" }}
      >
        <div className="separator separator-dashed mb-3" />
        <span>Keyword : {keyword}</span>
        <br />
        <span>Are you sure ?</span>
        <div className="d-flex justify-content-center">
          <button
            className="btn-primary btn me-5"
            onClick={() => {
              handleEdit();
            }}
          >
            Edit
          </button>
          <button
            className="btn-danger btn"
            onClick={() => {
              handleDelete();
            }}
          >
            Delete
          </button>
        </div>
      </Modal>
    </div>
  );
}
