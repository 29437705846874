import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AsinManagement from "../../modules/pages/campaign-management";

import {
  GetASINList,
  GetCampaignList,
  GetKeywordList,
  GetKeywordLogList,
  GetNotesLogList,
  StoreKeyword,
  GetGraphKeyword,
  GetRankGraph,
  UpdateKeyword,
  DeleteKeyword,
  CombinedKeywordGraph,
  fakeActionBrandManagement,
} from "../../redux/modules/campaign-management/campaign-management.action";

import {
  GetMarketplaceByCred,
  fakeActionCampaignManagement,
} from "../../redux/modules/keyword-tracker/keyword-tracker.action";

const mapStateToProps = (state) => ({
  GetAsinListResponse: state.AsinManagement.GetAsinListResponse,
  GetCampaignListResponse: state.AsinManagement.GetCampaignListResponse,
  GetKeywordListResponse: state.AsinManagement.GetKeywordListResponse,
  GetKeywordLogListResponse: state.AsinManagement.GetKeywordLogListResponse,
  GetNotesLogListResponse: state.AsinManagement.GetNotesLogListResponse,
  GetGraphKeywordResponse: state.AsinManagement.GetGraphKeywordResponse,
  GetRankGraphResponse: state.AsinManagement.GetRankGraphResponse,
  GetStoreKeywordResponse: state.AsinManagement.GetStoreKeywordResponse,
  GetUpdateKeywordResponse: state.AsinManagement.GetUpdateKeywordResponse,
  GetDeleteKeywordResponse: state.AsinManagement.GetDeleteKeywordResponse,
  GetMarketplaceByCredResponse:
    state.CampaignManagement.GetMarketplaceByCredResponse,
  CombinedKeywordGraphResponse:
    state.CampaignManagement.CombinedKeywordGraphResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetASINList,
      GetCampaignList,
      GetKeywordList,
      GetKeywordLogList,
      GetNotesLogList,
      StoreKeyword,
      GetGraphKeyword,
      GetRankGraph,
      UpdateKeyword,
      DeleteKeyword,
      fakeActionBrandManagement,
      GetMarketplaceByCred,
      CombinedKeywordGraph,
      fakeActionCampaignManagement,
    },
    dispatch
  );

const AsinManagementZonFlip = connect(
  mapStateToProps,
  mapDispatchToProps
)(AsinManagement);

export default AsinManagementZonFlip;
