import React, { useEffect, useState } from "react";
import { Wrapper, NewSignUp } from "./style";
import { Redirect, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Carousel, Input, message } from "antd";

export default function (props) {
  const { SignUpAction, fakeActionAuth } = props;

  const [redirectPage, setRedirectPage] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact_no, setContactNo] = useState("");
  const [company_name, setCompanyName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [sending, setSending] = useState("");

  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidContactNo, setIsValidContactNo] = useState(true);

  const SignUpRes = useSelector((state) => state.Auth.SignUpResponse || {});
  // if (redirectPage || localStorage.getItem("TOKEN")) {
  //   return <Redirect to="/dashboard" />;
  // }

  const handleEmailChange = (e) => {
    const enteredEmail = e.target.value;
    setEmail(enteredEmail);
    // Regular expression to check if the email is valid
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    // Check if the entered email matches the pattern
    const isEmailValid = emailPattern.test(enteredEmail);
    console.log(isEmailValid, "isEmailValid");
    setIsValidEmail(isEmailValid);
  };

  const handleContactNoChange = (e) => {
    const enteredContactNo = e.target.value;
    setContactNo(enteredContactNo);
    // Regular expression to check if the email is valid
    const contactPattern = /^[0-9]+$/;
    // Check if the entered email matches the pattern
    const isContactNoValid = contactPattern.test(enteredContactNo);
    console.log(isContactNoValid, "isContactNoValid");
    setIsValidContactNo(isContactNoValid);
  };

  const onSignUp = (e) => {
    e.preventDefault();
    if (name && email && password && contact_no && company_name) {
      setSending(true);
      // message.destroy();
      // message.loading("Loading...", 0);
      let body = {
        name: name,
        email: email,
        password: password,
        contact_no: contact_no,
        company_name: company_name,
      };
      console.log(body, "body");
      SignUpAction(body);
    }
  };

  useEffect(() => {
    if (SignUpRes?.status) {
      setSending(false);
      message.destroy();
      message.success(SignUpRes?.message);
      localStorage.setItem("user", JSON.stringify(SignUpRes?.data?.user_data));
      localStorage.setItem("userType", SignUpRes?.data?.user_data?.user_type);
      localStorage.setItem(
        "credentials",
        JSON.stringify(SignUpRes?.data?.credentials)
      );
      localStorage.setItem("token", SignUpRes?.data?.auth_token);
      setRedirectPage(true);
      fakeActionAuth("SignUpResponse");
    } else if (Object.keys(SignUpRes).length !== 0) {
      setSending(false);
      message.destroy();
      message.error(SignUpRes?.message);
      fakeActionAuth("SignUpResponse");
    }
  }, [SignUpRes]);

  if (localStorage.getItem("userType") || redirectPage) {
    return (
      <Redirect
        to={
          localStorage.getItem("userType") == 1 ? `/manage-user` : "/dashboard"
        }
      />
    );
  }

  return (
    <>
      <Wrapper>
        <div
          className="row px-0 mx-0"
          style={{
            height: "100%",
            backgroundImage: " linear-gradient(293deg, #073d73, transparent)",
          }}
        >
          <div
            className="col-12 col-md-5 px-0 mx-0 d-flex justify-content-center"
            // style={{ background: "#d7dee5", flexDirection: "column" }}
            style={{ flexDirection: "column" }}
          >
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ flexDirection: "column", flex: "1 auto" }}
            >
              <div className="mb-5">
                <img
                  loading="lazy"
                  src="/media/zonflip-logo.png"
                  className="h-70px shimmer"
                  alt=""
                />
              </div>
              <h1
                className="fs-2qx pb-5 pb-md-4 fw-normal"
                style={{ color: "rgb(73, 73, 81)" }}
              >
                Welcome to
                <b className="fw-boldest" style={{ color: "#073d73" }}>
                  ZonFlip
                </b>
              </h1>
            </div>
            {/* <div
              className="d-flex justify-content-center align-items-center"
              style={{ flexDirection: "column", flex: "0 auto" }}
            > */}
            <div className="authFadeInTop  justify-content-center align-items-center d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px">
              <img
                // src="/media/Projections-rafiki.svg"
                src="/media/Growth-analytics-rafiki.svg"
                style={{ objectFit: "contain", width: "65%" }}
                className="mx-auto"
              />
            </div>
          </div>
          <div
            className="col-12 col-md-7 px-0 mx-0 d-flex"
            style={{ background: "" }}
          >
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ flex: "1 auto" }}
            >
              <div
                className="row mx-0"
                style={{
                  width: "560px",
                  padding: "70px 20px",
                  background: "#eaedf0",
                  // clipPath: "polygon(0 0, 100% 0, 100% 80%, 80% 100%, 0 100%)",
                  clipPath:
                    "polygon(0% 15%, 0 0, 15% 0%, 85% 0%, 100% 15%, 100% 15%, 100% 85%, 100% 100%, 85% 100%, 15% 100%, 15% 100%, 0% 85%)",
                }}
              >
                <div className="col-12 px-0 mx-0 d-flex justify-content-center">
                  <h1 className="text-dark fw-bold mb-3">
                    Sign Up to
                    <b className="fw-boldest" style={{ color: "#073d73" }}>
                      ZonFlip
                    </b>
                  </h1>
                </div>
                <div className="col-12 px-0 mx-0 text-center">
                  <label className="text-gray-400 fw-semibold fs-6">
                    Gain more control over your Amazon growth potential
                  </label>
                </div>
                <div className="row mx-0 px-0 mt-10">
                  <div className="col-12 col-md-6 mt-5">
                    <label style={{ color: "#000000b5" }}>
                      <span style={{ color: "red" }} className="me-2">
                        *
                      </span>
                      Name
                    </label>
                    <Input
                      type="text"
                      autoComplete="off"
                      data-kt-translate="sign-in-input-email"
                      className="form-control"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      style={{
                        backgroundColor: "#e8f0fe",
                        border: "1px solid #ccd5e0",
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-5">
                    <label style={{ color: "#000000b5" }}>
                      <span style={{ color: "red" }} className="me-2">
                        *
                      </span>
                      Email
                    </label>
                    <Input
                      type="text"
                      autoComplete="off"
                      data-kt-translate="sign-in-input-email"
                      className="form-control"
                      value={email}
                      onChange={handleEmailChange}
                      style={{
                        backgroundColor: "#e8f0fe",
                        border: "1px solid #ccd5e0",
                      }}
                    />
                    {!isValidEmail && (
                      <span style={{ color: "red" }}>
                        Please enter a valid email address.
                      </span>
                    )}
                  </div>
                  <div className="col-12 col-md-6 mt-5">
                    <label style={{ color: "#000000b5" }}>
                      <span style={{ color: "red" }} className="me-2">
                        *
                      </span>
                      Phone Number
                    </label>
                    <Input
                      type="text"
                      autoComplete="off"
                      data-kt-translate="sign-in-input-email"
                      className="form-control"
                      value={contact_no}
                      onChange={handleContactNoChange}
                      // onChange={(e) => {
                      //   if (!e.target.value) {
                      //     setContactNo(e.target.value);
                      //   }
                      //   const newVal = e.target.value.split(".").join("");
                      //   const validation = /^[0-9]+$/.test(newVal);
                      //   if (validation) {
                      //     setContactNo(e.target.value);
                      //   }
                      // }}
                      style={{
                        backgroundColor: "#e8f0fe",
                        border: "1px solid #ccd5e0",
                      }}
                    />
                    {!isValidContactNo && (
                      <span style={{ color: "red" }}>
                        Please enter a valid Phone Number.
                      </span>
                    )}
                  </div>{" "}
                  <div className="col-12 col-md-6 mt-5">
                    <label style={{ color: "#000000b5" }}>
                      <span style={{ color: "red" }} className="me-2">
                        *
                      </span>
                      Company Name
                    </label>
                    <Input
                      type="text"
                      autoComplete="off"
                      data-kt-translate="sign-in-input-email"
                      className="form-control"
                      value={company_name}
                      onChange={(e) => setCompanyName(e.target.value)}
                      style={{
                        backgroundColor: "#e8f0fe",
                        border: "1px solid #ccd5e0",
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-12 mt-5">
                    <label style={{ color: "#000000b5" }}>
                      <span style={{ color: "red" }} className="me-2">
                        *
                      </span>
                      Password
                    </label>
                    <Input.Password
                      size="large"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      style={{
                        backgroundColor: "#e8f0fe",
                        border: "1px solid #ccd5e0",
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-12 mt-5">
                    <label style={{ color: "#000000b5" }}>
                      <span style={{ color: "red" }} className="me-2">
                        *
                      </span>
                      Confirm Password
                    </label>
                    <Input.Password
                      size="large"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      style={{
                        backgroundColor: "#e8f0fe",
                        border: "1px solid #ccd5e0",
                      }}
                    />
                  </div>
                </div>
                {/* <div className="col-12 px-0 mx-0 mt-1 text-end">
                  <a
                    href
                    className="link-primary"
                    data-kt-translate="sign-in-forgot-password"
                  >
                    Forgot Password ?
                  </a>
                </div> */}
                <div className="col-12 px-0 mx-0 mt-5 text-center">
                  <button
                    id="kt_sign_in_submit"
                    className="btn btn-primary me-2 flex-shrink-0 btn-lg h-50px"
                    onClick={onSignUp}
                    disabled={
                      sending ||
                      !name ||
                      !isValidEmail ||
                      !email ||
                      !password ||
                      !confirmPassword ||
                      !isValidContactNo ||
                      !contact_no ||
                      !company_name
                    }
                  >
                    {!sending && (
                      <span
                        className="indicator-label"
                        data-kt-translate="sign-in-submit"
                      >
                        Sign Up
                      </span>
                    )}
                    {sending && (
                      <span>
                        <span data-kt-translate="general-progress">
                          Please wait...
                        </span>
                        <span className="spinner-border spinner-border-sm align-middle ms-2" />
                      </span>
                    )}
                  </button>
                </div>

                <div className="col-12 px-0 mx-0 mt-5 text-center">
                  <div className="text-gray-400 fs-4">
                    Already have an account?
                    <Link to="/login" className="link-primary ms-2 fw-bold ">
                      Sign in here
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>

      {/* -------------------------------------------------------------------------------------------Old Code  */}
      {/* <Wrapper className="d-flex flex-column flex-lg-row flex-column-fluid">
        <div
          className="d-flex flex-column flex-column-fluid flex-center w-lg-50 p-10"
          style={{ backgroundColor: "#e7ebef" }}
        >
          <div className="d-flex justify-content-around flex-column-fluid flex-column w-100 mw-450px">
            <div className="d-flex flex-stack py-2">
              <div className="me-2">
                <img
                  loading="lazy"
                  src="/media/zonflip-logo.png"
                  className="h-70px shimmer"
                  alt=""
                />
              </div>
            </div>

            <div className="py-20">
              <div className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">
                <div className="card-body">
                  <div className="text-start mb-10">
                    <h2
                      className="mb-3 fs-3x fw-bolder"
                      data-kt-translate="sign-in-title"
                      style={{
                        color: "#073d73",
                        borderBottom: "1px solid #21d9594d",
                        padding: "8px 0px",
                      }}
                    >
                      Sign In
                    </h2>
                    <div
                      className="text-gray-400 fw-semibold fs-6"
                      data-kt-translate="general-desc"
                    >
                      Gain more control over your Amazon growth potential
                    </div>
                  </div>

                  <div className="fv-row mb-8 fv-plugins-icon-container">
                    <Input
                      type="text"
                      
                      
                      autoComplete="off"
                      data-kt-translate="sign-in-input-email"
                      className="form-control"
                      value={username}
                      onChange={(e) => setUserName(e.target.value)}
                      style={{
                        backgroundColor: "#e8f0fe",
                        border: "1px solid #ccd5e0",
                      }}
                    />
                    <div className="fv-plugins-message-container invalid-feedback" />
                  </div>

                  <div className="fv-row mb-7 fv-plugins-icon-container">
                    <Input
                      type="password"
                      
                      name="password"
                      autoComplete="off"
                      data-kt-translate="sign-in-input-password"
                      className="form-control"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      style={{
                        backgroundColor: "#e8f0fe",
                        border: "1px solid #ccd5e0",
                      }}
                    />
                    <div className="fv-plugins-message-container invalid-feedback" />
                  </div>

                  <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-10">
                    <div />
                    <a
                      href
                      className="link-primary"
                      data-kt-translate="sign-in-forgot-password"
                    >
                      Forgot Password ?
                    </a>
                  </div>

                  <div className="d-flex flex-stack">
                    <button
                      id="kt_sign_in_submit"
                      className="btn btn-primary me-2 flex-shrink-0 btn-lg h-50px"
                      onClick={onSignUp}
                    >
                      <span
                        className="indicator-label"
                        data-kt-translate="sign-in-submit"
                      >
                        Sign In
                      </span>

                      <span className="indicator-progress">
                        <span data-kt-translate="general-progress">
                          Please wait...
                        </span>
                        <span className="spinner-border spinner-border-sm align-middle ms-2" />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="m-0"></div>
          </div>
        </div>

        <div className="d-lg-flex flex-lg-row-fluid w-50 bgi-size-cover bgi-position-y-center bgi-position-x-start bgi-no-repeat">
          <img src="/media/Data Trends-pana.svg" />
        </div>
      </Wrapper> */}
    </>
  );
}
