import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CampaignManagement from "../../modules/pages/keyword-tracker";
import {
  GetCampaignManagementList,
  fakeActionCampaignManagement,
} from "../../redux/modules/keyword-tracker/keyword-tracker.action";

const mapStateToProps = (state) => ({
  GetCampaignManagementListResponse:
    state.CampaignManagement.GetCampaignManagementListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetCampaignManagementList,
      fakeActionCampaignManagement,
    },
    dispatch
  );

const CampaignManagementZonFlip = connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignManagement);

export default CampaignManagementZonFlip;
