import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ZoneFlipTable from "../../../../component/zonFlipTable";
import Pagination from "../../../../component/pagination";
import { DefaultPerPage } from "../../../../config";
import Loading from "../../../../component/loading";
import { SearchOutlined } from "@ant-design/icons";
import { Input, Select, Tag } from "antd";
import NoData from "../../../../component/no-data";
import moment from "moment";

export default function (props) {
  // return <span>Keyord Report</span>;
  const location = useLocation();
  const { GetKeywordReportList, fakeActionKeywordReportList } = props;
  const GetKeywordReportListRes = useSelector(
    (state) => state.KeywordReport.GetKeywordReportListResponse || {}
  );

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);

  useEffect(() => {
    const apiObj = {
      page: 1,
      perPage: pageSize,
    };
    GetKeywordReportList(apiObj);
  }, []);

  useEffect(() => {
    if (GetKeywordReportListRes.status === true) {
      setList(GetKeywordReportListRes?.data?.records);
      console.log(GetKeywordReportListRes, "GetKeywordReportListRes");
      setTotalPage(GetKeywordReportListRes?.data?.pagination?.totalCount);
      setLoading(false);
      fakeActionKeywordReportList("GetKeywordReportListResponse");
    } else if (GetKeywordReportListRes.status === false) {
      setLoading(false);
      fakeActionKeywordReportList("GetKeywordReportListResponse");
    }
  }, [GetKeywordReportListRes]);

  const onPageNo = (e) => {
    setLoading(true);
    const apiObj = {
      page: e,
      perPage: pageSize,
    };
    GetKeywordReportList(apiObj);
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    const apiObj = {
      page: 1,
      perPage: e,
    };
    GetKeywordReportList(apiObj);
  };

  const columns = [
    {
      title: "#",
      ellipsis: true,
      align: "left",
      render: (text) => {
        return <span>{(page - 1) * pageSize + 1 + text.key}</span>;
      },
    },
    {
      title: "Keyword",
      dataIndex: "keyword",
      key: "keyword",
      render: (text) => {
        return <span className="fw-bold">{text}</span>;
      },
    },
    {
      title: "Keyword Bid",
      dataIndex: "keyword_bid",
      key: "keyword_bid",
    },
    {
      title: "Keyword Id",
      dataIndex: "keyword_id",
      key: "keyword_id",
    },
    {
      title: "Keyword Type",
      dataIndex: "keyword_type",
      key: "keyword_type",
    },
    {
      title: "Acos Clicks7d",
      dataIndex: "acos_clicks7d",
      key: "acos_clicks7d",
    },
    {
      title: "Acos Clicks14d",
      dataIndex: "acos_clicks14d",
      key: "acos_clicks14d",
    },
    {
      title: "Ad Group Id",
      dataIndex: "ad_group_id",
      key: "ad_group_id",
    },
    {
      title: "Ad Group Name",
      dataIndex: "ad_group_name",
      key: "ad_group_name",
    },
    {
      title: "Attributed Sales Same Sku1d",
      dataIndex: "attributed_sales_same_sku1d",
      key: "attributed_sales_same_sku1d",
    },
    {
      title: "Attributed Sales Same Sku7d",
      dataIndex: "attributed_sales_same_sku7d",
      key: "attributed_sales_same_sku7d",
    },
    {
      title: "Attributed Sales Same Sku14d",
      dataIndex: "attributed_sales_same_sku14d",
      key: "attributed_sales_same_sku14d",
    },
    {
      title: "Attributed Sales Same Sku30d",
      dataIndex: "attributed_sales_same_sku30d",
      key: "attributed_sales_same_sku30d",
    },
    {
      title: "Brand Id",
      dataIndex: "brand_id",
      key: "brand_id",
    },
    {
      title: "Campaign Budget Amount",
      dataIndex: "campaign_budget_amount",
      key: "campaign_budget_amount",
    },
    {
      title: "Campaign Budget Currency Code",
      dataIndex: "campaign_budget_currency_code",
      key: "campaign_budget_currency_code",
    },
    {
      title: "Campaign Budget Type",
      dataIndex: "campaign_budget_type",
      key: "campaign_budget_type",
    },
    {
      title: "Campaign Id",
      dataIndex: "campaign_id",
      key: "campaign_id",
    },
    {
      title: "Campaign Name",
      dataIndex: "campaign_name",
      key: "campaign_name",
    },
    {
      title: "Campaign Status",
      dataIndex: "campaign_status",
      key: "campaign_status",
    },
    {
      title: "Click Through Rate",
      dataIndex: "click_through_rate",
      key: "click_through_rate",
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "cost",
    },
    {
      title: "Cost Per Click",
      dataIndex: "cost_per_click",
      key: "cost_per_click",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => {
        return (
          <span>
            {moment(new Date(text * 1000)).format("MMM DD, YYYY hh:mm A")}
          </span>
        );
      },
    },
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Impressions",
      dataIndex: "impressions",
      key: "impressions",
    },

    {
      title: "Kindle Edition Normalized Pages Read14d",
      dataIndex: "kindle_edition_normalized_pages_read14d",
      key: "kindle_edition_normalized_pages_read14d",
    },
    {
      title: "Kindle Edition Normalized Pages Royalties 14d",
      dataIndex: "kindle_edition_normalized_pages_royalties_14d",
      key: "kindle_edition_normalized_pages_royalties_14d",
    },
    {
      title: "Marketplace",
      dataIndex: "marketplace",
      key: "marketplace",
    },
    {
      title: "Marketplace Id",
      dataIndex: "marketplace_id",
      key: "marketplace_id",
    },
    {
      title: "Match Type",
      dataIndex: "match_type",
      key: "match_type",
    },
    {
      title: "Portfolio Id",
      dataIndex: "portfolio_id",
      key: "portfolio_id",
    },
    {
      title: "Profile Id",
      dataIndex: "profile_id",
      key: "profile_id",
    },
    {
      title: "Purchases 1d",
      dataIndex: "purchases1d",
      key: "purchases1d",
    },
    {
      title: "Purchases 7d",
      dataIndex: "purchases7d",
      key: "purchases7d",
    },
    {
      title: "Purchases 14d",
      dataIndex: "purchases14d",
      key: "purchases14d",
    },
    {
      title: "Purchases 30d",
      dataIndex: "purchases30d",
      key: "purchases30d",
    },
    {
      title: "Purchases Same Sku 1d",
      dataIndex: "purchases_same_sku1d",
      key: "purchases_same_sku1d",
    },
    {
      title: "Purchases Same Sku 7d",
      dataIndex: "purchases_same_sku7d",
      key: "purchases_same_sku7d",
    },
    {
      title: "Purchases Same Sku 14d",
      dataIndex: "purchases_same_sku14d",
      key: "purchases_same_sku14d",
    },
    {
      title: "Purchases Same Sku 30d",
      dataIndex: "purchases_same_sku30d",
      key: "purchases_same_sku30d",
    },
    {
      title: "Report Date",
      dataIndex: "report_date",
      key: "report_date",
      render: (text) => {
        return <span>{moment(new Date(text)).format("MMM DD, YYYY")}</span>;
      },
    },
    {
      title: "Roas Clicks 7d",
      dataIndex: "roas_clicks7d",
      key: "roas_clicks7d",
    },
    {
      title: "Roas Clicks 14d",
      dataIndex: "roas_clicks14d",
      key: "roas_clicks14d",
    },
    {
      title: "Sales 1d",
      dataIndex: "sales1d",
      key: "sales1d",
    },
    {
      title: "Sales 7d",
      dataIndex: "sales7d",
      key: "sales7d",
    },
    {
      title: "Sales 14d",
      dataIndex: "sales14d",
      key: "sales14d",
    },
    {
      title: "Sales 30d",
      dataIndex: "sales30d",
      key: "sales30d",
    },
    {
      title: "Sales Other Sku 7d",
      dataIndex: "sales_other_sku7d",
      key: "sales_other_sku7d",
    },
    {
      title: "System Event Process Id",
      dataIndex: "system_event_process_id",
      key: "system_event_process_id",
    },
    {
      title: "Targeting",
      dataIndex: "targeting",
      key: "targeting",
    },
    {
      title: "Units Sold Clicks 1d",
      dataIndex: "units_sold_clicks1d",
      key: "units_sold_clicks1d",
    },
    {
      title: "Units Sold Clicks 7d",
      dataIndex: "units_sold_clicks7d",
      key: "units_sold_clicks7d",
    },
    {
      title: "Units Sold Clicks 14d",
      dataIndex: "units_sold_clicks14d",
      key: "units_sold_clicks14d",
    },
    {
      title: "Units Sold Clicks 30d",
      dataIndex: "units_sold_clicks30d",
      key: "units_sold_clicks30d",
    },
    {
      title: "Units Sold Other Sku 7d",
      dataIndex: "units_sold_other_sku7d",
      key: "units_sold_other_sku7d",
    },
    {
      title: "Units Sold Same Sku 1d",
      dataIndex: "units_sold_same_sku1d",
      key: "units_sold_same_sku1d",
    },
    {
      title: "Units Sold Same Sku 7d",
      dataIndex: "units_sold_same_sku7d",
      key: "units_sold_same_sku7d",
    },
    {
      title: "Units Sold Same Sku 14d",
      dataIndex: "units_sold_same_sku14d",
      key: "units_sold_same_sku14d",
    },
    {
      title: "Units Sold Same Sku 30d",
      dataIndex: "units_sold_same_sku30d",
      key: "units_sold_same_sku30d",
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text) => {
        return (
          <span>
            {moment(new Date(text * 1000)).format("MMM DD, YYYY hh:mm A")}
          </span>
        );
      },
    },
  ];
  return (
    <>
      <div className="fadeInRight">
        <div className="d-flex flex-wrap gap-3 mb-3">
          <div className="position-relative mr-4"></div>
        </div>

        <div className="row gy-5 g-xl-5">
          <div className="col-xxl-12">
            <div className="card card-xxl-stretch mb-5 mb-xl-8">
              <div className="card-header border-bottom-dashed">
                <h3 className="card-title ">
                  <span className="card-lbel fw-bold text-gray-900">
                    Keyword Report
                  </span>
                </h3>

                <div className="card-toolbar">
                  <div className="position-relative mr-4"></div>
                </div>
              </div>

              <div className="card-body pb-5 pt-0">
                <div className="table-responsive mt-5">
                  {loading ? (
                    <Loading />
                  ) : list?.length !== 0 ? (
                    <ZoneFlipTable
                      columns={columns}
                      fixed="top"
                      dataSource={list}
                      rowKey="key"
                      loading={loading}
                      pagination={false}
                      scroll={{ x: "max-content" }}
                    />
                  ) : (
                    <NoData />
                  )}
                </div>
                <Pagination
                  loading={loading || list?.length === 0}
                  pageSize={pageSize}
                  page={page}
                  totalPage={totalPage}
                  onPerPage={onPerPage}
                  onPageNo={onPageNo}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
