import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CentralLog from "../../../modules/pages/application-log/central-log";
import {
  GetCentralLogList,
  fakeActionCentralLog,
} from "../../../redux/modules/application-log/central-log/central-log.action";

const mapStateToProps = (state) => ({
  GetCentralLogListResponse: state.CentralLog.GetCentralLogListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetCentralLogList,
      fakeActionCentralLog,
    },
    dispatch
  );

const CentralLogZonFlip = connect(
  mapStateToProps,
  mapDispatchToProps
)(CentralLog);

export default CentralLogZonFlip;
