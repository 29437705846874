import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Marketplace from "../../../modules/pages/setting/marketplace";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const MarketplaceZonFlip = connect(
  mapStateToProps,
  mapDispatchToProps
)(Marketplace);

export default MarketplaceZonFlip;
