import React, { useEffect, useState } from "react";
import { Wrapper } from "./style";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ZoneFlipTable from "../../../../component/zonFlipTable";
import { DefaultPerPage } from "../../../../config";
import Pagination from "../../../../component/pagination";
import Loading from "../../../../component/loading";
import moment from "moment";
import { Input, Popover } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import NoData from "../../../../component/no-data";
import ZonFlipTooltip from "../../../../component/tooltip";

export default function (props) {
  const { GetSystemErrorLogList, fakeActionSystemErrorLog } = props;
  const GetSystemErrorLogListRes = useSelector(
    (state) => state.SystemErrorLog.GetSystemErrorLogListResponse || {}
  );

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);

  useEffect(() => {
    const apiObj = {
      page: 1,
      perPage: pageSize,
    };
    GetSystemErrorLogList(apiObj);
    return () => {};
  }, []);

  useEffect(() => {
    if (GetSystemErrorLogListRes.status === true) {
      setList(GetSystemErrorLogListRes?.data?.records);
      console.log(GetSystemErrorLogListRes, "GetSystemErrorLogListRes");
      setLoading(false);
      setTotalPage(GetSystemErrorLogListRes?.data?.pagination?.totalCount);
      fakeActionSystemErrorLog("GetSystemErrorLogListResponse");
    } else if (GetSystemErrorLogListRes.status === false) {
      setLoading(false);
      fakeActionSystemErrorLog("GetSystemErrorLogListResponse");
    }
  }, [GetSystemErrorLogListRes]);

  const columns = [
    {
      title: "#",
      ellipsis: true,
      align: "left",
      render: (text) => {
        return <span>{(page - 1) * pageSize + 1 + text.key}</span>;
      },
    },

    {
      title: "Log Created at",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>
              {moment(new Date(item?.created_at * 1000)).format(
                "MMM DD, YYYY hh:mm A"
              )}
            </span>
          </div>
        );
      },
    },
    {
      title: "Event Name",
      align: "left",
      render: (item) => {
        return (
          <span className="fw-bold">
            <Popover content={item?.event_name} placement="bottom">
              {item?.event_name || "-"}
            </Popover>
          </span>
        );
      },
    },
    {
      title: "Event Type",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>{item?.event_type || "-"}</span>
          </div>
        );
      },
    },
    {
      title: "Error Message",
      width: 300,
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>{item?.error_message}</span>
          </div>
        );
      },
    },
    {
      title: "Error Data",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>{item?.error_data}</span>
          </div>
        );
      },
    },
  ];

  const onPageNo = (e) => {
    setLoading(true);
    const apiObj = {
      page: e,
      perPage: pageSize,
    };
    GetSystemErrorLogList(apiObj);
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    const apiObj = {
      page: 1,
      perPage: e,
    };
    GetSystemErrorLogList(apiObj);
  };

  return (
    <Wrapper className="fadeInRight">
      <>
        {/*begin::Row*/}
        <div className="row gy-5 g-xl-5">
          {/*begin::Col*/}
          <div className="col-xxl-12">
            {/*begin::Tables Widget 9*/}
            <div className="card card-xxl-stretch mb-5 mb-xl-8">
              {/*begin::Header*/}
              <div className="card-header border-bottom-dashed">
                {/*end::Title*/}
                {/*begin::Title*/}
                <h3 className="card-title ">
                  <span className="card-label fw-bold text-gray-900">
                    System Error Log
                  </span>
                </h3>
                {/*end::Title*/}
                <div className="card-toolbar">
                  <div className="position-relative mr-4"></div>
                  <div className="position-relative ms-3"></div>
                </div>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              <div className="card-body pb-5 pt-0">
                {/*begin::Table container*/}
                <div className="table-responsive mt-5">
                  {loading ? (
                    <Loading />
                  ) : list?.length != 0 ? (
                    <>
                      <ZoneFlipTable
                        columns={columns}
                        fixed="top"
                        dataSource={list}
                        rowKey="key"
                        loading={loading}
                        pagination={false}
                        scroll={{ x: "max-content" }}
                      />
                    </>
                  ) : (
                    <NoData />
                  )}
                </div>
                <Pagination
                  loading={loading || list?.length === 0}
                  pageSize={pageSize}
                  page={page}
                  totalPage={totalPage}
                  onPerPage={onPerPage}
                  onPageNo={onPageNo}
                />
              </div>
              {/*begin::Body*/}
            </div>
            {/*end::Tables Widget 9*/}
          </div>
          {/*end::Col*/}
        </div>
        {/*end::Row*/}
      </>
    </Wrapper>
  );
}
