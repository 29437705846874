import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ZoneFlipTable from "../../../../component/zonFlipTable";
import Pagination from "../../../../component/pagination";
import { DefaultPerPage, urlDecode } from "../../../../config";
import Loading from "../../../../component/loading";
import { SearchOutlined } from "@ant-design/icons";
import { Input, Popover, Select, Tag, Tooltip } from "antd";
import NoData from "../../../../component/no-data";
import ZonFlipTooltip from "../../../../component/tooltip";
import moment from "moment";

export default function (props) {
  const location = useLocation();
  const {
    GetCampaignList,
    fakeActionBrandManagement,
    setTabStatus,
    selectedBread,
    setSelectedBread,
  } = props;
  const GetCampaignListRes = useSelector(
    (state) => state.AsinManagement.GetCampaignListResponse || {}
  );

  const [campaignAsin, setCampaignAsin] = useState(selectedBread?.asin);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);
  const [searchCampaign, setSearchCampaign] = useState("");
  const [searchCampaignStatus, setSearchCampaignStatus] = useState(null);

  //   useEffect(() => {
  //     console.log("location", location);
  //     // if (location?.state) {
  //     setCampaignIdAsin(location?.state?.advertised_asin);
  //     // } else {
  //     //   const decodeUrl = urlDecode(location);
  //     //   setCampaignIdAsin(decodeUrl?.advertised_asin);
  //     // }
  //   }, [location]);

  useEffect(() => {
    // if (campaignIdAsin) {
    const apiObj = {
      page: 1,
      perPage: pageSize,
      asin: campaignAsin,
      searchCampaignName: searchCampaign,
      searchCampaignStatus: searchCampaignStatus,
    };
    GetCampaignList(apiObj);
    // }
  }, []);

  useEffect(() => {
    if (GetCampaignListRes.status === true) {
      setList(GetCampaignListRes?.data?.records);
      console.log(GetCampaignListRes, "GetCampaignListRes");
      setTotalPage(GetCampaignListRes?.data?.pagination?.totalCount);
      setLoading(false);
      // setTotalPage(GetExpensesListRes?.data?.pagination?.totalCount);
      fakeActionBrandManagement("GetCampaignListResponse");
    } else if (GetCampaignListRes.status === false) {
      setLoading(false);
      fakeActionBrandManagement("GetCampaignListResponse");
    }
  }, [GetCampaignListRes]);

  const onPageNo = (e) => {
    setLoading(true);
    const apiObj = {
      page: e,
      perPage: pageSize,
      asin: campaignAsin,
      searchCampaignName: searchCampaign,
      searchCampaignStatus: searchCampaignStatus,
    };
    GetCampaignList(apiObj);
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    const apiObj = {
      page: 1,
      perPage: e,
      asin: campaignAsin,
      searchCampaignName: searchCampaign,
      searchCampaignStatus: searchCampaignStatus,
    };
    GetCampaignList(apiObj);
  };

  const columns = [
    {
      title: "#",
      width: 100,
      ellipsis: true,
      align: "left",
      render: (text) => {
        return <span>{(page - 1) * pageSize + 1 + text.key}</span>;
      },
    },

    {
      title: "Name",
      align: "left",
      width: 300,
      render: (item) => {
        return (
          <div>
            <Popover content={item?.campaignName} placement="bottom">
              <div className="actionIcon">
                <span style={{ fontWeight: "600" }}>{item?.campaignName}</span>
              </div>
            </Popover>
          </div>
        );
      },
    },
    {
      title: "Start Date",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>
              {moment(item?.startDate).format("MMM DD, YYYY ") || "-"}
            </span>
          </div>
        );
      },
    },
    {
      title: "Status",
      align: "left",
      render: (item) => {
        return (
          <div>
            <Tag color="green">{item?.state}</Tag>
          </div>
        );
      },
    },
    {
      title: "Action",
      align: "left",
      render: (item) => {
        return (
          <>
            <span
              onClick={() => {
                const selected_ = { ...selectedBread };
                selected_.campaignId = item?.campaignId;
                selected_.campaignName = item?.campaignName;
                console.log("selected", selected_);
                setSelectedBread(selected_);
                setTabStatus("View Keyword");
              }}
              className="btn btn-sm btn-primary"
            >
              View Keyword
            </span>
            {/* <Link
              to={{
                pathname: "/brand-management/view-keyword",
                state: {
                  campaignId: item?.campaign_id,
                  campaignName: item?.campaign_name,
                },
              }}
              className="btn btn-sm btn-primary"
            >
              View Keyword
            </Link> */}
          </>
        );
      },
    },
  ];

  const filter1 = [
    { label: "Enabled", value: "Enabled" },
    { label: "Paused", value: "Paused" },
    { label: "Archived", value: "Archived" },
    { label: "User Deleted", value: "User Deleted" },
  ];

  return (
    <>
      <div className="fadeInRight">
        <div
          className="d-flex gap-3 mb-3 justify-content-between align-items-center"
          style={{
            background: "#21d9591f",
            padding: "10px 0px",
            borderRadius: "7px",
          }}
        >
          <div className="position-relative mx-5 d-flex align-items-center">
            <button
              onClick={() => {
                setTabStatus("Asin Management");
              }}
              class="btn btn-primary btn-icon me-3"
              style={{ height: "30px", width: "30px" }}
            >
              <i class="bi bi-arrow-left-short fs-1"></i>
            </button>
            <span className="me-1 d-flex align-items-center">
              <Link
                to={{
                  pathname: "/dashboard",
                }}
              >
                <i className="ki-outline ki-home fs-6 d-flex" />
              </Link>
            </span>
            <span className="me-1 d-flex align-items-center">
              <i className="ki-outline ki-right fs-7" />
            </span>
            <span
              onClick={() => {
                setTabStatus("Asin Management");
              }}
              style={{ cursor: "pointer" }}
            >
              ASIN Management
            </span>
            <span className="me-1 d-flex align-items-center">
              <i className="ki-outline ki-right fs-7" />
            </span>
            <span className="fw-bold">View Campaign</span>
          </div>
          <div className="me-2">
            <label className="fw-bold fs-6 d-flex">
              <img
                src={`/media/domainImage/${selectedBread?.marketplace_name}.png`}
                style={{ height: "20px" }}
                className="me-2"
              />
              {selectedBread?.marketplace_name}
            </label>
          </div>
        </div>
        <div className="row gy-5 g-xl-5">
          <div className="col-xxl-12">
            <div className="card card-xxl-stretch mb-5 mb-xl-8">
              <div className="card-header border-bottom-dashed">
                <h3 className="card-title ">
                  <span className="card-label fw-bold text-gray-900">
                    Campaign List
                  </span>
                  <Tag color="purple">ASIN : {campaignAsin}</Tag>
                </h3>

                <div className="card-toolbar">
                  <div className="position-relative mr-4">
                    <Input
                      type="text"
                      size="large"
                      placeholder="Search for Campaign"
                      className="ant_common_input fs-6 fw-bold"
                      prefix={
                        <SearchOutlined
                          style={{ color: "#a1a5b7", fontSize: "18px" }}
                        />
                      }
                      onChange={(e) => {
                        setSearchCampaign(e.target.value);
                      }}
                      onPressEnter={() => {
                        setLoading(true);
                        const apiObj = {
                          page: 1,
                          perPage: pageSize,
                          asin: campaignAsin,
                          searchCampaignName: searchCampaign,
                          searchCampaignStatus: searchCampaignStatus,
                        };
                        GetCampaignList(apiObj);
                      }}
                    />
                  </div>
                  <div className="position-relative ms-3">
                    <Select
                      // value={1}
                      placeholder="Select Option"
                      size="large"
                      style={{ width: "130px" }}
                      className="fs-7"
                      allowClear
                      value={searchCampaignStatus}
                      showSearch
                      options={filter1?.map((d, i) => {
                        return { value: d?.value, label: d?.label };
                      })}
                      onChange={(e) => {
                        setLoading(true);
                        setSearchCampaignStatus(e);
                        const apiObj = {
                          page: 1,
                          perPage: pageSize,
                          asin: campaignAsin,
                          searchCampaignName: searchCampaign,
                          searchCampaignStatus: e,
                        };
                        GetCampaignList(apiObj);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="card-body pb-5 pt-0">
                <div className="table-responsive mt-5">
                  {loading ? (
                    <Loading />
                  ) : list?.length !== 0 ? (
                    <ZoneFlipTable
                      columns={columns}
                      fixed="top"
                      dataSource={list}
                      rowKey="key"
                      loading={loading}
                      pagination={false}
                      scroll={{ x: "max-content" }}
                    />
                  ) : (
                    <NoData />
                  )}
                </div>
                <Pagination
                  loading={loading || list?.length === 0}
                  pageSize={pageSize}
                  page={page}
                  totalPage={totalPage}
                  onPerPage={onPerPage}
                  onPageNo={onPageNo}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
