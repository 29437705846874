export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_ADVERTISED_PRODUCT_REPORT_LIST_SUCCESS":
    case "GET_ADVERTISED_PRODUCT_REPORT_LIST_ERROR":
      return {
        ...state,
        GetAdvertisedProductReportListResponse: action.updatePayload,
      };

    case "FAKE_ACTION_ADVERTISED_PRODUCT":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
