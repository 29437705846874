import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SignUp from "../../../modules/pages/auth/sign-up";

import {
  SignUpAction,
  fakeActionAuth,
} from "../../../redux/modules/auth/auth.action";

const mapStateToProps = (state) => ({
  SignUpResponse: state.Auth.SignUpResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ SignUpAction, fakeActionAuth }, dispatch);

const SignUpZonFlip = connect(mapStateToProps, mapDispatchToProps)(SignUp);

export default SignUpZonFlip;
