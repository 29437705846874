import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ForgotPassword from "../../../modules/pages/auth/forgot-password";

import {
  ForgotPasswordAction,
  fakeActionAuth,
} from "../../../redux/modules/auth/auth.action";

const mapStateToProps = (state) => ({
  ForgotPasswordResponse: state.Auth.ForgotPasswordResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ForgotPasswordAction, fakeActionAuth }, dispatch);

const ForgotPasswordZonFlip = connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPassword);

export default ForgotPasswordZonFlip;
