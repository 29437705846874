import { createGlobalStyle } from "styled-components";

export const AppStyleWrapper = createGlobalStyle`
    #root {
        height: 100%;
    }
    ::selection {
        color: #181c32;
        background: #e96fc067;
    }

        ::-webkit-scrollbar {
          width: 10px !important;
          height: 12px !important;
          background-color: transparent;
        }
    
        ::-webkit-scrollbar-track {
          border-radius: 8px;
          background-color: transparent !important;
          border: 2px solid transparent;
        }
    
        ::-webkit-scrollbar-thumb {
          border: 3px solid transparent !important;
          background-clip: content-box;
          background-color: transparent !important;
          border-radius: 10px;
        }
        ::-webkit-scrollbar-thumb:hover {
          background-color: #073d73 !important;
        }
        &:hover {
          &::-webkit-scrollbar {
            width: 10px !important;
            background-color: transparent;
          }
    
          &::-webkit-scrollbar-track {
            border-radius: 8px;
            background-color: transparent;
            background-color: #073d73;
            border: 1px solid #d1d1d1;
          }
    
          &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            border: 3px solid transparent;
            background-clip: content-box;
            background-color: #073d73 !important;
            border-radius: 10px;
          }
          &::-webkit-scrollbar-thumb:hover {
            background-color: #073d73 !important;
          }
        }
      }

    
    .btn-light-primary{
      background-color: #d9e8f8 !important;
    }
    .ant-select-selector{
        border-color: #ecf0f4 !important;
        background-color: #ecf0f4 !important;
        border-radius:4px !important;
    }
    .ant-select-selection-item{
        font-size: 1rem !important;
        font-weight: bold !important;
        color: #00000099 !important;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        position: relative !important; 
        box-shadow: none !important;
    }
    .ant-select-selection-placeholder{
        color: #00000075 !important;
        font-weight : bold;
        font-size: 1rem !important;
    }
    .ant-select-item-option-selected{
        background-color : #dbdbdb !important;
    }


    .antd_selected_presets_date {
        color: #fff !important;
        background: #073d73 !important;
            &:hover {
            color: #073d73 !important;
            }
        }
    }
    .ant-picker-dropdown 
    .ant-picker-cell-in-view
    .ant-picker-cell-selected 
    .ant-picker-cell-inner 
    .ant-picker-cell-in-view
    .ant-picker-cell-range-start 
    .ant-picker-cell-range-end {
        color: #fff !important;
        background: #073d73 !important;    
    }
    .ant-picker:hover,.ant-picker-focused{    
        border-color: #e1e3ea !important;
        box-shadow: none !important;
    }
    .ant-picker-dropdown .ant-picker-panel-container .ant-picker-presets ul li{
        border-radius: 0px !important;
        padding-inline: 9px !important;
        padding-block: 2px !important;
    }
    .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before{
        border: 1px solid #073d73 !important;
    }
    .ant-picker-presets ul{
        padding : 15px 2px !important;
    }
    .ant-picker-active-bar{
        background: #073d73 !important;
    }
    .ant-picker-input input{
        color: #00000075 !important;
        font-weight : bold;
        font-size: 1rem !important;
    }
    .ant-picker-large{
        padding : 9px 11px !important;
    }
    .ant-picker-cell-in-range::before{
        background: #e0e4e6 !important
    }


    .ant_common_input { 
        background-color: #ecf0f4 !important;
        border-color: #ecf0f4 !important;
        transition: color .2s ease !important;
        border-radius:4px !important;
        line-height: 1.7 !important;
    }
    .ant_common_input::placeholder {
        color:#000 !important; 
    }
    .ant-input{
        box-shadow:none !important;
        background-color: #ecf0f4;
    }
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
    .ant-input:focus,
    .ant-input-focused,
    .ant-input:hover {
        border-color: #ecf0f4 ;
        box-shadow:none ;
    }

    .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused{
        box-shadow:none !important;
    }

    .ant-table{
        font-size:14px !important;
        font-family : Inter,Helvetica,sans-serif !important
    }
    .card-header{
        border-bottom: 1px dashed #073d7330 !important;
    }
    @keyframes fadeIn {
        from {
        opacity: 0;
        }
        to {
        opacity: 1;
        }
    }

    .fade-in {
        opacity: 0;
        animation: fadeIn ease-in 1;
        animation-fill-mode: forwards;
        animation-duration: 0.4s;
    }

    .authFadeInBottom {
        animation-duration: 1s;
        animation-fill-mode: both;
        animation-name: authFadeInBottom;
        z-index : 99
    }

    .line-clamp {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .tooltipBox {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .Delete-Icon-Antd {
        cursor: pointer;
        margin: 0px 10px;
        svg {
          transition: 0.6s;
          font-size: 20px;
          path:first-child {
            fill: white;
          }
          path {
            fill: #ef7fb1;
          }
        }
        &:hover {
          svg {
            transform: scale(1.2);
            path:first-child {
              fill: #ef7fb156;
            }
            path {
              fill: #ef7fb1;
            }
          }
        }
    }
    
    .Edit-Icon-Antd {
        cursor: pointer;
        margin: 0px 10px;
        svg {
        transition: 0.6s;
        font-size: 20px;
        path:first-child {
            fill: white;
        }
        path {
            fill: grey;
        }
        }
        &:hover {
        svg {
            transform: scale(1.2);
            path {
            fill: grey;
            }
        }
        }
    }

    .fadeInRight {
      animation-duration: 0.7s;
      animation-fill-mode: both;
      animation-name: fadeInRight;
    }
    
    @keyframes fadeInRight {
      from {
        opacity: 0;
        transform: translate3d(50px, 50px, 50px); 
      }
      to {
        opacity: 1;
      }
    }

    .fadeInTop {
      animation-duration: 0.7s;
      animation-fill-mode: both;
      animation-name: fadeInTop;
    }
    
    @keyframes fadeInTop {
      from {
        opacity: 0;
        transform: translateY(-200px); 
      }
      to {
        opacity: 1;
      }
    }

    .fadeInTopUser {
      animation-duration: 0.7s;
      animation-fill-mode: both;
      animation-name: fadeInTopUser;
    }
    
    @keyframes fadeInTopUser {
      from {
        opacity: 0;
        transform: translateY(-200px); 
      }
      to {
        opacity: 1;
      }
    }

    .fadeOutBottom {
      animation-duration: 0.5s;
      animation-fill-mode: both;
      animation-name: fadeOutBottom;
    }
    
    @keyframes fadeOutBottom {
      0% {
        opacity: 0;        
        transform: translate(0px); 
      }
      100% {
        opacity: 1;        
        transform: translateY(-320px); 
      }
    }

    .fadeInLeft{
      animation-duration: 0.5s;
      animation-fill-mode: both;
      animation-name: fadeInLeft;
    }
    
    @keyframes fadeInLeft {
      0% {
        opacity: 0;        
        transform: translateX(-200px); 
      }
      100% {
        opacity: 1;               
      }
    }

    .shimmer {
      -webkit-mask: linear-gradient(-60deg, rgb(0, 0, 0) 30%, rgba(0, 0, 0, 0.333), rgb(0, 0, 0) 70%) right center / 300% 100%;
      background-repeat: no-repeat;
      animation: 2.5s ease 0s infinite normal none running shimmer;
    }

    @keyframes shimmer{
      100% {
        -webkit-mask-position: left center;
      }
    }

    .actionIcon {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      cursor: pointer;
    }

    .remove-cell-padding{
      padding-left : 0px !important;
      padding-right : 0px !important;
      font-size: 13px !important;
    }
    .add-cell-padding{
      padding-left : 5px !important;
      padding-right : 5px !important;
    }
    .add-cell-padding-3{
      padding-left : 3px !important;
      padding-right : 3px !important;
    }
    .apexcharts-xaxistooltip.apexcharts-theme-light{
        background:#fff !important;
    }

    .ant-message{
      z-index: 1070 !important;
    }
`;
