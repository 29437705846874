import axiosCall from "../../configurations/network-services/axiosCall";

// const prefix = "dashboard";

export const GetCampaignManagementList = (data) => {
  // const path = `keyword-campaign-list?page=${data?.page || 1}&pageSize=${
  const path = `campaign-list?page=${data?.page || 1}&pageSize=${
    data?.perPage || 10
  }&name=${data?.campaign_name || ""}&state=${data?.status || ""}`;
  const responseType = "GET_CAMPAIGN_MANAGEMENT_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GetCampaignDetailsList = (data) => {
  // const path = `campaign-keyword-rank-data-list?page=${
  const path = `get-asin-list-ranking-wise?page=${data?.page || 1}&pageSize=${
    data?.perPage || 10
  }&start_date=${data?.start_date || ""}&end_date=${
    data?.end_date || ""
  }&advertised_asin=${data?.asin || ""}&keyword=${
    data?.keyword || ""
  }&marketplace_id=${data?.marketplace_id}`;
  const responseType = "GET_KEYWORD_DETAILS_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const AddKeywordAction = (data) => {
  // const path = `campaign-keyword-rank-data-list?page=${
  const path = `asin-store`;
  const responseType = "ADD_KEYWORD_ACTION";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
// export const GetCampaignDetailsList = (data) => {
//   // const path = `campaign-keyword-rank-data-list?page=${
//   const path = `campaign-keyword-rank-data-list?page=${
//     data?.page || 1
//   }&pageSize=${data?.perPage || 10}&start_date=${
//     data?.start_date || ""
//   }&end_date=${data?.end_date || ""}&advertised_asin=${
//     data?.asin || ""
//   }&campaignId=${data?.campaign_id || ""}&keywordText=${data?.keyword || ""}`;
//   const responseType = "GET_KEYWORD_DETAILS_LIST";
//   return axiosCall("get", path, responseType, null, {
//     Authorization: `Bearer ${localStorage.getItem("token")}`,
//   });
// };

export const GetKeywordASINList = (id) => {
  // const path = `advertised-asin-list`;
  const path = `search-asin-list?marketplace_id=${id}`;
  const responseType = "GET_KEYWORD_ASIN_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

// export const GetCampaignList = (data) => {
//   const path = `get-campaigns-list?advertised_asin=${
//     data?.advertised_asin
//   }&page=${data?.page || 1}&pageSize=${
//     data?.perPage || 20
//   }&searchCampaignName=${data?.searchCampaignName || ""}&searchCampaignStatus=${
//     data?.searchCampaignStatus || ""
//   }`;
//   const responseType = "GET_CAMPAIGN_LIST";
//   return axiosCall("get", path, responseType, null, {
//     Authorization: `Bearer ${localStorage.getItem("token")}`,
//   });
// };

export const GetMarketplaceByCred = () => {
  const path = `marketplaces/get-marketplace-by-credential`;
  const responseType = "GET_MARKETPLACE_BY_CRED";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionCampaignManagement = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_KEYWORD_MANAGEMENT", state: data });
};
