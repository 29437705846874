import { message } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";

export default function (props) {
  const { setSidebarStatus, sidebarStatus } = props;
  const history = useHistory();
  const location = useLocation();
  const [locationPath, setLocationPath] = useState([]);

  useEffect(() => {
    // console.log(location, "newLoca-----------");
    // const newLoca = location.pathname.split("/")?.[1]?.split("-")?.join(" ");
    const fullPath = location.pathname.split("/");
    setLocationPath(fullPath);
  }, [location]);

  // const backToAdmin = () => {
  //   let adminDataLocal = localStorage.getItem("adminData");
  //   if (adminDataLocal) {
  //     adminDataLocal = JSON.parse(adminDataLocal);
  //     localStorage.setItem("user", adminDataLocal.data);
  //     localStorage.setItem(
  //       "userType",
  //       JSON.parse(adminDataLocal?.data)?.user_type
  //     );
  //     localStorage.setItem("token", adminDataLocal.token);
  //     localStorage.removeItem("adminData");
  //   }
  // };

  return (
    <>
      <div
        id="kt_app_header"
        className="app-header"
        data-kt-sticky="true"
        data-kt-sticky-activate="{default: false, lg: true}"
        data-kt-sticky-name="app-header-sticky"
        data-kt-sticky-offset="{default: false, lg: '300px'}"
        style={{ position: "sticky" }}
      >
        <div
          className="app-container container-fluid d-flex flex-stack"
          id="kt_app_header_container"
        >
          <div
            className="d-flex align-items-center d-block d-lg-none ms-n3"
            title="Show sidebar menu"
          >
            <div
              className="btn btn-icon btn-active-color-primary w-35px h-35px me-2"
              id="kt_app_sidebar_mobile_toggle"
              onClick={() => setSidebarStatus(!sidebarStatus)}
            >
              <i className="ki-outline ki-abstract-14 fs-2" />
            </div>

            <img
              alt="Logo"
              src="assets/media/cropped-zonflip-favicon-1-180x180.png"
              className="h-40px theme-light-show"
            />
            <img
              alt="Logo"
              src="assets/media/cropped-zonflip-favicon-1-180x180.png"
              className="h-40px theme-dark-show"
            />
          </div>
          <div
            className="d-flex flex-stack flex-lg-row-fluid"
            id="kt_app_header_wrapper"
          >
            <div
              className="page-title gap-4 me-3 mb-5 mb-lg-0"
              data-kt-swapper="true"
              data-kt-swapper-mode="{default: 'prepend', lg: 'prepend'}"
              data-kt-swapper-parent="{default: '#kt_app_content_container', lg: '#kt_app_header_wrapper'}"
            >
              {/* <h1
                className="text-gray-100 fw-bolder m-0"
                style={{ textTransform: "capitalize" }}
              >
                {locationPath?.[1]?.split("-")?.join(" ")}
              </h1> */}
              <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-6 mb-0">
                {/* <li className="breadcrumb-item text-gray-200 fw-bold lh-1 mx-3">
                  |
                </li> */}
                <li className="breadcrumb-item text-gray-600 fw-bold lh-1">
                  <span className="text-gray-700 me-1">
                    <i className="ki-outline ki-home text-gray-200 fs-6" />
                  </span>
                </li>
                <li className="breadcrumb-item">
                  <i className="ki-outline ki-right fs-7 text-gray-300 mx-n1" />
                </li>
                <li
                  className="breadcrumb-item text-gray-200 fw-bold lh-1"
                  style={{ textTransform: "capitalize" }}
                >
                  {/* <Link
                    to={{
                      pathname: `/${locationPath?.[1]}`,
                    }}
                  > */}
                  <span className="text-gray-200 fw-bold lh-1">
                    {locationPath?.[1]?.split("-")?.join(" ") ===
                    "asin management"
                      ? "ASIN Management"
                      : locationPath?.[1]?.split("-")?.join(" ")}
                  </span>
                  {/* </Link> */}
                </li>
                {locationPath?.length >= 3 && (
                  <>
                    <li className="breadcrumb-item">
                      <i className="ki-outline ki-right fs-7 text-gray-300 mx-n1" />
                    </li>
                    <li
                      className="breadcrumb-item text-gray-200"
                      style={{ textTransform: "capitalize" }}
                    >
                      {locationPath?.[2]?.split("-")?.join(" ")}
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
          {/* <div>
            {localStorage.getItem("adminData") && (
              <>
                <button
                  className="btn ml-auto mr-10px"
                  id="btnBackToAdmin"
                  style={{ color: "#fff" }}
                  onClick={() => {
                    backToAdmin();
                    message.success("Success");
                    history.replace("/manage-user");
                  }}
                >
                  Back to admin
                </button>
              </>
            )}
          </div> */}
        </div>
      </div>
    </>
  );
}
