import React, { Suspense, useState } from "react";
import { Route, Switch } from "react-router-dom";
import Dashboard from "./containers/dashboard";
import AsinManagement from "./containers/campaign-management";
import CampaignAnalytics from "./containers/campaign-analytics";
import CampaignManagement from "./containers/keyword-tracker";
import CampaignDetails from "./containers/keyword-tracker/lib/campaign-details";
import CentralLog from "./containers/application-log/central-log";
import SystemErrorLog from "./containers/application-log/system-error-log";
import DataFrequencySetting from "./containers/application-log/data-frequency-setting";
// import ViewCampaign from "./containers/brand-management/view-campaign";
// import ViewKeyword from "./containers/brand-management/view-keyword";
// import ViewKeywordDetails from "./containers/brand-management/view-keyword-details";
import SettingMarketplace from "./containers/setting/marketplace";
import MarketplaceCredentials_ from "./containers/setting/marketplace-credentials";
import ManageUser from "./containers/admin/manage-user";
import AdvertisedProductReport from "./containers/sp-advertisement-report/advertised-product-report";
import KeywordsReport from "./containers/sp-advertisement-report/keywords-report";
import SearchTermReport from "./containers/sp-advertisement-report/search-term-report";
import Error from "./component/error";
function PageRouter(props) {
  const { userType } = props;
  return (
    <Suspense>
      {userType == 1 ? (
        <>
          <Switch>
            <Route path={"/manage-user"} component={ManageUser} exact />
            <Route path="*" render={() => <Error status={1} />} />
          </Switch>
        </>
      ) : (
        <>
          <Switch>
            <Route path={"/"} component={Dashboard} exact />
            <Route path={"/dashboard"} component={Dashboard} exact />
            <Route
              path={"/campaign-management"}
              component={AsinManagement}
              exact
            />
            {/* <Route
              path={"/brand-management/view-campaign"}
              component={ViewCampaign}
              exact
            />
            <Route
              path={"/brand-management/view-keyword"}
              component={ViewKeyword}
              exact
            />
            <Route
              path={"/brand-management/view-keyword-details"}
              component={ViewKeywordDetails}
              exact
            /> */}
            <Route
              path={"/campaign-analytics"}
              component={CampaignAnalytics}
              exact
            />
            <Route
              path={"/keyword-tracker"}
              component={CampaignDetails}
              exact
            />
            {/* <Route
              path={"/keyword-tracker/campaign-details"}
              component={CampaignDetails}
              exact
            /> */}
            <Route
              path={"/application-logs/central-log"}
              component={CentralLog}
              exact
            />
            <Route
              path={"/application-logs/system-error-log"}
              component={SystemErrorLog}
              exact
            />
            <Route
              path={"/application-logs/data-frequency-setting"}
              component={DataFrequencySetting}
              exact
            />
            <Route
              path={"/spapi-callback"}
              component={MarketplaceCredentials_}
              exact
            />
            <Route
              path={"/callback"}
              component={MarketplaceCredentials_}
              exact
            />
            <Route
              path={"/setting/marketplace"}
              component={MarketplaceCredentials_}
              exact
            />
            <Route
              path={"/advertisement-report/advertised-product-report"}
              component={AdvertisedProductReport}
              exact
            />
            <Route
              path={"/advertisement-report/keywords-report"}
              component={KeywordsReport}
              exact
            />
            <Route
              path={"/advertisement-report/search-term-report"}
              component={SearchTermReport}
              exact
            />
            <Route path="*" render={() => <Error />} />
          </Switch>
        </>
      )}
    </Suspense>
  );
}

export default PageRouter;
