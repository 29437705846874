import styled from "styled-components";

const ErrorWrapper = styled.div`
  display: grid;
  justify-content: center;
  justify-items: center;
  align-items: center;
  ${"" /* height: calc(100vh - 150px); */}
  height: 100%;

  .container404 {
    // width: auto;
    // margin: 100px auto;
  }

  .block {
    position: relative;
    box-sizing: border-box;
    float: left;
    margin: 7px;
    font-size: 6rem;
    color: #073d73;
    font-weight: 600;
    border-radius: 3px;
    text-shadow: rgb(7 61 115 / 38%) 4px 0px;
    // background: #fff;
  }

  // .block:nth-child(4n + 1) {
  //   animation: wave_23 2s ease 0s infinite;
  // }

  // .block:nth-child(4n + 2) {
  //   animation: wave_23 2s ease 0.2s infinite;
  // }

  // .block:nth-child(4n + 3) {
  //   animation: wave_23 2s ease 0.4s infinite;
  // }

  // .block:nth-child(4n + 4) {
  //   animation: wave_23 2s ease 0.6s infinite;
  //   margin-right: 0;
  // }

  // @keyframes wave_23 {
  //   0% {
  //     top: 0;
  //     // opacity: 1;
  //   }

  //   50% {
  //     top: 10px;
  //     // opacity: 0.2;
  //   }

  //   100% {
  //     top: 0;
  //     // opacity: 1;
  //   }
  // }

  // svg {
  //   position: absolute;

  //   top: 50%;

  //   left: 50%;

  //   margin-top: -250px;

  //   margin-left: -400px;
  // }

  p {
    color: #000;
  }

  .message-box {
    height: 200px;

    width: 380px;

    position: absolute;

    top: 50%;

    left: 50%;

    margin-top: -100px;

    margin-left: 50px;

    color: #fff;

    font-family: Roboto;

    font-weight: 300;
  }

  .message-box h1 {
    font-size: 60px;

    line-height: 46px;

    margin-bottom: 40px;
  }

  .buttons-con .action-link-wrap {
    margin-top: 40px;
  }

  .buttons-con .action-link-wrap a {
    background: #68c950;

    padding: 8px 25px;

    border-radius: 4px;

    color: #fff;

    font-weight: bold;

    font-size: 14px;

    transition: all 0.3s linear;

    cursor: pointer;

    text-decoration: none;

    margin-right: 10px;
  }

  .buttons-con .action-link-wrap a:hover {
    background: #5a5c6c;

    color: #fff;
  }

  #Polygon-1,
  #Polygon-2,
  #Polygon-3,
  #Polygon-4,
  #Polygon-4,
  #Polygon-5 {
    animation: float 1s infinite ease-in-out alternate;
  }

  #Polygon-2 {
    animation-delay: 0.2s;
  }

  #Polygon-3 {
    animation-delay: 0.4s;
  }

  #Polygon-4 {
    animation-delay: 0.6s;
  }

  #Polygon-5 {
    animation-delay: 0.8s;
  }

  @keyframes float {
    100% {
      transform: translateY(20px);
    }
  }

  @media (max-width: 450px) {
    svg {
      position: absolute;

      top: 50%;

      left: 50%;

      margin-top: -250px;

      margin-left: -190px;
    }

    .message-box {
      top: 50%;

      left: 50%;

      margin-top: -100px;

      margin-left: -190px;

      text-align: center;
    }
  }
`;

export default ErrorWrapper;
