import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ZoneFlipTable from "../../../../component/zonFlipTable";
import Pagination from "../../../../component/pagination";
import { DefaultPerPage } from "../../../../config";
import Loading from "../../../../component/loading";
import { SearchOutlined } from "@ant-design/icons";
import { Input, Select, Tag } from "antd";
import NoData from "../../../../component/no-data";
import moment from "moment";

export default function (props) {
  const location = useLocation();
  const {
    GetKeywordList,
    fakeActionBrandManagement,
    setTabStatus,
    selectedBread,
    setSelectedBread,
  } = props;
  const GetKeywordListRes = useSelector(
    (state) => state.AsinManagement.GetKeywordListResponse || {}
  );

  const [campaignId, setCampaignId] = useState("");
  const [campaignName, setCampaignName] = useState("");
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchCampaignStatus, setSearchCampaignStatus] = useState(null);
  const [searchKeywordType, setSearchKeywordType] = useState(null);

  useEffect(() => {
    // console.log("location", location);
    setCampaignId(selectedBread?.campaignId);
    setCampaignName(selectedBread?.campaignName);
  }, []);

  useEffect(() => {
    if (campaignId) {
      const data = {
        page: 1,
        perPage: pageSize,
        campaign_id: campaignId,
        searchKeywordName: searchKeyword,
        searchCampaignStatus: searchCampaignStatus,
        searchKeywordType: searchKeywordType,
      };
      GetKeywordList(data);
    }
  }, [campaignId]);

  useEffect(() => {
    if (GetKeywordListRes.status === true) {
      setList(GetKeywordListRes?.data?.records);
      console.log(GetKeywordListRes, "GetKeywordListResponse");
      setLoading(false);
      setTotalPage(GetKeywordListRes?.data?.pagination?.totalCount);
      fakeActionBrandManagement("GetKeywordListResponse");
    } else if (GetKeywordListRes.status === false) {
      setLoading(false);
      fakeActionBrandManagement("GetKeywordListResponse");
    }
  }, [GetKeywordListRes]);

  const onPageNo = (e) => {
    setLoading(true);
    const apiObj = {
      page: e,
      perPage: pageSize,
      campaign_id: campaignId,
      searchKeywordName: searchKeyword,
      searchCampaignStatus: searchCampaignStatus,
      searchKeywordType: searchKeywordType,
    };
    GetKeywordList(apiObj);
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    const apiObj = {
      page: 1,
      perPage: e,
      campaign_id: campaignId,
      searchKeywordName: searchKeyword,
      searchCampaignStatus: searchCampaignStatus,
      searchKeywordType: searchKeywordType,
    };
    GetKeywordList(apiObj);
  };

  const columns = [
    {
      title: "#",
      width: 100,
      ellipsis: true,
      align: "left",
      render: (text) => {
        return <span>{(page - 1) * pageSize + 1 + text.key}</span>;
      },
    },

    {
      title: "Name",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span style={{ fontWeight: "600" }}>{item?.keywordText}</span>
          </div>
        );
      },
    },
    {
      title: "Keyword Type",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>{item?.matchType}</span>
          </div>
        );
      },
    },
    {
      title: "Status",
      align: "left",
      render: (item) => {
        return (
          <div>
            <Tag
              color={
                item?.state?.toLowerCase() === "enabled"
                  ? "green"
                  : item?.state?.toLowerCase() === "paused"
                  ? "warning"
                  : item?.state?.toLowerCase() === "archived"
                  ? "blue"
                  : item?.state?.toLowerCase() === "user deleted"
                  ? "red"
                  : "red"
              }
            >
              {item?.state}
            </Tag>
          </div>
        );
      },
    },
    {
      title: "Last Change Date",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>
              {moment(new Date(item?.updated_at * 1000)).format(
                "MMM DD, YYYY "
              ) || "-"}
            </span>
          </div>
        );
      },
    },

    {
      title: "Action",
      align: "left",
      render: (item) => {
        return (
          <>
            <span
              onClick={() => {
                const selected_ = { ...selectedBread };
                selected_.keyword = item?.keywordText;
                selected_.kd_keyword_id = item?.keywordId;
                selected_.kd_keyword_name = item?.keywordText;
                selected_.kd_campaign_id = item?.campaignId;
                selected_.kd_campaign_name = item?.campaignName;
                setSelectedBread(selected_);
                setTabStatus("Keyword Details");
              }}
              className="btn btn-sm btn-primary"
            >
              View Keyword
            </span>
            {/* <Link
              to={{
                pathname: "/brand-management/view-keyword-details",
                state: {
                  keyword: item?.keyword,
                },
              }}
              className="btn btn-sm btn-primary"
            >
              View Details
            </Link> */}
          </>
        );
      },
    },
  ];

  const filter1 = [
    { label: "Enabled", value: "ENABLED" },
    { label: "Paused", value: "PAUSED" },
    { label: "Archived", value: "ARCHIVED" },
    { label: "User Deleted", value: "USER DELETED" },
  ];

  const filter2 = [
    { label: "Exact", value: "Exact" },
    { label: "Phrase", value: "Phrase" },
    { label: "Broad", value: "Broad" },
    { label: "Target", value: "TARGETING_EXPRESSION_PREDEFINED" },
  ];

  return (
    <>
      <div className="fadeInRight">
        {/*begin::Row*/}

        <div className="row gy-5 g-xl-5 mt-0">
          {/*begin::Col*/}
          <div className="col-xxl-12 mt-0">
            <div
              className="d-flex justify-content-between align-items-center gap-3 mb-3"
              style={{
                background: "#21d9591f",
                padding: "10px 0px",
                borderRadius: "7px",
              }}
            >
              <div className="position-relative mx-5 d-flex align-items-center">
                <button
                  onClick={() => {
                    setTabStatus("View Campaign");
                  }}
                  class="btn btn-primary btn-icon me-3"
                  style={{ height: "30px", width: "30px" }}
                >
                  <i class="bi bi-arrow-left-short fs-1"></i>
                </button>
                <span className="me-1 d-flex align-items-center">
                  <Link
                    to={{
                      pathname: "/dashboard",
                    }}
                  >
                    <i className="ki-outline ki-home fs-6 d-flex" />
                  </Link>
                </span>
                <span className="me-1 d-flex align-items-center">
                  <i className="ki-outline ki-right fs-7" />
                </span>
                <span
                  onClick={() => {
                    setTabStatus("Asin Management");
                  }}
                  style={{ cursor: "pointer", color: "#5e5e5e" }}
                >
                  ASIN Management
                </span>
                <span className="me-1 d-flex align-items-center">
                  <i className="ki-outline ki-right fs-7" />
                </span>
                <span
                  onClick={() => {
                    setTabStatus("View Campaign");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  View Campaign
                </span>
                <span className="me-1 d-flex align-items-center">
                  <i className="ki-outline ki-right fs-7" />
                </span>
                <span className="fw-bold">View Keyword</span>
              </div>
              <div className="me-2">
                <label className="fw-bold fs-6 d-flex">
                  <img
                    src={`/media/domainImage/${selectedBread?.marketplace_name}.png`}
                    style={{ height: "20px" }}
                    className="me-2"
                  />
                  {selectedBread?.marketplace_name}
                </label>
              </div>
            </div>
            {/*begin::Tables Widget 9*/}
            <div className="card card-xxl-stretch mb-5 mb-xl-8">
              {/*begin::Header*/}
              <div className="card-header border-bottom-dashed">
                {/*end::Title*/}
                {/*begin::Title*/}
                <h3 className="card-title ">
                  <Tag color="purple">Campaign Name: {campaignName}</Tag>
                  {/* <span className="card-label fw-bold text-gray-900">
                    {campaignName}
                  </span> */}
                </h3>
                {/*end::Title*/}
                <div className="card-toolbar">
                  <div className="position-relative mr-4">
                    <Input
                      type="text"
                      size="large"
                      placeholder="Search for Keyword"
                      className="ant_common_input fs-6 fw-bold"
                      prefix={
                        <SearchOutlined
                          style={{ color: "#a1a5b7", fontSize: "18px" }}
                        />
                      }
                      onChange={(e) => {
                        setSearchKeyword(e.target.value);
                      }}
                      onPressEnter={() => {
                        setLoading(true);
                        const data = {
                          page: 1,
                          perPage: DefaultPerPage,
                          campaign_id: campaignId,
                          searchKeywordName: searchKeyword,
                          searchCampaignStatus: searchCampaignStatus,
                          searchKeywordType: searchKeywordType,
                        };
                        GetKeywordList(data);
                      }}
                    />
                  </div>
                  <div className="position-relative ms-3">
                    {/* <select class="form-select fs-7 w-md-200px" data-control="select2" data-placeholder="Select Option" data-hide-search="true"> */}
                    <Select
                      // value={1}
                      placeholder="Select Option"
                      size="large"
                      style={{ width: "130px" }}
                      className="fs-7"
                      allowClear
                      showSearch
                      options={filter1?.map((d, i) => {
                        return { value: d?.value, label: d?.label };
                      })}
                      onChange={(e) => {
                        setLoading(true);
                        const data = {
                          page: 1,
                          perPage: DefaultPerPage,
                          campaign_id: campaignId,
                          searchKeywordName: searchKeyword,
                          searchCampaignStatus: e,
                          searchKeywordType: searchKeywordType,
                        };
                        GetKeywordList(data);
                      }}
                    />
                  </div>
                  <div className="position-relative ms-3">
                    {/* <select class="form-select fs-7 w-md-200px" data-control="select2" data-placeholder="Select Option" data-hide-search="true"> */}
                    <Select
                      // value={1}
                      placeholder="Select Option"
                      size="large"
                      style={{ width: "130px" }}
                      className="fs-7"
                      allowClear
                      showSearch
                      options={filter2?.map((d, i) => {
                        return { value: d?.value, label: d?.label };
                      })}
                      onChange={(e) => {
                        setLoading(true);
                        const data = {
                          page: 1,
                          perPage: DefaultPerPage,
                          campaign_id: campaignId,
                          searchKeywordName: searchKeyword,
                          searchCampaignStatus: searchCampaignStatus,
                          searchKeywordType: e,
                        };
                        GetKeywordList(data);
                      }}
                    />
                  </div>
                </div>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              <div className="card-body pb-5 pt-0">
                {/*begin::Table container*/}
                <div className="table-responsive mt-5">
                  {loading ? (
                    <Loading />
                  ) : list?.length !== 0 ? (
                    <ZoneFlipTable
                      columns={columns}
                      fixed="top"
                      dataSource={list}
                      rowKey="key"
                      loading={loading}
                      pagination={false}
                      scroll={{ x: "max-content" }}
                    />
                  ) : (
                    <NoData />
                  )}
                </div>
                <Pagination
                  loading={loading || list?.length === 0}
                  pageSize={pageSize}
                  page={page}
                  totalPage={totalPage}
                  onPerPage={onPerPage}
                  onPageNo={onPageNo}
                />

                {/*end::Table container*/}
                {/*end::Table container*/}
              </div>
              {/*begin::Body*/}
            </div>
            {/*end::Tables Widget 9*/}
          </div>
          {/*end::Col*/}
        </div>
        {/*end::Row*/}
      </div>
    </>
  );
}
