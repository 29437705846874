import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DataFrequencySetting from "../../../modules/pages/application-log/data-frequency-setting";
import {
  GetDataFrequencySettingList,
  fakeActionDataFrequencySetting,
} from "../../../redux/modules/application-log/data-frequency-setting/data-frequency-setting.action";

const mapStateToProps = (state) => ({
  GetDataFrequencySettingListResponse:
    state.DataFrequencySetting.GetDataFrequencySettingListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetDataFrequencySettingList,
      fakeActionDataFrequencySetting,
    },
    dispatch
  );

const DataFrequencySettingZonFlip = connect(
  mapStateToProps,
  mapDispatchToProps
)(DataFrequencySetting);

export default DataFrequencySettingZonFlip;
