import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Dashboard from "../../modules/pages/dashboard";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const DashboardZonFlip = connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);

export default DashboardZonFlip;
