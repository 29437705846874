import React, { useEffect, useState } from "react";
import { Redirect, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Carousel, Input, message } from "antd";

export default function (props) {
  const { ForgotPasswordAction, fakeActionAuth } = props;

  const [isValidPass, setIsValidPass] = useState(true);
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [sending, setSending] = useState("");
  const [isRedirect, setIsRedirect] = useState(false);

  const ForgotPasswordRes = useSelector(
    (state) => state.Auth.ForgotPasswordResponse || {}
  );

  const onLogin = (e) => {
    e.preventDefault();
    if (username && password) {
      setSending(true);
      // message.destroy();
      // message.loading("Loading...", 0);
      let body = {
        email: username,
        password: password,
      };
      ForgotPasswordAction(body);
    }
  };

  useEffect(() => {
    if (ForgotPasswordRes?.status) {
      setSending(false);
      message.destroy();
      message.success(ForgotPasswordRes?.message);
      setIsRedirect(true);
      fakeActionAuth("ForgotPasswordResponse");
    } else if (Object.keys(ForgotPasswordRes).length !== 0) {
      setSending(false);
      message.destroy();
      message.error(ForgotPasswordRes?.message);
      fakeActionAuth("ForgotPasswordResponse");
    }
  }, [ForgotPasswordRes]);

  const handleValidConfirmPass = (e) => {
    setConfirmPassword(e.target.value);
    if (e.target.value == password) {
      setIsValidPass(true);
    } else {
      setIsValidPass(false);
    }
  };

  if (isRedirect) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      <>
        <div
          className="row px-0 mx-0"
          style={{
            height: "100%",
            backgroundImage: " linear-gradient(293deg, #073d73, transparent)",
          }}
        >
          <div
            className="col-12 col-md-5 px-0 mx-0 d-flex justify-content-center"
            // style={{ background: "#d7dee5", flexDirection: "column" }}
            style={{ flexDirection: "column" }}
          >
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ flexDirection: "column", flex: "1 auto" }}
            >
              <div className="mb-5">
                <img
                  loading="lazy"
                  src="/media/zonflip-logo.png"
                  className="h-70px shimmer"
                  alt=""
                />
              </div>
              <h1
                className="fs-2qx pb-5 pb-md-4 fw-normal"
                style={{ color: "rgb(73, 73, 81)" }}
              >
                Welcome to
                <b className="fw-boldest" style={{ color: "#073d73" }}>
                  ZonFlip
                </b>
              </h1>
            </div>
            {/* <div
              className="d-flex justify-content-center align-items-center"
              style={{ flexDirection: "column", flex: "0 auto" }}
            > */}
            <div className="authFadeInTop  justify-content-center align-items-center d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px">
              <img
                // src="/media/Projections-rafiki.svg"
                src="/media/Growth-analytics-rafiki.svg"
                style={{ objectFit: "contain", width: "65%" }}
                className="mx-auto"
              />
            </div>
          </div>
          <div
            className="col-12 col-md-7 px-0 mx-0 d-flex"
            style={{ background: "" }}
          >
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ flex: "1 auto" }}
            >
              <div
                className="row mx-0"
                style={{
                  width: "500px",
                  padding: "70px",
                  background: "#eaedf0",
                  // clipPath: "polygon(0 0, 100% 0, 100% 80%, 80% 100%, 0 100%)",
                  clipPath:
                    "polygon(0% 15%, 0 0, 15% 0%, 85% 0%, 100% 15%, 100% 15%, 100% 85%, 100% 100%, 85% 100%, 15% 100%, 15% 100%, 0% 85%)",
                }}
              >
                <div className="col-12 px-0 mx-0 d-flex justify-content-center">
                  <h1 className="text-dark fw-bold mb-3">
                    Forgot Password ?
                    {/* <b className="fw-boldest" style={{ color: "#073d73" }}>
                      ZonFlip
                    </b> */}
                  </h1>
                </div>
                <div className="col-12 px-0 mx-0 text-center">
                  <label className="text-gray-400 fw-semibold fs-6">
                    Gain more control over your Amazon growth potential
                  </label>
                </div>
                <div className="col-12 px-0 mx-0 mt-20">
                  <Input
                    type="text"
                    placeholder="Email"
                    name="email"
                    autoComplete="on"
                    data-kt-translate="sign-in-input-email"
                    className="form-control"
                    value={username}
                    onChange={(e) => setUserName(e.target.value)}
                    style={{
                      backgroundColor: "#e8f0fe",
                      border: "1px solid #ccd5e0",
                    }}
                  />
                </div>
                <div className="col-12 px-0 mx-0 mt-3">
                  <Input
                    type="password"
                    placeholder="Password"
                    name="password"
                    autoComplete="off"
                    data-kt-translate="sign-in-input-password"
                    className="form-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    style={{
                      backgroundColor: "#e8f0fe",
                      border: "1px solid #ccd5e0",
                    }}
                  />
                </div>
                <div className="col-12 px-0 mx-0 mt-3">
                  <Input
                    type="password"
                    placeholder="Confirm Password"
                    name="password"
                    autoComplete="off"
                    data-kt-translate="sign-in-input-password"
                    className="form-control"
                    value={confirmPassword}
                    onChange={handleValidConfirmPass}
                    style={{
                      backgroundColor: "#e8f0fe",
                      border: "1px solid #ccd5e0",
                    }}
                  />
                  {!isValidPass && (
                    <span style={{ color: "red" }}>
                      The new password that you entered do not match!
                    </span>
                  )}
                </div>
                {/* <div className="col-12 px-0 mx-0 mt-1 text-end">
                  <a
                    href
                    className="link-primary"
                    data-kt-translate="sign-in-forgot-password"
                  >
                    Forgot Password ?
                  </a>
                </div> */}
                <div className="col-12 px-0 mx-0 mt-5 text-center">
                  <button
                    id="kt_sign_in_submit"
                    className="btn btn-primary me-2 flex-shrink-0 btn-lg h-50px"
                    onClick={onLogin}
                    disabled={
                      sending ||
                      !username ||
                      !password ||
                      !confirmPassword ||
                      !isValidPass
                    }
                  >
                    {!sending && (
                      <span
                        className="indicator-label"
                        data-kt-translate="sign-in-submit"
                      >
                        Submit
                      </span>
                    )}
                    {sending && (
                      <span>
                        <span data-kt-translate="general-progress">
                          Please wait...
                        </span>
                        <span className="spinner-border spinner-border-sm align-middle ms-2" />
                      </span>
                    )}
                  </button>
                </div>
                <div className="col-12 px-0 mx-0 mt-5 text-center">
                  <div className="text-gray-400 fs-4">
                    Already have an account?
                    <Link to="/login" className="link-primary ms-2 fw-bold ">
                      Sign in here
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>

      {/* -------------------------------------------------------------------------------------------Old Code  */}
    </>
  );
}
