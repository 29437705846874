import React, { useEffect, useState } from "react";
import { Wrapper } from "./style";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ZoneFlipTable from "../../../component/zonFlipTable";
import { DefaultPerPage } from "../../../config";
import Pagination from "../../../component/pagination";
import Loading from "../../../component/loading";
import { Input, Select, Tag } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import NoData from "../../../component/no-data";
import moment from "moment";

export default function (props) {
  const { GetCampaignManagementList, fakeActionCampaignManagement } = props;
  const GetCampaignManagementListRes = useSelector(
    (state) => state.CampaignManagement.GetCampaignManagementListResponse || {}
  );

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);
  const [searchCampaign, setSearchCampaign] = useState("");
  const [searchCampaignStatus, setSearchCampaignStatus] = useState(null);

  useEffect(() => {
    const apiObj = {
      page: 1,
      perPage: pageSize,
      campaign_name: searchCampaign,
      status: searchCampaignStatus,
      // search_value: searchAsin,
    };
    GetCampaignManagementList(apiObj);
  }, []);

  useEffect(() => {
    if (GetCampaignManagementListRes.status === true) {
      setList(GetCampaignManagementListRes?.data?.records);
      console.log(GetCampaignManagementListRes, "GetCampaignManagementListRes");
      setLoading(false);
      setTotalPage(GetCampaignManagementListRes?.data?.pagination?.totalCount);
      fakeActionCampaignManagement("GetCampaignManagementListResponse");
    } else if (GetCampaignManagementListRes.status === false) {
      setLoading(false);
      fakeActionCampaignManagement("GetCampaignManagementListResponse");
    }
  }, [GetCampaignManagementListRes]);

  const columns = [
    {
      title: "#",
      width: 100,
      ellipsis: true,
      align: "left",
      render: (text) => {
        return <span>{(page - 1) * pageSize + 1 + text.key}</span>;
      },
    },

    {
      title: "Campaign Name",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span style={{ fontWeight: "600" }}>{item?.campaignName}</span>
          </div>
        );
      },
    },
    {
      title: "Campaign Id",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span style={{ fontWeight: "600" }}>{item?.campaignId}</span>
          </div>
        );
      },
    },
    {
      title: "Status",
      align: "left",
      render: (item) => {
        return (
          <div>
            <Tag
              color={
                item?.campaignStatus?.toLowerCase() === "enabled"
                  ? "green"
                  : item?.campaignStatus?.toLowerCase() === "paused"
                  ? "warning"
                  : item?.campaignStatus?.toLowerCase() === "archived"
                  ? "blue"
                  : item?.campaignStatus?.toLowerCase() === "user deleted"
                  ? "red"
                  : "red"
              }
            >
              {item?.campaignStatus}
            </Tag>
          </div>
        );
      },
    },
    // {
    //   title: "Date",
    //   align: "left",
    //   render: (item) => {
    //     return (
    //       <div>
    //         <span>
    //           {moment(new Date(item?.created_at * 1000)).format(
    //             "MMM DD, YYYY "
    //           )}
    //         </span>
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Rank",
    //   align: "left",
    //   render: (item) => {
    //     return (
    //       <div>
    //         <span>{item?.rank || "-"}</span>
    //       </div>
    //     );
    //   },
    // },
    {
      title: "Action",
      align: "left",
      render: (item) => {
        return (
          <>
            {/* <span className="btn btn-sm btn-primary">
              View Campaign Details
            </span> */}
            <Link
              to={{
                pathname: "/keyword-tracker/campaign-details",
                // search: `campaignName=${item?.name}&campaignId=${item?.campaignId}`,
                state: {
                  campaignName: item?.campaignName,
                  campaignId: item?.campaignId,
                },
              }}
              // target={"blank"}
              className="btn btn-sm btn-primary"
            >
              View Keyword
            </Link>
          </>
        );
      },
    },
  ];

  const onPageNo = (e) => {
    setLoading(true);
    const apiObj = {
      page: e,
      perPage: pageSize,
      campaign_name: searchCampaign,
      status: searchCampaignStatus,
    };
    GetCampaignManagementList(apiObj);
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    const apiObj = {
      page: 1,
      perPage: e,
      campaign_name: searchCampaign,
      status: searchCampaignStatus,
    };
    GetCampaignManagementList(apiObj);
  };

  const filter1 = [
    { label: "Enabled", value: "ENABLED" },
    { label: "Paused", value: "PAUSED" },
    { label: "Archived", value: "ARCHIVED" },
    { label: "User Deleted", value: "USER DELETED" },
  ];

  return (
    <div className="fadeInRight">
      <>
        {/*begin::Row*/}
        <div className="row gy-5 g-xl-5">
          {/*begin::Col*/}

          <div className="col-xxl-12">
            {/*begin::Tables Widget 9*/}
            <div className="card card-xxl-stretch mb-5 mb-xl-8">
              {/*begin::Header*/}
              <div className="card-header border-bottom-dashed">
                {/*end::Title*/}
                {/*begin::Title*/}
                <h3 className="card-title ">
                  <span className="card-label fw-bold text-gray-900">
                    Campaign List
                  </span>
                </h3>
                {/*end::Title*/}
                <div className="card-toolbar">
                  <div className="position-relative me-4">
                    <Input
                      type="text"
                      size="large"
                      placeholder="Search for Campaign"
                      className="ant_common_input fs-6 fw-bold"
                      prefix={
                        <SearchOutlined
                          style={{ color: "#a1a5b7", fontSize: "18px" }}
                        />
                      }
                      onChange={(e) => {
                        setSearchCampaign(e.target.value);
                      }}
                      onPressEnter={() => {
                        setLoading(true);
                        setPage(1);
                        setPageSize(DefaultPerPage);
                        const apiObj = {
                          page: 1,
                          perPage: DefaultPerPage,
                          campaign_name: searchCampaign,
                          status: searchCampaignStatus,
                        };
                        GetCampaignManagementList(apiObj);
                      }}
                    />
                  </div>
                  <div className="position-relative">
                    <Select
                      placeholder="Select Option"
                      size="large"
                      style={{ width: "130px" }}
                      className="fs-7"
                      allowClear
                      value={searchCampaignStatus}
                      showSearch
                      options={filter1?.map((d, i) => {
                        return { value: d?.value, label: d?.label };
                      })}
                      onChange={(e) => {
                        setSearchCampaignStatus(e);
                        setLoading(true);
                        setPage(1);
                        setPageSize(DefaultPerPage);
                        const apiObj = {
                          page: 1,
                          perPage: DefaultPerPage,
                          campaign_name: searchCampaign,
                          status: e,
                        };
                        GetCampaignManagementList(apiObj);
                      }}
                    />
                  </div>
                </div>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              <div className="card-body pb-5 pt-0">
                {/*begin::Table container*/}
                <div className="table-responsive mt-5">
                  {loading ? (
                    <Loading />
                  ) : list?.length !== 0 ? (
                    <ZoneFlipTable
                      columns={columns}
                      fixed="top"
                      dataSource={list}
                      rowKey="key"
                      loading={loading}
                      pagination={false}
                      scroll={{ x: "max-content" }}
                    />
                  ) : (
                    <NoData />
                  )}
                </div>
                <Pagination
                  loading={loading || list?.length === 0}
                  pageSize={pageSize}
                  page={page}
                  totalPage={totalPage}
                  onPerPage={onPerPage}
                  onPageNo={onPageNo}
                />
              </div>
              {/*begin::Body*/}
            </div>
            {/*end::Tables Widget 9*/}
          </div>
          {/*end::Col*/}
        </div>
        {/*end::Row*/}
      </>
    </div>
  );
}
