import React, { useEffect, useState } from "react";
// import { Wrapper } from "./style";
import moment from "moment";
import { useSelector } from "react-redux";
import Loading from "../../../../component/loading";
import NoData from "../../../../component/no-data";
import Pagination from "../../../../component/pagination";
import ZoneFlipTable from "../../../../component/zonFlipTable";
import { DefaultPerPage } from "../../../../config";

export default function (props) {
  const { GetDataFrequencySettingList, fakeActionDataFrequencySetting } = props;
  const GetDataFrequencySettingListRes = useSelector(
    (state) =>
      state.DataFrequencySetting.GetDataFrequencySettingListResponse || {}
  );

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);

  useEffect(() => {
    const apiObj = {
      page: 1,
      perPage: pageSize,
    };
    GetDataFrequencySettingList(apiObj);
    return () => {};
  }, []);

  useEffect(() => {
    if (GetDataFrequencySettingListRes.status === true) {
      setList(GetDataFrequencySettingListRes?.data?.records);
      console.log(
        GetDataFrequencySettingListRes,
        "GetDataFrequencySettingListRes"
      );
      setLoading(false);
      setTotalPage(
        GetDataFrequencySettingListRes?.data?.pagination?.totalCount
      );
      fakeActionDataFrequencySetting("GetDataFrequencySettingListResponse");
    } else if (GetDataFrequencySettingListRes.status === false) {
      setLoading(false);
      fakeActionDataFrequencySetting("GetDataFrequencySettingListResponse");
    }
  }, [GetDataFrequencySettingListRes]);

  const columns = [
    {
      title: "#",
      ellipsis: true,
      align: "left",
      render: (text) => {
        return <span>{(page - 1) * pageSize + 1 + text.key}</span>;
      },
    },

    {
      title: "Event Process Type",
      align: "left",
      render: (item) => {
        return (
          <span className="fw-bold fs-7">
            {item?.event_process_type || "-"}
          </span>
        );
      },
    },
    {
      title: "Event Type",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span className="fw-bold fs-7 ">{item?.event_type || "-"}</span>
          </div>
        );
      },
    },
    {
      title: "Event Name",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>{item?.event_name}</span>
          </div>
        );
      },
    },
    {
      title: "Daily Frequency",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>{item?.daily_frequency}</span>
          </div>
        );
      },
    },
    {
      title: "Frequency Status",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>{item?.frequency_status}</span>
          </div>
        );
      },
    },
    {
      title: "Previous Days",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>{item?.previous_days}</span>
          </div>
        );
      },
    },
    {
      title: "Previous Status",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>{item?.previous_status}</span>
          </div>
        );
      },
    },
    {
      title: "Update Daily Days",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>{item?.update_daily_days}</span>
          </div>
        );
      },
    },
    {
      title: "Update Daily Status",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>{item?.update_daily_status}</span>
          </div>
        );
      },
    },
    {
      title: "Marketplace",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>{item?.marketplace}</span>
          </div>
        );
      },
    },
    {
      title: "Marketplace ID",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>{item?.marketplace_id}</span>
          </div>
        );
      },
    },
    {
      title: "Last Updated",
      align: "left",
      render: (item) => {
        return (
          <div>
            <span>
              {moment(new Date(item?.updated_at * 1000)).format(
                "MMM DD, YYYY hh:mm A"
              )}
            </span>
          </div>
        );
      },
    },
  ];

  const onPageNo = (e) => {
    setLoading(true);
    const apiObj = {
      page: e,
      perPage: pageSize,
    };
    GetDataFrequencySettingList(apiObj);
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    const apiObj = {
      page: 1,
      perPage: e,
    };
    GetDataFrequencySettingList(apiObj);
  };

  return (
    <>
      <>
        {/*begin::Row*/}
        <div className="row gy-5 g-xl-5 fadeInRight">
          {/*begin::Col*/}
          <div className="col-xxl-12">
            {/*begin::Tables Widget 9*/}
            <div className="card card-xxl-stretch mb-5 mb-xl-8">
              {/*begin::Header*/}
              <div className="card-header border-bottom-dashed">
                {/*end::Title*/}
                {/*begin::Title*/}
                <h3 className="card-title ">
                  <span className="card-label fw-bold text-gray-900">
                    Data Frequency Setting
                  </span>
                </h3>
                {/*end::Title*/}
                <div className="card-toolbar">
                  <div className="position-relative mr-4"></div>
                  <div className="position-relative ms-3"></div>
                </div>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              <div className="card-body pb-5 pt-0">
                {/*begin::Table container*/}
                <div className="table-responsive mt-5">
                  {loading ? (
                    <Loading />
                  ) : list?.length != 0 ? (
                    <>
                      <ZoneFlipTable
                        columns={columns}
                        fixed="top"
                        dataSource={list}
                        rowKey="key"
                        loading={loading}
                        pagination={false}
                        scroll={{ x: "max-content" }}
                      />
                    </>
                  ) : (
                    <NoData />
                  )}
                </div>
                <Pagination
                  loading={loading || list?.length === 0}
                  pageSize={pageSize}
                  page={page}
                  totalPage={totalPage}
                  onPerPage={onPerPage}
                  onPageNo={onPageNo}
                />
              </div>
              {/*begin::Body*/}
            </div>
            {/*end::Tables Widget 9*/}
          </div>
          {/*end::Col*/}
        </div>
        {/*end::Row*/}
      </>
    </>
  );
}
