import React, { useState } from "react";
import { Wrapper } from "./style";
import Chart from "react-apexcharts";
import { Select } from "antd";
import RangePickerZonFlip from "../../../component/range-picker";
import { DateFormat } from "../../../config";
import dayjs from "dayjs";
export default function () {
  const [filters, setFilters] = useState({
    start_date: dayjs().add(-30, "d"),
    end_date: dayjs(),
  });

  const options = {
    chart: {
      height: 350,
      type: "area",
      toolbar: { show: !1 },
    },
    plotOptions: {},
    legend: { show: !1 },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.4,
        opacityTo: 0.1,
        stops: [10, 40, 100],
      },
    },
    stroke: {
      curve: "straight",
      show: !0,
      width: 2,
      //   colors: [o, s, d],
    },
    xaxis: {
      //   type: 'datetime',
      categories: [
        "",
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
        "",
      ],
      axisBorder: { show: !1 },
      axisTicks: { show: !1 },
      tickAmount: 6,

      crosshairs: {
        position: "front",
        stroke: { color: [], width: 1, dashArray: 3 },
      },
      tooltip: {
        enabled: !0,
        formatter: void 0,
        offsetY: 0,
        style: { fontSize: "12px" },
      },
    },
    labels: {
      rotate: 0,
      rotateAlways: !0,
      style: { colors: "#ddd", fontSize: "12px", fontWeight: "bolder" },
    },
    yaxis: {
      tickAmount: 6,
      labels: { style: { colors: "#000", fontSize: "12px" } },
    },
    states: {
      normal: { filter: { type: "none", value: 0 } },
      hover: { filter: { type: "none", value: 0 } },
      active: {
        allowMultipleDataPointsSelection: !1,
        filter: { type: "none", value: 0 },
      },
    },
    tooltip: { style: { fontSize: "12px" } },
    colors: ["#21D959", "#000", "#073D73"],
    grid: {
      borderColor: "#ddd",
      strokeDashArray: 4,
      yaxis: { lines: { show: !0 } },
    },
    // markers: { strokeColor: [], strokeWidth: 3 },
  };

  const series = [
    {
      name: "Spend",
      data: [65, 80, 60, 60, 45, 80, 80, 70, 90, 80, 80, 60],
    },
    {
      name: "Sales",
      data: [90, 110, 95, 85, 95, 115, 100, 115, 95, 95, 85, 85],
    },
    {
      name: "ACOS",
      data: [75, 90, 70, 55, 75, 80, 88, 88, 65, 65, 50, 50],
    },
  ];

  const filter1 = [
    { name: "ACOS", value: 1 },
    { name: "ROAS", value: 2 },
    { name: "Spend", value: 3 },
    { name: "Sales", value: 4 },
    { name: "CPC", value: 5 },
    { name: "Conversion Rate", value: 6 },
    { name: "Orders", value: 7 },
    { name: "Clicks", value: 8 },
    { name: "Impressions", value: 9 },
    { name: "CTR", value: 10 },
    { name: "Ad Sales Percentage", value: 11 },
    { name: "Total Sales", value: 12 },
    { name: "Total Orders", value: 13 },
    { name: "Total ACOS", value: 14 },
  ];

  const filter2 = [
    { label: "Sponsored Product", value: "sponsored-product" },
    { label: "Sponsored Brands", value: "sponsored-brands" },
    { label: "Sponsored Brand Video", value: "sponsored-brand-video" },
    { label: "Sponsored Display", value: "sponsored-display" },
  ];

  const campaignData = {
    performanceData: [
      {
        title: "Spend",
        value: "559.5K",
        percentage: "+8.8%",
        icon: "ki-arrow-up",
        iconColor: "text-dark",
        amount: "$592.6K",
      },
      {
        title: "Sales",
        value: "4.5M",
        percentage: "+5.8%",
        icon: "ki-arrow-up",
        iconColor: "text-success",
        amount: "$4.9M",
      },
      {
        title: "ACOS",
        value: "12.40%",
        percentage: "+0.1%",
        icon: "ki-arrow-up",
        iconColor: "text-warning",
        amount: "12.42%",
      },
    ],
    conversionData: [
      {
        title: "Clicks",
        value: "783.6K",
        percentage: "+4.3%",
        icon: "ki-arrow-up",
        iconColor: "text-success",
        amount: "816.8K",
      },
      {
        title: "Orders",
        value: "85.1K",
        percentage: "+13.5%",
        icon: "ki-arrow-up",
        iconColor: "text-success",
        amount: "96.6K",
      },
      {
        title: "CVR",
        value: "10.86%",
        percentage: "+8.8%",
        icon: "ki-arrow-up",
        iconColor: "text-success",
        amount: "11.82%",
      },
    ],
    efficiencyData: [
      {
        title: "CPC",
        value: "$0.71",
        percentage: "+1.5%",
        icon: "ki-arrow-up",
        iconColor: "text-warning",
        amount: "$0.73",
      },
      {
        title: "SPC",
        value: "$5.8",
        percentage: "+1.4%",
        icon: "ki-arrow-up",
        iconColor: "text-success",
        amount: "$5.8",
      },
      {
        title: "CTR",
        value: "0.31%",
        percentage: "+5.5%",
        icon: "ki-arrow-up",
        iconColor: "text-success",
        amount: "0.33%",
      },
    ],
    activeCampaignData: [
      {
        title: "SP",
        value: "916",
        percentage: "-7.4%",
        icon: "ki-arrow-up",
        iconColor: "text-warning",
        amount: "890",
      },
      {
        title: "SB",
        value: "445",
        percentage: "-6.1%",
        icon: "ki-arrow-up",
        iconColor: "text-warning",
        amount: "418",
      },
      {
        title: "SD",
        value: "156",
        percentage: "+3.8%",
        icon: "ki-arrow-up",
        iconColor: "text-warning",
        amount: "162",
      },
    ],
    targetingData: [
      {
        title: "Active Keywords",
        value: "77787",
        percentage: "+9.1%",
        icon: "ki-arrow-down",
        iconColor: "text-warning",
        amount: "70706",
      },
      {
        title: "New Keywords",
        value: "1336",
        percentage: "-82.9%",
        icon: "ki-arrow-down",
        iconColor: "text-danger",
        amount: "229",
      },
      {
        title: "Active PAT",
        value: "23887",
        percentage: "-9.4%",
        icon: "ki-arrow-down",
        iconColor: "text-warning",
        amount: "21198",
      },
      {
        title: "New PAT",
        value: "513",
        percentage: "+62.8%",
        icon: "ki-arrow-up",
        iconColor: "text-success",
        amount: "835",
      },
      {
        title: "Active Audience",
        value: "409",
        percentage: "--",
        icon: "",
        iconColor: "text-dark",
        amount: "409",
      },
      {
        title: "New Audience",
        value: "12",
        percentage: "+83.3%",
        icon: "ki-arrow-up",
        iconColor: "text-success",
        amount: "22",
      },
    ],
  };

  return (
    <Wrapper className="fadeInRight">
      <>
        <div className>
          <div className>
            <div
              className="row mx-0 mb-3 mt-0"
              style={{
                background: "#fff",
                padding: "13px 6px",
                borderRadius: "7px",
              }}
            >
              <div className="col-md-2 ">
                <div className="position-relative">
                  <Select
                    // value={1}
                    placeholder="Select Option"
                    size="large"
                    style={{ width: "100%" }}
                    className="fs-7"
                    allowClear
                    showSearch
                    options={filter1?.map((d, i) => {
                      return { value: d?.value, label: d?.name };
                    })}
                  />
                </div>
              </div>
              <div className="col-md-2 ">
                <div className="position-relative">
                  <Select
                    // value={1}
                    placeholder="Select Option"
                    size="large"
                    style={{ width: "100%" }}
                    className="fs-7"
                    allowClear
                    showSearch
                    options={filter2?.map((d, i) => {
                      return { value: d?.value, label: d?.label };
                    })}
                  />
                </div>
              </div>
              <div className="col-md-3 ">
                {/*begin::Daterangepicker(defined in src/js/layout/app.js)*/}
                <div className="position-relative">
                  <RangePickerZonFlip
                    className="ant_common_input"
                    size="large"
                    id="campaign_range_id"
                    allowClear={false}
                    style={{ width: "290px" }}
                    format={DateFormat}
                    value={[filters?.start_date, filters?.end_date]}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    // disabled={chartLoading}
                    onChange={(e) => {
                      const filters_ = filters;
                      filters_.start_date = e?.[0];
                      filters_.end_date = e?.[1];
                      setFilters({ ...filters_ });
                    }}
                  />
                  {/*end::Daterangepicker*/}
                </div>
              </div>
            </div>

            {/* Header End  */}

            <div className="row gx-5 gx-xl-5">
              {/*begin::Col*/}
              <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-5 mb-xl-5">
                <div className="card card-flush h-lg-100">
                  {/*begin::Body*/}
                  <div className="card-body pt-4 pb-4 ">
                    <div
                      className=" rounded mb-3"
                      style={{
                        background:
                          "linear-gradient(90deg, #073d73, transparent)",
                        color: "#fff",
                      }}
                    >
                      <h3 className="card-title fw-bold mb-0 py-3 p-4 fs-5">
                        Performance
                      </h3>
                    </div>
                    <div>
                      <div className="d-flex flex-stack">
                        <div className="d-flex align-items-center me-5">
                          <div className="me-5 d-flex">
                            <a
                              href="#"
                              className="text-gray-800 fw-bolder text-hover-primary fs-6 me-0 w-75px "
                            >
                              Spend
                            </a>
                            <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0 align-items-center">
                              559.5K{" "}
                              <span className="d-flex align-items-center w-100 ms-2 fs-8 fw-bolder">
                                + 8.8%{" "}
                                <i className="ki-outline ki-arrow-up fs-3 text-dark" />
                              </span>
                            </span>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <h4 className="fw-bolder mb-0 fs-5">$592.6K</h4>
                        </div>
                      </div>
                      <div className="separator separator-dashed my-3" />
                      <div className="d-flex flex-stack">
                        <div className="d-flex align-items-center me-5">
                          <div className="me-5 d-flex">
                            <a
                              href="#"
                              className="text-gray-800 fw-bolder text-hover-primary fs-6 me-0 w-75px"
                            >
                              Sales
                            </a>
                            <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0 align-items-center">
                              4.5M{" "}
                              <span className="d-flex align-items-center w-100 ms-2 fs-8 fw-bolder text-success">
                                + 5.8%{" "}
                                <i className="ki-outline ki-arrow-up fs-3 text-success" />
                              </span>
                            </span>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <h4 className="fw-bolder mb-0 fs-5">$4.9M</h4>
                        </div>
                      </div>
                      <div className="separator separator-dashed my-3" />
                      <div className="d-flex flex-stack">
                        <div className="d-flex align-items-center me-5">
                          <div className="me-5 d-flex">
                            <a
                              href="#"
                              className="text-gray-800 fw-bolder text-hover-primary fs-6 me-0 w-75px"
                            >
                              ACOS
                            </a>
                            <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0 align-items-center">
                              12.40%{" "}
                              <span className="d-flex align-items-center w-100 ms-2 fs-8 fw-bolder text-warning">
                                + 0.1%{" "}
                                <i className="ki-outline ki-arrow-up fs-3 text-warning" />
                              </span>
                            </span>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <h4 className="fw-bolder mb-0 fs-5">12.42%</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*end::Body*/}
                </div>
              </div>
              {/*end::Col*/}
              {/*begin::Col*/}
              <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-5 mb-xl-5">
                <div className="card card-flush h-lg-100">
                  {/*begin::Body*/}
                  <div className="card-body pt-4 pb-4 ">
                    <div
                      className=" rounded mb-3"
                      style={{
                        background:
                          "linear-gradient(90deg, #073d73, transparent)",
                        color: "#fff",
                      }}
                    >
                      <h3 className="card-title fw-bold mb-0 py-3 p-4 fs-5">
                        Conversion
                      </h3>
                    </div>
                    <div className="d-flex flex-stack">
                      <div className="d-flex align-items-center me-5">
                        <div className="me-5 d-flex">
                          <a
                            href="#"
                            className="text-gray-800 fw-bolder text-hover-primary fs-6 me-0 w-75px "
                          >
                            Clicks
                          </a>
                          <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0 align-items-center">
                            783.6K{" "}
                            <span className="d-flex align-items-center w-100 ms-2 fs-8 fw-bolder text-success">
                              + 4.3%{" "}
                              <i className="ki-outline ki-arrow-up fs-3 text-success" />
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <h4 className="fw-bolder mb-0 fs-5">816.8K</h4>
                      </div>
                    </div>
                    <div className="separator separator-dashed my-3" />
                    <div className="d-flex flex-stack">
                      <div className="d-flex align-items-center me-5">
                        <div className="me-5 d-flex">
                          <a
                            href="#"
                            className="text-gray-800 fw-bolder text-hover-primary fs-6 me-0 w-75px "
                          >
                            Orders
                          </a>
                          <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0 align-items-center">
                            85.1K{" "}
                            <span className="d-flex align-items-center w-100 ms-2 fs-8 fw-bolder text-success">
                              + 13.5%{" "}
                              <i className="ki-outline ki-arrow-up fs-3 text-success" />
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <h4 className="fw-bolder mb-0 fs-5">96.6K</h4>
                      </div>
                    </div>
                    <div className="separator separator-dashed my-3" />
                    <div className="d-flex flex-stack">
                      <div className="d-flex align-items-center me-5">
                        <div className="me-5 d-flex">
                          <a
                            href="#"
                            className="text-gray-800 fw-bolder text-hover-primary fs-6 me-0 w-75px "
                          >
                            CVR
                          </a>
                          <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0 align-items-center">
                            10.86%{" "}
                            <span className="d-flex align-items-center w-100 ms-2 fs-8 fw-bolder text-success">
                              + 8.8%{" "}
                              <i className="ki-outline ki-arrow-up fs-3 text-success" />
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <h4 className="fw-bolder mb-0 fs-5">11.82%</h4>
                      </div>
                    </div>
                  </div>
                  {/*end::Body*/}
                </div>
              </div>
              {/*end::Col*/}
              {/*begin::Col*/}
              <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-5 mb-xl-5">
                <div className="card card-flush h-lg-100">
                  {/*begin::Body*/}
                  <div className="card-body pt-4 pb-4 ">
                    <div
                      className=" rounded mb-3"
                      style={{
                        background:
                          "linear-gradient(90deg, #073d73, transparent)",
                        color: "#fff",
                      }}
                    >
                      <h3 className="card-title fw-bold mb-0 py-3 p-4 fs-5">
                        Efficiency
                      </h3>
                    </div>
                    <div className="d-flex flex-stack">
                      <div className="d-flex align-items-center me-5">
                        <div className="me-5 d-flex">
                          <a
                            href="#"
                            className="text-gray-800 fw-bolder text-hover-primary fs-6 me-0 w-75px "
                          >
                            CPC
                          </a>
                          <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0 align-items-center">
                            $0.71{" "}
                            <span className="d-flex align-items-center w-100 ms-2 fs-8 fw-bolder text-warning">
                              + 1.5%{" "}
                              <i className="ki-outline ki-arrow-up fs-3 text-warning" />
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <h4 className="fw-bolder mb-0 fs-5">$0.73</h4>
                      </div>
                    </div>
                    <div className="separator separator-dashed my-3" />
                    <div className="d-flex flex-stack">
                      <div className="d-flex align-items-center me-5">
                        <div className="me-5 d-flex">
                          <a
                            href="#"
                            className="text-gray-800 fw-bolder text-hover-primary fs-6 me-0 w-75px "
                          >
                            SPC
                          </a>
                          <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0 align-items-center">
                            $5.8{" "}
                            <span className="d-flex align-items-center w-100 ms-2 fs-8 fw-bolder text-success">
                              + 1.4%{" "}
                              <i className="ki-outline ki-arrow-up fs-3 text-success" />
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <h4 className="fw-bolder mb-0 fs-5">$5.8</h4>
                      </div>
                    </div>
                    <div className="separator separator-dashed my-3" />
                    <div className="d-flex flex-stack">
                      <div className="d-flex align-items-center me-5">
                        <div className="me-5 d-flex">
                          <a
                            href="#"
                            className="text-gray-800 fw-bolder text-hover-primary fs-6 me-0 w-75px "
                          >
                            CTR
                          </a>
                          <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0 align-items-center">
                            0.31%{" "}
                            <span className="d-flex align-items-center w-100 ms-2 fs-8 fw-bolder text-success">
                              + 5.5%{" "}
                              <i className="ki-outline ki-arrow-up fs-3 text-success" />
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <h4 className="fw-bolder mb-0 fs-5">0.33%</h4>
                      </div>
                    </div>
                  </div>
                  {/*end::Body*/}
                </div>
              </div>
              {/*end::Col*/}

              {/*begin::Col*/}
              <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-5 mb-xl-5">
                <div className="card card-flush h-lg-100">
                  {/*begin::Body*/}
                  <div className="card-body pt-4 pb-4 ">
                    <div
                      className=" rounded mb-3"
                      style={{
                        background:
                          "linear-gradient(90deg, #073d73, transparent)",
                        color: "#fff",
                      }}
                    >
                      <h3 className="card-title fw-bold mb-0 py-3 p-4 fs-5">
                        Active Campaign
                      </h3>
                    </div>
                    <div className="d-flex flex-stack">
                      <div className="d-flex align-items-center me-5">
                        <div className="me-5 d-flex">
                          <a
                            href="#"
                            className="text-gray-800 fw-bolder text-hover-primary fs-6 me-0 w-75px"
                          >
                            SP
                          </a>
                          <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0 align-items-center">
                            916{" "}
                            <span className="d-flex align-items-center w-100 ms-2 fs-8 fw-bolder text-warning">
                              - 7.4%{" "}
                              <i className="ki-outline ki-arrow-up fs-3 text-warning" />
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <h4 className="fw-bolder mb-0 fs-5">890</h4>
                      </div>
                    </div>
                    <div className="separator separator-dashed my-3" />
                    <div className="d-flex flex-stack">
                      <div className="d-flex align-items-center me-5">
                        <div className="me-5 d-flex">
                          <a
                            href="#"
                            className="text-gray-800 fw-bolder text-hover-primary fs-6 me-0 w-75px"
                          >
                            SB
                          </a>
                          <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0 align-items-center">
                            445{" "}
                            <span className="d-flex align-items-center w-100 ms-2 fs-8 fw-bolder text-warning">
                              - 6.1%{" "}
                              <i className="ki-outline ki-arrow-up fs-3 text-warning" />
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <h4 className="fw-bolder mb-0 fs-5">418</h4>
                      </div>
                    </div>
                    <div className="separator separator-dashed my-3" />
                    <div className="d-flex flex-stack">
                      <div className="d-flex align-items-center me-5">
                        <div className="me-5 d-flex">
                          <a
                            href="#"
                            className="text-gray-800 fw-bolder text-hover-primary fs-6 me-0 w-75px"
                          >
                            SD
                          </a>
                          <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0 align-items-center">
                            156{" "}
                            <span className="d-flex align-items-center w-100 ms-2 fs-8 fw-bolder text-warning">
                              + 3.8%{" "}
                              <i className="ki-outline ki-arrow-up fs-3 text-warning" />
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <h4 className="fw-bolder mb-0 fs-5">162</h4>
                      </div>
                    </div>
                  </div>
                  {/*end::Body*/}
                </div>
              </div>
              {/*end::Col*/}
              {/*begin::Col*/}
              <div className="col-12 col-md-12 col-lg-12 col-xl-8 mb-5 mb-xl-5">
                <div className="card card-flush h-lg-100">
                  {/*begin::Body*/}
                  <div className="card-body pt-4 pb-4 ">
                    <div
                      className=" rounded mb-3"
                      style={{
                        background:
                          "linear-gradient(90deg, #073d73, transparent)",
                        color: "#fff",
                      }}
                    >
                      <h3 className="card-title fw-bold mb-0 py-3 p-4 fs-5">
                        Targeting
                      </h3>
                    </div>
                    <div className="row">
                      <div className="col-md-6 pe-7">
                        <div className="d-flex flex-stack">
                          <div className="d-flex align-items-center me-5">
                            <div className="me-5 d-flex">
                              <a
                                href="#"
                                className="text-gray-800 fw-bolder text-hover-primary fs-6 me-0 w-125px"
                              >
                                Active Keywords
                              </a>
                              <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0 align-items-center">
                                77787{" "}
                                <span className="d-flex align-items-center w-100 ms-2 fs-8 fw-bolder text-warning">
                                  + 9.1%{" "}
                                  <i className="ki-outline ki-arrow-down fs-3 text-warning" />
                                </span>
                              </span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <h4 className="fw-bolder mb-0 fs-5">70706</h4>
                          </div>
                        </div>
                        <div className="separator separator-dashed my-3" />
                        <div className="d-flex flex-stack">
                          <div className="d-flex align-items-center me-5">
                            <div className="me-5 d-flex">
                              <a
                                href="#"
                                className="text-gray-800 fw-bolder text-hover-primary fs-6 me-0 w-125px"
                              >
                                New Keywords
                              </a>
                              <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0 align-items-center">
                                1336{" "}
                                <span className="d-flex align-items-center w-100 ms-2 fs-8 fw-bolder text-danger">
                                  - 82.9%{" "}
                                  <i className="ki-outline ki-arrow-down fs-3 text-danger" />
                                </span>
                              </span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <h4 className="fw-bolder mb-0 fs-5">229</h4>
                          </div>
                        </div>
                        <div className="separator separator-dashed my-3" />
                        <div className="d-flex flex-stack">
                          <div className="d-flex align-items-center me-5">
                            <div className="me-5 d-flex">
                              <a
                                href="#"
                                className="text-gray-800 fw-bolder text-hover-primary fs-6 me-0 w-125px"
                              >
                                Active PAT
                              </a>
                              <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0 align-items-center">
                                23887
                                <span className="d-flex align-items-center w-100 ms-2 fs-8 fw-bolder text-warning">
                                  - 9.4%{" "}
                                  <i className="ki-outline ki-arrow-down fs-3 text-warning" />
                                </span>
                              </span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <h4 className="fw-bolder mb-0 fs-5">21198</h4>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 ps-7 ms-auto">
                        <div className="d-flex flex-stack">
                          <div className="d-flex align-items-center me-5">
                            <div className="me-5 d-flex">
                              <a
                                href="#"
                                className="text-gray-800 fw-bolder text-hover-primary fs-6 me-0 w-125px"
                              >
                                New PAT
                              </a>
                              <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0 align-items-center">
                                513{" "}
                                <span className="d-flex align-items-center w-100 ms-2 fs-8 fw-bolder text-success">
                                  + 62.8%{" "}
                                  <i className="ki-outline ki-arrow-up fs-3 text-success" />
                                </span>
                              </span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <h4 className="fw-bolder mb-0 fs-5">835</h4>
                          </div>
                        </div>
                        <div className="separator separator-dashed my-3" />
                        <div className="d-flex flex-stack">
                          <div className="d-flex align-items-center me-5">
                            <div className="me-5 d-flex">
                              <a
                                href="#"
                                className="text-gray-800 fw-bolder text-hover-primary fs-6 me-0 w-125px"
                              >
                                Active Audience
                              </a>
                              <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0 align-items-center">
                                409{" "}
                                <span className="d-flex align-items-center w-100 ms-2 fs-8 fw-bolder text-dark">
                                  --
                                </span>
                              </span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <h4 className="fw-bolder mb-0 fs-5">409</h4>
                          </div>
                        </div>
                        <div className="separator separator-dashed my-3" />
                        <div className="d-flex flex-stack">
                          <div className="d-flex align-items-center me-5">
                            <div className="me-5 d-flex">
                              <a
                                href="#"
                                className="text-gray-800 fw-bolder text-hover-primary fs-6 me-0 w-125px"
                              >
                                New Audience
                              </a>
                              <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0 align-items-center">
                                12{" "}
                                <span className="d-flex align-items-center w-100 ms-2 fs-8 fw-bolder text-success">
                                  + 83.3%{" "}
                                  <i className="ki-outline ki-arrow-up fs-3 text-success" />
                                </span>
                              </span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <h4 className="fw-bolder mb-0 fs-5">22</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*end::Body*/}
                </div>
              </div>
              {/*end::Col*/}
            </div>

            <div className="row gx-5">
              {/*begin::Col*/}
              <div className="col-xl-12">
                {/*begin::Chart widget 36*/}
                <div className="card card-flush overflow-hidden h-lg-100">
                  {/*begin::Header*/}
                  <div className="card-header pt-5">
                    {/*begin::Title*/}
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold text-dark">
                        Daily Performance Trend
                      </span>
                      {/* <span class="text-gray-400 mt-1 fw-semibold fs-6">1,046 Inbound Calls today</span> */}
                    </h3>
                    {/*end::Title*/}
                    {/*begin::Toolbar*/}
                    <div className="card-toolbar"></div>
                    {/*end::Toolbar*/}
                  </div>
                  {/*end::Header*/}
                  {/*begin::Card body*/}
                  <div className="card-body d-flex align-items-end p-0">
                    <div
                      class="min-h-auto w-100 ps-4 pe-6"
                      style={{ height: "350px" }}
                    >
                      <Chart
                        options={options}
                        series={series}
                        type="area"
                        height={300}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row gy-5 g-xl-5">
              {/*begin::Col*/}
              <div className="col-xxl-12">
                {/*begin::Tables Widget 9*/}
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  {/*begin::Header*/}
                  <div className="card-header border-bottom-dashed">
                    {/*end::Title*/}
                    {/*begin::Title*/}
                    <h3 className="card-title ">
                      <span className="card-label fw-bold text-gray-900">
                        Overview
                      </span>
                    </h3>
                    {/*end::Title*/}
                    <div className="card-toolbar"></div>
                  </div>
                  {/*end::Header*/}
                  {/*begin::Body*/}
                  <div className="card-body py-2 pt-0">
                    {/*begin::Table container*/}
                    <div className="table-responsive">
                      {/*begin::Table*/}
                      <table className="table table-row-dashed table-row-gray-300 align-middle gx-4 gy-4">
                        {/*begin::Table head*/}
                        <thead>
                          <tr className="fw-bolder text-dark">
                            <th className="min-w-25px">#</th>
                            <th className="min-w-175px">Type</th>
                            <th className="min-w-150px">Campaign Status</th>
                            <th className="min-w-150px">Campaign Budget</th>
                            <th className="min-w-225px">
                              Campaign Bidding Strategy
                            </th>
                            <th className="min-w-100px">Start Date</th>
                            <th className="min-w-100px">End Date</th>
                            <th className="min-w-100px">Impressions</th>
                            <th className="min-w-75px">Clicks</th>
                            <th className="min-w-125px">Unit Ordered</th>
                            <th className="min-w-75px">Sales</th>
                            <th className="min-w-75px">Spend</th>
                            <th className="min-w-200px">
                              Cost-per-click (CPC)
                            </th>
                            <th className="min-w-225px">
                              Click Through Rate (CTR)
                            </th>
                            <th className="min-w-175px">Conversion Rate</th>
                            <th className="min-w-225px">
                              Return on Ad spend (ROAS)
                            </th>
                            <th className="min-w-250px">
                              Advertising cost of sales (ACOS)
                            </th>
                            <th className="min-w-250px">
                              Advertising cost of sales (ACOS%)
                            </th>
                            <th className="min-w-275px">
                              Top-of-search impression share (IS)
                            </th>
                            <th className="min-w-250px">
                              New-to-brand (NTB) orders
                            </th>
                            <th className="min-w-250px">
                              % of orders new-to-brand (NTB)
                            </th>
                            <th className="min-w-150px">Campaign ID</th>
                            <th className="min-w-150px">Campaign Name</th>
                            <th className="min-w-200px">
                              New-to-brand (NTB) sales
                            </th>
                            <th className="min-w-250px">
                              % of sales new-to-brand (NTB)
                            </th>
                          </tr>
                        </thead>
                        {/*end::Table head*/}
                        {/*begin::Table body*/}
                        <tbody>
                          <tr className="ant-table-row ant-table-row-level-0">
                            <td>1</td>
                            <td>
                              <span className="fw-bolder">
                                Sponsored Products
                              </span>
                            </td>
                            <td>ENABLED</td>
                            <td>100</td>
                            <td>0</td>
                            <td>-</td>
                            <td>-</td>
                            <td>8815</td>
                            <td>60</td>
                            <td>23</td>
                            <td>928.77</td>
                            <td>40.33</td>
                            <td>0.67 </td>
                            <td>0.01</td>
                            <td>0</td>
                            <td>185.75</td>
                            <td>0.04 </td>
                            <td>4.34 </td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>263870281425806</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                          </tr>
                        </tbody>
                        {/*end::Table body*/}
                      </table>
                      {/*end::Table*/}
                    </div>
                    {/*end::Table container*/}
                    {/*end::Table container*/}
                    <div className="d-flex flex-stack flex-wrap pt-0 mt-5">
                      <div className="fs-7 fw-bold text-gray-700 d-flex align-items-center">
                        <select
                          name
                          aria-controls
                          className="form-select form-select-sm form-select-solid w-75px me-3"
                        >
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                        Showing 1 to 3 of 50 entries
                      </div>
                      {/*begin::Pages*/}
                      <ul className="pagination">
                        <li className="page-item previous">
                          <a href="#" className="page-link">
                            <i className="previous" />
                          </a>
                        </li>
                        <li className="page-item active">
                          <a href="#" className="page-link">
                            1
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            2
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            3
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            4
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            5
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            6
                          </a>
                        </li>
                        <li className="page-item next">
                          <a href="#" className="page-link">
                            <i className="next" />
                          </a>
                        </li>
                      </ul>
                      {/*end::Pages*/}
                    </div>
                  </div>
                  {/*begin::Body*/}
                </div>
                {/*end::Tables Widget 9*/}
              </div>
              {/*end::Col*/}
            </div>
          </div>
        </div>
      </>
    </Wrapper>
  );
}
